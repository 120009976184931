import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 775 849",
    }}
    viewBox="0 0 775 849"
    {...props}
  >
    <path d="M570.8 66.4c17.1 3.5 33.6 5.9 49.6 10.5 72.3 20.7 116.5 68.7 134.3 141.3 5.8 23.4 7.5 47.3 7.5 71.4-.1 108.3-.1 216.7 0 325 0 29.8-2.8 59.3-12 87.7-22.1 68.2-68.8 109.9-137.9 127.4-23.4 5.9-47.3 8-71.3 8-101.8.1-203.7 0-305.5 0-36.8 0-72.8-5.1-106.8-20-45.1-19.8-77.2-52.5-96.1-98.1C18.8 686.1 14.3 651 14.3 615c.1-108.7.1-217.3 0-326 0-31.2 3.3-61.9 13.4-91.5C49.1 135.2 92.1 95.3 155.1 77.1c16.2-4.7 33.1-7.1 50.4-10.7 0-7.8-.1-16.6 0-25.4 0-3.7-.1-7.4.7-10.9 3.6-16 16.5-22.9 30.8-21.6 17.4 1.5 26.7 12.4 26.7 29.9v27.8h249.2v-8.6c0-7-.2-14 .1-21 .5-13.6 7.7-23.3 19.4-26.7 13-3.7 27-.1 33.1 10 3 5 4.7 11.4 5.1 17.3.8 9.8.2 19.8.2 29.2zM205.5 126c-1.9.1-3.2 0-4.5.2-11.7 2.3-23.7 4-35.1 7.3-44 12.8-72.1 41.6-85.1 85.5-6.8 23.1-8.6 46.8-8.6 70.6v324.9c0 29.5 2.7 58.6 14.2 86.2 12.7 30.8 34.6 52.5 65.5 65 26.9 10.8 55 14.1 83.7 14.1H541c23.6 0 46.9-2.3 69.6-9.1 44.1-13.1 72.4-41.8 85.3-86 6.7-22.9 8.5-46.4 8.5-70.2V289.1c0-29.7-2.8-58.9-14.5-86.6-8.7-20.7-22-37.9-40.5-50.9-20.9-14.6-44.6-21.1-69.4-25-2.9-.4-5.8-.5-9.1-.8 0 9.7.1 18.9 0 28-.2 16.7-10.4 27.2-27.3 28.3-16.1 1.1-28.2-8.6-30.1-24.8-.9-8.1-.4-16.3-.5-24.5v-8.5H263.7c0 10.3.2 20.3 0 30.3-.3 15.5-10.6 26.2-27.3 27.6-12.4 1-26.4-4.8-30-21.3-.8-3.7-.7-7.6-.8-11.4-.2-7.6-.1-15.4-.1-23.5z" />
    <path d="M388.6 356.5h149.9c3.6 0 7.4 0 10.9.8 14.3 3.1 22 14.4 21.3 30.5-.7 14.2-10.1 24.4-24.2 26-2.6.3-5.3.5-8 .5H237.6c-3.8 0-7.7-.1-11.4-1-14-3.2-21.8-15.5-20.5-31.7 1.2-13.8 11.2-23.8 24.9-24.9 2.7-.2 5.3-.2 8-.2h150zM312.7 547.8c26.3 0 52.7-.1 79 0 17 .1 30.1 13.7 28.2 28.8-1.5 11.4-14.2 25.2-26 27.9-3.2.7-6.6 1.1-9.9 1.1-49.5.1-99 .1-148.5 0-22.3 0-35.4-18.3-28.3-39.6 3.8-11.4 13.7-18.2 27-18.3 26.2 0 52.4.1 78.5.1z" />
  </svg>
)
export default SvgComponent