import {
  Anchor,
  Col,
  Form,
  Input,
  Row,
  Typography
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FooterContent from "../../components/FooterContent";
import HeaderAuthTop from "../../components/HeaderAuthTop";
import { EndPoints } from "../../endpoint";
import image1 from "../../images/Image-2.png";
import verifyicon from "../../images/verify.png";
import { GetData } from "../../services";
import "../Profile/UserProfile.css";
import frame1 from "../../images/Help&center.png"
import frame2 from "../../images/Help&center2.png"
import frame3 from "../../images/Help&center3.png"
import { Space, Tag } from 'antd';
import {
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer, TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import moment from "moment";

const MyFormItemContext = React.createContext([]);

const { Title } = Typography;
const { Link } = Anchor;
const { TextArea } = Input;

const MyFormItemGroup = ({ prefix, children }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(
    () => [...prefixPath, ...toArr(prefix)],
    [prefixPath, prefix]
  );
  return (
    <MyFormItemContext.Provider value={concatPath}>
      {children}
    </MyFormItemContext.Provider>
  );
};

function toArr(str) {
  return Array.isArray(str) ? str : [str];
}
const MyFormItem = ({ name, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
  return <Form.Item name={concatName} {...props} />;
};

function PaymentHistory() {
  const navigate = useNavigate()
  const [dataList, setDataList] = useState([])

  const columns = [
    {
      title: 'INVOICE NUMBER',
      dataIndex: 'invoicenumber',
      key: 'invoicenumber',
      //   render: (text) => <b>{text}</b>,
    },
    {
      title: 'VENDOR',
      dataIndex: 'vendor',
      key: 'vendor',
    },
    {
      title: 'BILLING DATE',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Status',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 5 ? 'geekblue' : 'green';
            if (tag === 'Unpaid') {
              color = 'red';
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'AMOUNT($)',
      dataIndex: 'fees',
      key: 'fees',
      render: (text) => <b>{text}</b>,
    },
  ];

  const data = [
    {
      key: '1',
      invoicenumber: '5146846548465',
      //   age: 32,
      vendor: 'John Smith',
      date: '21 Sep 2023',
      tags: ['Paid'],
      fees: '$184',
    },
    {
      key: '2',
      invoicenumber: '5146846548436',
      //   age: 42,
      vendor: 'Williamson',
      date: '21 Sep 2023',
      tags: ['Unpaid'],
      fees: '$98',
    },
    {
      key: '3',
      invoicenumber: '5146846548745',
      //   age: 32,
      vendor: 'John Smith',
      date: '21 Sep 2023',
      tags: ['Paid'],
      fees: '$123',
    },
    {
      key: '4',
      invoicenumber: '514684654842334',
      //   age: 32,
      vendor: 'John Smith',
      date: '21 Sep 2023',
      tags: ['Unpaid'],
      fees: '$100',
    },
    {
      key: '5',
      invoicenumber: '51468465482309',
      //   age: 42,
      vendor: 'John Smith',
      date: '21 Sep 2023',
      tags: ['Unpaid'],
      fees: '$98',
    },
  ];

  useEffect(() => {
    getPaymentList()
  }, [])

  const getPaymentList = async () => {
    try {
      let res = await GetData(EndPoints?.PAYMENT_LIST + localStorage.getItem("user_id"))
      if (res?.status == 200 && res?.success_status == true) {
        let newArr = res.data.map((x, i) => ({
          sr: i + 1,
          amt: `$${x?.amount}`,
          // winnerAmt:`$${x?.winner_prize}`,
          ...x
        }));

        setDataList(newArr);

        // setDataList(res?.data)
      }
      else if (res?.status == 200 && res?.success_status == false) {
        toast.error(res?.message);
      }
      else {
        toast.error(res?.message);
      }
    }
    catch (err) {
      toast.error('Something went wrong');
    }
  }

  return (
    <>
      {/* <HeaderAuthTop /> */}
      <div className="Profilesection" >
        <div className="container profilewrap" style={{ width: "92%", background: "#fff" }} >
          <Form name="form_item_path" layout="vertical">
            <h2 className="contesthomeform">Payment History</h2>
            <div className="border" style={{ marginBottom: "20px" }}></div>
            {/* <Table columns={columns} dataSource={data}  pagination={false} /> */}
            <TableContainer className="tableContainer" style={{ border: "1px solid #E0E7ED", background: "#fff", borderRadius: "3px" }}>
              <Table>
                <TableHead className='tableHeader'>
                  <TableRow>
                    <TableCell>S.No</TableCell>
                    <TableCell>Transation Id</TableCell>
                    <TableCell>Date Of Transation</TableCell>
                    <TableCell>Type For</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataList.length != 0 ?
                    <>                    {dataList.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.sr}</TableCell>
                        <TableCell>{row.payment_id}</TableCell>
                        <TableCell>{moment(row.created_at).format('MMM DD, YYYY')}</TableCell>
                        <TableCell>{row.paymentfor}</TableCell>
                        <TableCell className='successText'>{row.amt}</TableCell>
                        <TableCell className='successText'>{row.status}</TableCell>
                      </TableRow>
                    ))}
                    </> :
                    <p style={{ textAlign: "center", fontSize: "15px", color: "red" }}>There is no record avilable.</p>
                  }

                </TableBody>
              </Table>
            </TableContainer>




          </Form>
        </div>
      </div>

      {/* <FooterContent /> */}
    </>
  );
}

export default PaymentHistory;