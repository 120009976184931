export const EndPoints = {
  BASE_URL: "https://fantasy.largetech.in/api/",


  STRIPE_PUBLIC_KEY: 'pk_test_51OEd9nSCrqq8IKL6rOzOrmRpMrzpJ892hFQOiuPOxvNWmL5GkywRrgOXcNAYfUlUyT72i5yGcO6hyfT8q99fZ47E00ZJFzemZK',

  // THIS KEY IS FOR LOGIN
  LOGIN: "login",

  // THIS KEY IS FOR LOGIN
  SIGNUP: "register",

  //FORGOT
  FORGOT_PASSWORD: "reset-password",
  RESET_PASSWORD: "change-reset-password",

  // THIS KEYS IS FOR HOME PAGES
  BANNERSECTION: "home-banner",
  SUBSCRIPTIONSECTION: "subscription",
  COMINGSOONSECTION: "comingsoon",
  ONGOINGBATTELSECTION: "ongoing",
  UPCOMINGSECTION: "upcoming",
  TOPWINNERS: "top-winner",

  // THIS KEYS IS FOR CONTEST PAGES
  UPCOMINGCONTEST: "all-upcoming",
  ONGOINGCONTEST: "all-ongoing",
  CANCELLEDCONTEST: "all-cancelled",
  COMMINGSOON: "all-comingsoon",

  // THIS KEYS IS FOR BANNER PAGES
  BANNERDATA: "banner-list",

  // THIS KEYS IS FOR CREATE AND LIST CONTEST PAGES
  CREATECONTEST: "contest-create",
  CREATECONTESTLIST: "contest-list?user_id=",
  BATTLE_HISTORY: 'battels-list',
  CONTESTUPDATE: "contest-update",
  CONTESTDELETE: "contest-delete?contest_id=",

  couponList: "coupon-list",

  CONTEXT_DETAILS: "join-now?contest_id=",

  // THIS KEYS IS FOR COMMUNITY PAGES
  POSTLIST: "post-list",
  CONTEXT_COURSE_LIST:'course-contest-list?user_id=',
  // THIS KEYS IS FOR COMMUNITY PAGE

  CUSTOM_BANNER: "advertisements?page_type=",

  // THIS KEYS IS FOR PROFILE PAGES

  CONTACT_US: "contact-us",
  GET_USER: "profile-details?user_id=",
  UPDATE_USER: "update-profile",
  COUPON_LIST: "coupon-list",
  TERMS: "terms-condition",
  POLICY: "privacy-policy",
  FAQ: "faq",
  HELPANDCENTER: "helps-center",
  HELP_FORM: 'help-center',
  NOTIFICATION_ALERT: 'notification-alert',
  GET_NOTIFICATION_DATA: 'getnotificationdata?user_id=',
  INVITATION_SEND: 'invitation-send?contest_id=',

  COMMUNITY_LIST: 'community-list?user_id=',


  POST_LIKE_DISLIKE: 'post-like',
  POST_COMMENT: 'post-comment',
  COURSE_DETAILS: 'course-details?course_id=',
  ACTIVE_SUBSCRIPTION: 'active-subscription?user_id=',
  NOTIFICATION_LIST: 'notification-detail',
  PAYMENT_LIST:'payment-history?user_id=',
  CONTEST_PAY:'contest-payments',
  INVITATION_ACCEPT:'invitation-accept',
  FINAL_JOIN:'final-join',
  UPDATE_WINNER:'winner-update',


  CARD_LIST: 'card-list?user_id=',
  SAVE_CARD: 'create-card',
  DELETE_CARD: 'delete-card',
  BUY_SUBS: 'make-payment',
  DEFAULT_CARD: 'set-default-card',
  STRIPE_KEY:'stripe-key',


  ADD_POST:'create-post',
  REPORT_POST:'report-post',
  DELETE_POST:'delete-post',
  EDIT_POST:'update-post',

POST_ADVERTISEMENT:'post-advertisements',
POST_COMMENT_List:'post-comments?post_id=',

};

