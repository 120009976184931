import React, { useEffect, useState } from 'react'
import './ResetPassword.css';
import {
  Typography,
  Anchor,
  Form,
  Input,
  MyFormItem
} from "antd";
import Bootstrap from 'bootstrap'
import HeaderTop from '../../components/HeaderTop';
import FooterContent from '../../components/FooterContent';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Validator from '../../Validator';
import { PostData } from '../../services';
import { EndPoints } from '../../endpoint';

export default function ResetPassword() {
  const navigate = useNavigate();
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [token, setToken] = useState('')
  const [id, setId] = useState('')


  useEffect(() => {
    console.log(window.location.href, '9999999999999999999999999')
    handleGetTokenUrl()
  }, [])


  const handleGetTokenUrl = async () => {
    const url = window.location.href;
    const regex = /token=([^&]+)&id=([^&]+)/;
    const match = regex.exec(url);

    if (match) {
      setToken(match[1]);
      setId(match[2]);
    } else {
      console.log("No match found");
    }
  }


  const handleSubmit = async() => {
    if (Validator.isEmpty(password)) {
      toast.error('Please enter password')
    }
    else {
      try {
        const data = {
          "id": id,
          "token": token,
          "password": password
        }
        console.log(data,'daattatya')
        const res = await PostData(EndPoints.RESET_PASSWORD, data)
        console.log(res,'ressssssssss')
        if (res?.status == 200 && res?.success_status == true) {
          toast.success(res.message)
          navigate('/');
          navigate(0)
        }
        else if (res?.status == 200 && res?.success_status == false) {
          toast.error(res.message)
        }
        else {
          toast.error(res.message)
        }
      }
      catch (err) {
        toast.error('Something went wrong')
      }
    }
  }



  return (
    <>
      <HeaderTop />
      <div classNameName="container" style={{ margin: "70px 0px" }}>
        <div className="form">
          <form action="">
            <div className="card" style={{ border: "2px solid white", borderRadius: "10px", backgroundColor: "white" }}>

              <h2 className="head">Reset Password?</h2>
              <p className="subhead">You can reset your Password here</p>
              {/* <input type="text" id="lname" name="lname"/> */}
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
                hasFeedback
              >
                <Input.Password onChange={(e) => setPassword(e.target.value)} />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The new password that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password onChange={(e) => setConfirmPassword(e.target.value)} />
              </Form.Item>
              {/* <div onClick={() => handleSubmit()}>
                <button className="btn">Reset Password</button>
              </div> */}
               <div onClick={() => handleSubmit()} className="btn">Reset Password</div>

            </div>
          </form>
        </div>
      </div>
      <FooterContent />

    </>
  )
}