import React, { useContext, useState } from 'react';
import { Layout, Menu, Row, Col, Typography, Modal, Form, Input, Anchor, Button, Checkbox, Avatar, Dropdown, Space, Popover } from 'antd';
import logo from '../images/fantasy-logo.svg';
import blacklogo from '../images/blacklogo.svg';
import SignUp from '../components/SignUp';
import LogIn from '../components/LogIn';
import AvatorImg from "../images/Avatar.png";
import WalletIcon from "../images/wallet.png";
import Notification from "../images/notification.jpg"
import {
  LoginOutlined,UserOutlined
} from '@ant-design/icons';
import { AuthContext } from '../App';
import { useNavigate } from 'react-router-dom';
import proImg from '../images/pro.png'



const MyFormItemContext = React.createContext([]);

const { Header } = Layout;
const { Title } = Typography;
const { Link } = Anchor;
// const content = (
//   <div onClick={()=>{localStorage.removeItem();setIsAuth(false)}}>
//     <p style={{fontSize:"17px" }}><LoginOutlined style={{fontSize:"20px"}} /> Logout</p>
//   </div>
// );

const MyFormItemGroup = ({ prefix, children }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(() => [...prefixPath, ...toArr(prefix)], [prefixPath, prefix]);
  return <MyFormItemContext.Provider value={concatPath}>{children}</MyFormItemContext.Provider>;
};

function toArr(str) {
  return Array.isArray(str) ? str : [str];
}
const MyFormItem = ({ name, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName = name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
  return <Form.Item name={concatName} {...props} />;
};

function HeaderAuthTop() {
  const navigate = useNavigate()
  const [isAuth, setIsAuth] = useContext(AuthContext)
  const [isModalLoginOpen, setIsModalLoginOpen] = useState(false);
  const [isModasignuplOpen, setIsModalSignupOpen] = useState(false);

  const handleLogout = () => {
    localStorage.setItem("user_id", "");
    localStorage.setItem("access_token", "");
    localStorage.setItem("proImg", '');
    setIsAuth(false)
    navigate('/');
    navigate(0)


  }

  const content = (
    <div className='headerProfileMenu'>
      {/* <p onClick={() => handleLogout()} style={{ fontSize: "17px" }}><LoginOutlined style={{ fontSize: "20px" }} /> Logout</p> */}
      <Menu>
        {/* <Menu.Item key="contact Us"><a href='/UserProfile'>My Profile </a></Menu.Item> */}
        <p onClick={() => navigate('/UserProfile')} style={{ fontSize: "15px", cursor: "pointer" }}><UserOutlined style={{ fontSize: "15px" }} /> My Profile</p>
        {/* <Menu.Item key="contact Us"><a href='/couponcodes'>Coupon</a></Menu.Item>
        <Menu.Item key="contact Us"><a href='/privacypolicy'>Privacy Policy</a></Menu.Item>
        <Menu.Item key="contact Us"><a href='/TermsandConditions'>Terms & condition</a></Menu.Item>
        <Menu.Item key="contact Us"><a href='/Faq'>FAQ</a></Menu.Item>
        <Menu.Item key="contact Us"><a href='/NotificationsSetting'>Notifcation Settings </a></Menu.Item>
        <Menu.Item key="contact Us"><a href='/HelpandCenter'>Help & Center</a></Menu.Item>
        <Menu.Item key="contact Us"><a href='/Contesthistory'>Contestent History</a></Menu.Item>
        <Menu.Item key="contact Us"><a href='/Contestentlist'>Contest History</a></Menu.Item>
        <Menu.Item key="contact Us"><a href='/paymenthistory'>Payment History</a></Menu.Item>
        <Menu.Item key="contact Us"><a href='/subscription'>Subscription</a></Menu.Item> */}
        <p onClick={() => handleLogout()} style={{ fontSize: "15px" }}><LoginOutlined style={{ fontSize: "15px" }} /> Logout</p>
      </Menu>
    </div>
  );

  const showModal = () => {
    setIsModalLoginOpen(true);
  };
  const handleOk = () => {
    setIsModalLoginOpen(false);
  };
  const handleCancel = () => {
    setIsModalLoginOpen(false);
  };
  const onFinish = (value) => {
  };
  const showModalsignup = () => {
    setIsModalSignupOpen(true);
  };
  const handleOksignup = () => {
    setIsModalSignupOpen(false);
  };
  const handleCancelsignup = () => {
    setIsModalSignupOpen(false);
  };
  return (

    <Layout>
      <Header className="headerAuthTop" >
        <div className="container">
          <div className="headerWrapAuth">
            <Row gutter={16} align="middle">
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <div className="logo" style={{ marginTop: "20px" }}>
                  <a href="/" ><img src={blacklogo} height={50} alt="logo" /></a>
                </div>
              </Col>
              <Col xs={24} sm={24} md={14} lg={14} xl={14} className='d-flex'>
                <Menu className="menuListAuth menuListMenuAuth" mode="horizontal">
                  <Menu.Item key="Home" ><a href='/'>Home</a></Menu.Item>
                  {/* <Menu.Item key="About Us"><a href='/Aboutuspage'>About Us</a></Menu.Item> */}
                  <Menu.Item key="Contest page"><a href='/Contestpage'>Contest </a></Menu.Item>
                  <Menu.Item key="contact Us"><a href='/Contactuspage'>Contact Us </a></Menu.Item>


                </Menu>
                <Menu className="menuListAuth" mode="horizontal" defaultSelectedKeys={['1']}>
                  <Menu.Item key="1" className="JoinContest"><a href='/joincommunity'>Join Community</a></Menu.Item>
                  <Menu.Item key="Home" >
                    {isAuth &&
                      <Avatar size={25} onClick={()=>navigate('/NotificationPage')} src={<img src={Notification} alt="avatar" />} />}
                  </Menu.Item>

                  {isAuth &&
                    <Menu.Item key="Home" >
                      <Popover content={content}
                        trigger="click">
                        {localStorage.getItem("proImg") ?
                          <Avatar size={45} style={{ border: "2px solid #F4EBFF" }} src={<img src={localStorage.getItem("proImg")} alt="avatar" />} />
                          :
                          <Avatar size={45} style={{ border: "2px solid #F4EBFF" }} src={<img src={proImg} alt="avatar" />} />}
                      </Popover>
                    </Menu.Item>}
                </Menu>
              </Col>
            </Row>
          </div>
        </div>
      </Header>

    <Modal title="" className="loginModal" open={isModalLoginOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
    <LogIn />
    <div className='dontAccountWrap'>
    <span className='dontText'>Don’t have an account?</span>
     <div  onClick={()=>{setIsModalSignupOpen(true);setIsModalLoginOpen(false)}} className="notSignLink">Sign up</div> 
    </div>
    </Modal>


      <Modal title="" className="loginModal" open={isModasignuplOpen} onOk={handleOksignup} onCancel={handleCancelsignup} footer={null}>
        <SignUp />
        <div className='dontAccountWrap'>
        <span className='dontText'>Already have an account?</span>  <div onClick={()=>{setIsModalSignupOpen(false);setIsModalLoginOpen(true)}} className="notSignLink">Log In</div>
      </div>
      <br />
      <div>
        <p className='dontText' style={{ textAlign: "center" }}>If you have any query or face any issue please connect us on<br />
          1800-2342-2342 Or mail Us support@fanstasy.com</p>
      </div>
      </Modal>

    </Layout>
  );
}

export default HeaderAuthTop;