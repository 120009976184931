import {
  DeleteOutlined
} from '@ant-design/icons';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer, TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  Col,
  Form,
  Row, Button, Checkbox, Modal, Typography
} from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EndPoints } from "../../endpoint";
import { GetData, PostData } from "../../services";
import "../Profile/UserProfile.css";
import HeaderAuthTop from '../../components/HeaderAuthTop';
import FooterContent from '../../components/FooterContent';

// const stripePromise = loadStripe('pk_test_51OMus3SEgsRkips3ddW50n8aFlGYcpWkTHbk3TgE6iIupDA8tQsn93BbCmIwGAa60ZUZxXJru7T0lujhRSIWyUTr00F74JEb4p');

const columns = [
  {
    title: 'Card Number',
    dataIndex: 'cardnumber',
    key: 'cardnumber',
  },
  {
    title: 'Card Name',
    dataIndex: 'cardname',
    key: 'cardname',
  },
  {
    title: 'Expiry(mm/dd)',
    dataIndex: 'expiry',
    key: 'expiry',
  },
  {
    title: 'CVV',
    dataIndex: 'cvv',
    key: 'cvv',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
  },
];

const dataSource = [
  {
    key: '1',
    cardnumber: '41xx xxxx xxxx 5609',
    cardname: 'Vaishno Tiwari',
    expiry: '02/23',
    cvv: '695',
    action: <DeleteOutlined style={{ color: '#db0a40' }} />,
  },
  {
    key: '1',
    cardnumber: '41xx xxxx xxxx 5609',
    cardname: 'Vaishno Tiwari',
    expiry: '02/23',
    cvv: '695',
    action: <DeleteOutlined style={{ color: '#db0a40' }} />,
  },
  {
    key: '1',
    cardnumber: '41xx xxxx xxxx 5609',
    cardname: 'Vaishno Tiwari',
    expiry: '02/23',
    cvv: '695',
    action: <DeleteOutlined style={{ color: '#db0a40' }} />,
  },
];

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate()
  const [email, setEmail] = useState('');

  useEffect(() => {
    getUser()
  }, [])

  const getUser = async () => {
    try {
      // const res = await GetData(EndPoints.GET_USER + 17)
      const res = await GetData(EndPoints.GET_USER + localStorage.getItem("user_id"))
      if (res.status == 200 && res.success_status == true) {
        setEmail(res?.data?.email ? res?.data?.email : "")
      }
      else if (res.status == 200 && res.success_status == false) {
        toast.error(res.message)
      }
      else {
        toast.error(res.message)
      }
    }
    catch (err) {
      toast.error('Something went wrong')
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const { token, error } = await stripe.createToken(cardElement);

    if (error) {
    } else {
      await handleSaveCard(token.id)
    }
  };

  const handleSaveCard = async (token) => {
    try {
      let data = {
        "token": token,
        "user_id": localStorage.getItem("user_id"),
        "email": email
      }
      const res = await PostData(EndPoints?.SAVE_CARD, data)
      if (res?.status == 200 && res?.success_status == true) {
        props.handleGetCardList()
        toast.success(res?.message)
      }
      else if (res?.status == 200 && res?.success_status == false) {
        toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    }
    catch (err) {
      toast.error('Something went wrong')
    }
  }

  return (
    <form>
      <CardElement options={cardElementOptions} />
      <button type="button" className='saveCardBtn' style={{ marginTop: "15px", border: "none " }} onClick={handleSubmit} disabled={!stripe}>
        Save Card
      </button>
    </form>
  );
};

const SaveCard = () => {
  const navigate = useNavigate()
  const [cardList, setCardList] = useState([])
  const location = useLocation()
  const { Title } = Typography;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const stripePromise = loadStripe('pk_test_51ONDAKLZzn4yU9QeOmGso48moP65kY2D25JajZnznAIqH8bhxlbzLPDqs5UqlVADlayJccGb9LnBILlPfZThPISs00KFnT6hes');

  // const [stripePromise,setstripePromise]=useState('')
  //   useEffect(()=>{
  //     getStripeKey()
  //   },[])

  //   const getStripeKey=async()=>{
  // try{
  // const res = await GetData(EndPoints.STRIPE_KEY)
  // if (res.status == 200 && res?.success_status == true) {
  //   setstripePromise(res.data?.stripe_key)
  // }
  // else if (res.status == 200 && res?.success_status == false) {
  //   toast.error(res.message)
  // }
  // else{
  //   toast.error(res.message)
  // }
  // }
  // catch(err){
  //   toast.error('Something went wrong')
  // }
  //   }

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {

    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    navigate('/');
    navigate(0)
  };

  useEffect(() => {
    getCardList()
  }, [])

  const getCardList = async () => {
    try {
      let res = await GetData(EndPoints.CARD_LIST + localStorage.getItem("user_id"))
      if (res?.status == 200 && res?.success_status == true) {

        let newArr = res?.cards?.map((x, i) => ({
          sr: i + 1,
          default: x.is_default ? 'Default' : '-',
          cardNum: `xxxx xxxx xxxx ${x?.last4}`,
          ...x
        }));
        setCardList(newArr)
      }
      else if (res?.status == 200 && res?.success_status == false) {
        setCardList([])
        // toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    }
    catch (err) {
      toast.error('Something went wrong')
    }
  }

  const handleDefaultCard = async (id, index) => {
    try {
      let data = {
        "user_id": localStorage.getItem("user_id"),
        "card_id": id
      }
      const res = await PostData(EndPoints?.DEFAULT_CARD, data)
      if (res?.status == 200 && res?.success_status == true) {
        // getCardList()
        setSelectedId(index)
        toast.success(res?.message)
      }
      else if (res?.status == 200 && res?.success_status == false) {
        toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    }
    catch (err) {
      toast.error('Something went wrong')
    }
  }

  const handleDelete = async (id) => {
    try {
      let data = {
        "user_id": localStorage.getItem("user_id"),
        "card_id": id
      }
      const res = await PostData(EndPoints?.DELETE_CARD, data)
      if (res?.status == 200 && res?.success_status == true) {
        getCardList()
        toast.success(res?.message)
      }
      else if (res?.status == 200 && res?.success_status == false) {
        toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    }
    catch (err) {
      toast.error('Something went wrong')
    }
  }


  const handlePay = async () => {
    try {
      const defaultCard = cardList.find(el => el.is_default === true);
      let data = {
        "card_id": defaultCard.card_id,
        "user_id": localStorage.getItem("user_id"),
        "sub_id": location?.state?.data?.id,
        "subs_type": location?.state?.data?.type,
        "amount": location?.state?.data?.amount,
      }
      let res = await PostData(EndPoints?.BUY_SUBS, data)
      if (res?.status == 200 && res?.success_status == true) {
        setIsModalOpen(true)
        toast.success(res?.message)
        // alert('Thank you, your payment has been successful.')
      }
      else if (res?.status == 200 && res?.success_status == false) {
        toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    }
    catch (err) {
      toast.error('Something went wrong')
    }
  }

  const handleReciverPay = async (el) => {
    try {
      const defaultCard = cardList.find(el => el.is_default === true);
      let data = {
        "user_id": localStorage.getItem("user_id"),
        "contest_id": el?.id,
        "amount": el?.entry_fee ,
        "card_id": defaultCard.card_id,
      }
      const res = await PostData(EndPoints?.CONTEST_PAY, data)
      if (res?.status == 200 && res?.success_status == true) {
        await handleAccept()
        // setIsModalOpen(true)
        // toast.success(res?.message)
        // alert('Thank you, your payment has been successful.')
      }
      else if (res?.status == 200 && res?.success_status == false) {
        toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }

    }
    catch (err) {
      toast.error('Something went wrong')
    }
  }

  const handleAccept = async () => {
    try {
      let data = {
        "contest_id": location.state.data?.id,
        "user_id": localStorage.getItem("user_id"),
        "refferal_userid": location.state.data?.senderId,
        "user_payment_status": "1",
        "amount": location.state.data?.entry_fee,
        "user_rapper": location.state.data?.rapperPlace == "0" ? 'rapper_name2' : 'rapper_name',
      }
      const res = await PostData(EndPoints?.INVITATION_ACCEPT, data)
      if (res?.status == 200 && res?.success_status == true) {
        setIsModalOpen(true)
      }
      else if (res?.status == 200 && res?.success_status == false) {
        toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    }
    catch (err) {
      toast.error('Something went wrong')
    }
  }

  const handleSenderPay = async (el) => {
    try {
      const defaultCard = cardList.find(el => el.is_default === true);
      let data = {
        "user_id": localStorage.getItem("user_id"),
        "contest_id": el?.id,
        "amount": el?.entry_fee,
        "card_id": defaultCard.card_id,
      }
      const res = await PostData(EndPoints?.CONTEST_PAY, data)
      if (res?.status == 200 && res?.success_status == true) {
        handleFinalJoin()
      }
      else if (res?.status == 200 && res?.success_status == false) {
        toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    }
    catch (err) {
      toast.error('Something went wrong')
    }
  }

  const handleFinalJoin = async () => {
    try {
      let data = {
        "contest_id": location.state.data?.id,
        "user_id": localStorage.getItem("user_id"),
        "refferal_rapper": location.state.rapperPlace == "0" ? 'rapper_name' : 'rapper_name2',
        "refferal_payment_status": 1
      }
      console.log(data,'datatatatattatata final join')
      let res = await PostData(EndPoints.FINAL_JOIN, data)
      if (res?.status == 200 && res?.success_status == true) {
        setIsModalOpen(true)
      }
      else if (res?.status == 200 && res?.success_status == false) {
        toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    }
    catch (err) {
      toast.error('Something went wrong')
    }
  }

  return (
    <>
      {location?.state?.step &&
        <HeaderAuthTop />}
      <div className="Profilesection" >
        <div className="container profilewrap" style={{ width: location?.state?.step ? "60%" : "92%", background: "#fff" }} >
          <Form name="form_item_path" layout="vertical">
            <h2 className="contesthomeform saveCardWrap">Payment Method
              {/* <button className="addCardBtn"><PlusOutlined className="pr-5" />Add Cards</button> */}
            </h2>
            <div className="border"></div>
            <div className="debitCardWrap saveCardForm">
              {stripePromise &&
                <Elements stripe={stripePromise}>
                  <CheckoutForm handleGetCardList={getCardList} />
                </Elements>}
              {/* <Row gutter={25}>
            <Col xs={24} sm={24} md={12} lg={12} xl={18}>
            <Form.Item
            label="Name on Card"
            name="cardname"
            rules={[
              {
                required: false,
                message: 'Name on card',
              },
            ]} >
            <Input placeholder="Vaishno Tiwari" />
            </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item
            label="Expiry(mm/yy)"
            name="--/--"
            rules={[
              {
                required: false,
                message: 'Expiry',
              },
            ]} >
            <Input placeholder="02/23" maxLength={5} />
            </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={18}>
            <Form.Item
            label="Card Number"
            name="cardnumber"
            rules={[
              {
                required: false,
                message: 'Card number',
              },
            ]} >
            <Input placeholder="1234 1234 1234 1234" maxLength={16} />
            </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <Form.Item
            label="CVV"
            name="cvv"
            rules={[
              {
                required: false,
                message: 'CVV',
              },
            ]} >
            <Input placeholder="CVV" maxLength={3} />
            </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="cardBtnWrap">
            <Typography.Title level={5} className="cardDetailsText">
            3 or 4 digits usually found on the signature strip
            </Typography.Title>
            <Button className="saveCardBtn">Save Card</Button>
            </div>
            </Col>
            </Row> */}

            </div>
          </Form>
        </div>

        <div className="container profilewrap SaveCardHistoryWrap" style={{ width: location?.state?.step ? "60%" : "92%", background: "#fff" }} >
          <Form name="form_item_path" layout="vertical">
            <h2 className="contesthomeform saveCardWrap">Save Cards History  </h2>
            <div className="border"></div>
            <div className="debitCardWrap saveCardHistory">
              <Row gutter={25}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {/* <Table columns={columns} dataSource={dataSource} style={{ width: '100%' }} /> */}

                  <TableContainer className="tableContainer" style={{ border: "1px solid #E0E7ED", background: "#fff", borderRadius: "3px" }}>
                    <Table>

                      <TableHead className='tableHeader'>
                        <TableRow>
                          <TableCell>Select</TableCell>
                          {/* <TableCell>Card Id</TableCell> */}
                          <TableCell>Card Number</TableCell>
                          <TableCell>Card Type</TableCell>

                          {/* <TableCell>Card Status</TableCell> */}
                          <TableCell>Action</TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cardList.length != 0 ?
                          <>
                            {cardList?.map((row, index) => {
                              const _checked = index === selectedId ? true : false
                              return (
                                <TableRow key={row.id}>
                                  {/* <TableCell>{row.sr}</TableCell> */}
                                  <TableCell>
                                    <Checkbox
                                      checked={_checked}
                                      // checked={row?.is_default}
                                      // onClick={()=>setchecked(!checked)}
                                      onClick={() => handleDefaultCard(row?.card_id, index)}
                                    >
                                    </Checkbox>
                                  </TableCell>
                                  {/* <TableCell>{row?.card_id}</TableCell> */}
                                  <TableCell>{row?.cardNum}</TableCell>
                                  <TableCell>{row?.brand}</TableCell>
                                  {/* <TableCell>{row?.default}</TableCell> */}

                                  <TableCell className='actionWidth' sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}>
                                    <div className="icontag" onClick={() => handleDelete(row.card_id)}>
                                      <Tooltip title='Delete'>
                                        <DeleteOutlined
                                          style={{ fontSize: "20px" }}
                                        />
                                      </Tooltip>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </> : <p style={{ textAlign: "center", fontSize: "15px", color: "red" }}>There is no record avilable.</p>
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {cardList.length != 0 ?
                    <>
                      {location?.state?.step == 2 &&
                        <>
                          {/* {location.state.data.payType == 'reciverPay' ?
                        <Button className="saveCardBtn" onClick={() => handleReciverPay(location.state.data)}>Pay</Button>
                        :
                        location.state.data.payType == 'senderPay' ?
                          <Button className="saveCardBtn" onClick={() => handleSenderPay(location.state.data)}>Pay</Button>
                          :
                          <Button className="saveCardBtn" onClick={() => handlePay()}>Pay</Button>
                      } */}
                          {location.state.data.payType == 'reciverPay' &&
                            <Button className="saveCardBtn" onClick={() => handleReciverPay(location.state.data)}>Pay</Button>}

                          {location.state.type == 'senderPay' ?
                            <Button className="saveCardBtn" onClick={() => handleSenderPay(location.state.data)}>Pay</Button>
                            : location.state.type == 'subs' ?

                              <Button className="saveCardBtn" onClick={() => handlePay()}>Pay</Button> : null
                          }
                        </>
                      }
                    </>
                    : ""
                  }

                </Col>
              </Row>
            </div>
          </Form>
        </div>

      </div>
      {location?.state?.step == 2 &&
        <FooterContent />}
      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <Title level={3} style={{ textAlign: 'center' }}>Thank you, your payment has been successful.</Title>
      </Modal>
    </>
  );
};

export default SaveCard;
const cardElementOptions = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    input: {
      backgoundColor: 'green'
    },
    invalid: {
      color: '#9e2146',
    },
  },
};