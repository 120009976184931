import { PlusOutlined } from '@ant-design/icons';
import { Col, Modal, Row, Tabs, Typography } from "antd";
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../App';
import FooterContent from '../../components/FooterContent';
import HeaderTop from "../../components/HeaderTop";
import LogIn from '../../components/LogIn';
import SignUp from '../../components/SignUp';
import { EndPoints } from '../../endpoint';
import calender from "../../images/calendar.svg";
import contestGraphic from "../../images/contestGraphic.svg";
import watch from "../../images/watch.svg";
import "../../pages/ContestPage/ContestPage.css";
import { GetData } from '../../services';

const { Title } = Typography;
const { TabPane } = Tabs;

function Contest() {
  const navigate = useNavigate()
  const [isAuth, setIsAuth] = useContext(AuthContext)
  const [upcomingdata, setUpcomingData] = useState([]);
  const [ongoingdata, setOngoingData] = useState([]);
  const [canceldata, setCancelData] = useState([]);
  const [commingSoondata, setCommingSoondata] = useState([]);
  const [isModalLoginOpen, setIsModalLoginOpen] = useState(false);
  const [isModasignuplOpen, setIsModalSignupOpen] = useState(false);

  const handleOk = () => {
    setIsModalLoginOpen(false);
  };
  const handleCancel = () => {
    setIsModalLoginOpen(false);
  };

  const handleOksignup = () => {
    setIsModalSignupOpen(false);
  };
  const handleCancelsignup = () => {
    setIsModalSignupOpen(false);
  };

  useEffect(() => {
    getUpcomingList();
    getOngoingList();
    getCancelList();
    getCommingSoon()
  }, [])

  const getUpcomingList = async () => {
    try {
      const res = await GetData(EndPoints.UPCOMINGCONTEST)
      if (res?.status == 200 && res?.success_status == true) {
        setUpcomingData(res?.data)
      }
      else if (res?.status == 200 && res?.success_status == false) {
        // toast.error(res?.message);
      }
      else {
        toast.error(res?.message);
      }
    }
    catch (err) {
      toast.error('Something went wrong');
    }
  }

  const getOngoingList = async () => {
    try {
      const res = await GetData(EndPoints.ONGOINGCONTEST)
      if (res?.status == 200 && res?.success_status == true) {
        setOngoingData(res?.data)
      }
      else if (res?.status == 200 && res?.success_status == false) {
        // toast.error(res?.message);
      }
      else {
        toast.error(res?.message);
      }
    }
    catch (err) {
      toast.error('Something went wrong');
    }
  }

  const getCancelList = async () => {
    try {
      const res = await GetData(EndPoints.CANCELLEDCONTEST)
      if (res?.status == 200 && res?.success_status == true) {
        setCancelData(res?.data)
      }
      else if (res?.status == 200 && res?.success_status == false) {
        // toast.error(res?.message);
      }
      else {
        toast.error(res?.message);
      }
    }
    catch (err) {
      toast.error('Something went wrong');
    }
  }

  const getCommingSoon = async () => {
    try {
      const res = await GetData(EndPoints.COMMINGSOON)
      if (res?.status == 200 && res?.success_status == true) {
        setCommingSoondata(res?.data)
      }
      else if (res?.status == 200 && res?.success_status == false) {
        // toast.error(res?.message);
      }
      else {
        toast.error(res?.message);
      }
    }
    catch (err) {
      toast.error('Something went wrong');
    }
  }

  return (
    <>
      <HeaderTop />
      <div className="InnerBannerSection">
        <img src={contestGraphic} className="contestGraphic" alt="" />
        <div className="container">
          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="sectionHeading text-center ">
                <Title className="">
                  <span className="text-white">Fantasy</span> Rapper
                </Title>
                <p className="smallHeading">
                  🎤 Welcome to the Fantasy Rapper Universe! 🚀

                  In a world where every beat counts, and every lyric tells a story, Fantasy Rapper is your backstage pass to the ultimate rap battle experience! 🌟
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="TabSectionArea">
        <div className="container">
          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Tabs defaultActiveKey="1" onChange={(key) => console.log(key)}>
                <TabPane tab="Upcoming" key="1">
                  <div className="innerContestPadd ">
                    <div className="sectionHeading text-left ">
                      <Title className="">Upcoming Contest</Title>
                    </div>
                    <Row gutter={16} align="middle" className="battleCardWrap">
                      {upcomingdata.map((battle) => {
                        return (
                          <Col xs={24} sm={24} md={24} lg={6} xl={6} key={""} style={{ marginBottom: "25px" }}>
                            <div className="battleCards">
                              <div className="battleImg">
                                <div className='battleCombImg'>
                                  <div className='battleFirstImg'>
                                    <img src={battle.rapper_image} alt="" className="" />
                                  </div>
                                  <div className='battleSecondImg'>
                                    <img src={battle.rapper_image2} alt="" className="" />
                                  </div>
                                </div>
                                <div className="battleName">
                                  <span className="battleFirst">{battle.rapper_name}</span>
                                  <span className="battleSecond">{battle.rapper_name2}</span>
                                </div>
                              </div>
                              <div className="battleInfo">
                                <Title className="battleDes">
                                  {battle.contest_name}
                                </Title>
                                <div className="battleTimeline">
                                  <span className="">
                                    <img src={calender} />
                                    {moment(battle.contest_date).format('MMM DD, YYYY')}
                                  </span>
                                  <span className="">
                                    <img src={watch} />
                                    <p>{battle.contest_time}</p>
                                  </span>
                                </div>
                                <div className="joinNowBtn" onClick={() => isAuth ? navigate('/joincontest', { state: { id: battle.id, banner: battle.banner_image } }) : setIsModalLoginOpen(true)}>
                                  Join Now
                                </div>
                              </div>
                            </div>
                          </Col>
                        )
                      })}
                    </Row>
                  </div>
                  {commingSoondata.length != 0 &&
                    <div className="innerContestPadd">
                      <div className="sectionHeading text-left ">
                        <Title className="">Coming Soon</Title>
                      </div>
                      <Row gutter={16} align="middle" className="battleCardWrap">
                        {commingSoondata.map((battle) => (
                          <Col xs={24} sm={24} md={24} lg={6} xl={6} key={""} style={{ marginBottom: "25px" }}>
                            <div className="battleCards">
                              <div className="battleImg">
                                <div className='battleCombImg'>
                                  <div className='battleFirstImg'>
                                    <img src={battle.rapper_image} alt="" className="" />
                                  </div>
                                  <div className='battleSecondImg'>
                                    <img src={battle.rapper_image2} alt="" className="" />
                                  </div>
                                </div>

                                <div className="battleName">
                                  <span className="battleFirst">{battle?.rapper_name}</span>
                                  <span className="battleSecond">{battle?.rapper_name2}</span>
                                </div>
                              </div>
                              <div className="battleInfo">
                                <Title className="battleDes">
                                  {battle?.contest_name}
                                </Title>
                                <div className="battleTimeline">
                                  <span className="">
                                    <img src={calender} />
                                    {moment(battle?.contest_date).format('MMM DD, YYYY')}
                                  </span>
                                  <span className="">
                                    <img src={watch} />
                                    {battle?.contest_time}
                                  </span>
                                </div>
                                <div className="joinNowBtn" onClick={() => { isAuth ? navigate('/joincontest', { state: { id: battle.id } }) : setIsModalLoginOpen(true) }}>
                                  Join Now
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>}
                </TabPane>
                <TabPane tab="Ongoing" key="2">
                  <div className="innerContestPadd">
                    <div className="sectionHeading text-left ">
                      <Title className="">Ongoing Contest</Title>
                    </div>
                    <Row gutter={16} align="middle" className="battleCardWrap">
                      {ongoingdata.map((battle) => (
                        <Col xs={24} sm={24} md={24} lg={6} xl={6} key={""} style={{ marginBottom: "25px" }}>
                          <div className="battleCards">
                            <div className="battleImg">
                              <div className='battleCombImg'>
                                <div className='battleFirstImg'>
                                  <img src={battle.rapper_image} alt="" className="" />
                                </div>
                                <div className='battleSecondImg'>
                                  <img src={battle.rapper_image2} alt="" className="" />
                                </div>
                              </div>

                              <div className="battleName">
                                <span className="battleFirst">{battle?.rapper_name}</span>
                                <span className="battleSecond">{battle?.rapper_name2}</span>
                              </div>
                            </div>
                            <div className="battleInfo">
                              <Title className="battleDes">
                                {battle?.contest_name}
                              </Title>
                              <div className="battleTimeline">
                                <span className="">
                                  <img src={calender} />
                                  {moment(battle?.contest_date).format('MMM DD, YYYY')}
                                </span>
                                <span className="">
                                  <img src={watch} />
                                  {battle?.contest_time}
                                </span>
                              </div>
                              <div className="joinNowBtn" onClick={() => { isAuth ? navigate('/joincontest', { state: { id: battle.id } }) : setIsModalLoginOpen(true) }}>
                                Join Now
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab="Cancelled" key="3">
                  <div className="innerContestPadd">
                    <div className="sectionHeading text-left ">
                      <Title className="">Cancelled Contest</Title>
                    </div>
                    <Row gutter={16} align="middle" className="battleCardWrap">
                      {canceldata.map((battle) => (
                        <Col xs={24} sm={24} md={24} lg={6} xl={6} key={""} style={{ marginBottom: "25px" }}>
                          <div className="battleCards">
                            <div className="battleImg">
                              <div className='battleCombImg'>
                                <div className='battleFirstImg'>
                                  <img src={battle.rapper_image} alt="" className="" />
                                </div>
                                <div className='battleSecondImg'>
                                  <img src={battle.rapper_image2} alt="" className="" />
                                </div>
                              </div>

                              <div className="battleName">
                                <span className="battleFirst">{battle.rapper_name}</span>
                                <span className="battleSecond">{battle.rapper_name2}</span>
                              </div>
                            </div>
                            <div className="battleInfo">
                              <Title className="battleDes">
                                {battle?.contest_name}
                              </Title>
                              <div className="battleTimeline">
                                <span className="">
                                  <img src={calender} />
                                  {moment(battle.contest_date).format('MMM DD, YYYY')}
                                </span>
                                <span className="">
                                  <img src={watch} />
                                  {battle?.contest_time}
                                </span>
                              </div>
                              <div className="joinNowBtn" onClick={() => { isAuth ? navigate('/joincontest', { state: { id: battle.id } }) : setIsModalLoginOpen(true) }}>
                                Join Now
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </TabPane>
              </Tabs>

              {/* <div onClick={()=>{isAuth?navigate("/CreateContest"):setIsModalLoginOpen(true)}} className="contestBtn">
                Create Contest <PlusOutlined />
              </div> */}
            </Col>
          </Row>
        </div>
      </div>
      <FooterContent />

      <Modal title="" className="loginModal" open={isModalLoginOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <LogIn />
        <div className='dontAccountWrap'>
          <span className='dontText'>Don’t have an account?</span>  <div onClick={() => { setIsModalSignupOpen(true); setIsModalLoginOpen(false) }} className="notSignLink">Sign up</div>
        </div>
      </Modal>


      <Modal title="" className="loginModal" open={isModasignuplOpen} onOk={handleOksignup} onCancel={handleCancelsignup} footer={null}>
        <SignUp />
        <div className='dontAccountWrap'>
          <span className='dontText'>Already have an account?</span>  <div onClick={() => { setIsModalSignupOpen(false); setIsModalLoginOpen(true) }} className="notSignLink">Log In</div>
        </div>
        <br />
        <div>
          <p className='dontText' style={{ textAlign: "center" }}>If you have any query or face any issue please connect us on<br />
            1800-2342-2342 Or mail Us support@fanstasy.com</p>
        </div>
      </Modal>
    </>
  );
}

export default Contest;
