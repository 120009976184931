import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 16 16",
    }}
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M11.6 10.2c-.1 0-.3 0-.4-.1-.2-.2-.2-.5 0-.7L12.6 8l-1.4-1.4c-.2-.2-.2-.5 0-.7.2-.2.5-.2.7 0l1.7 1.7c.2.2.2.5 0 .7L12 10.1c-.1.1-.2.1-.4.1z" />
    <path d="M13.3 8.5H6.5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h6.8c.3 0 .5.2.5.5s-.2.5-.5.5z" />
    <path d="M7.8 13.8C4.4 13.8 2 11.4 2 8s2.4-5.8 5.8-5.8c.3 0 .5.2.5.5s-.2.5-.5.5C5 3.2 3 5.2 3 8s2 4.8 4.8 4.8c.3 0 .5.2.5.5s-.2.5-.5.5z" />
  </svg>
)
export default SvgComponent
