import {
  Anchor,
  Button,
  Col,
  List,
  Modal,
  Row,
  Typography
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../App";
import CommunityBanner from "../../components/CommunityBanner";
import FooterContent from "../../components/FooterContent";
import HeaderAuthTop from "../../components/HeaderAuthTop";
import LogIn from "../../components/LogIn";
import SignUp from "../../components/SignUp";
import { EndPoints } from "../../endpoint";
import { GetData } from "../../services";
import "./CommunityPage.css";
import BannerAuth from "../../components/BannerAuth";
import ListIcon from '../../components/ListIcon';


const { Title } = Typography;
const { Link } = Anchor;

function CommunityPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [communityList, setCommunityList] = useState([]);
  const [communityDesc, setCommunityListDesc] = useState({});
  const [isAuth, setIsAuth] = useContext(AuthContext)
  const [isModalLoginOpen, setIsModalLoginOpen] = useState(false);
  const [isModasignuplOpen, setIsModalSignupOpen] = useState(false);

  const [step, setStep] = useState(0)

  const handleOk = () => {
    setIsModalLoginOpen(false);
  };
  const handleCancel = () => {
    setIsModalLoginOpen(false);
  };

  const handleOksignup = () => {
    setIsModalSignupOpen(false);
  };
  const handleCancelsignup = () => {
    setIsModalSignupOpen(false);
  };

  useEffect(() => {
    getCommunityList(0)
  }, [])

  const getCommunityList = async (val) => {
    try {
      const res = await GetData(EndPoints.COMMUNITY_LIST + localStorage.getItem("user_id"))
      if (res?.status == 200 && res?.success_status == true) {
        setCommunityList(res?.data)
        setCommunityListDesc(res?.data[val])
      }
      else if (res?.status == 200 && res?.success_status == true) {
        toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    }
    catch (err) {
      toast.error('Something went wrong');
    }
  }

  console.log(communityDesc, '0000')

  return (
    <>
      <HeaderAuthTop />
      <div className="Createcontestsection">
        {communityList.length != 0 ?
          <div className="container">
            <Row>
              <Col xl={5} md={5} lg={5} xs={5}>
                {/* <div className="communityLeftLink">
                <List
                  size="small"  
                  bordered
                  dataSource={communityList}
                  renderItem={(item, index) =>{ 
                    const backgroundColor = index === step ? '#6e3b6e' : '#fff';
                  return(
                    <List.Item style={{ backgroundColor: backgroundColor }} onClick={() => { setStep(index); getCommunityList(index) }}>
                    <Link  >{item.community_name}</Link>
                  </List.Item>
                  )}}
                />
              </div> */}
                <div className="communityLeftLink">
                  <List
                    size="small"
                    bordered
                    dataSource={communityList}
                    renderItem={(item, index) => {
                      const backgroundColor = index === step ? true : false
                      return (
                        <List.Item className={backgroundColor ? 'withActiveBG' : 'withoutActiveBG'} onClick={() => { setStep(index); getCommunityList(index); }}>
                          <Link><ListIcon />{item.community_name}</Link>
                        </List.Item>

                      )
                    }}

                  />
                </div>

              </Col>
              <Col xl={19} md={19} lg={19} xs={19}>
                <CommunityBanner data={communityDesc?.banner_images} />
                <Row>
                  <Col xl={24} md={24} lg={24} xs={24}>
                    <div className="container">
                      <div className="aboutCommunity">
                        <Title level={2}>About Community</Title>
                        <p style={{ color: '#000' }} dangerouslySetInnerHTML={{ __html: communityDesc?.about }}></p>
                        <Col xl={24} md={24} lg={24} xs={24} style={{ textAlign: 'right' }}>
                          <Button className="joinCommunity" onClick={() => { isAuth ? navigate('/CommunityCourse', { state: { data: communityDesc, step: step } }) : setIsModalLoginOpen(true) }}
                          >Join Community</Button>
                        </Col>
                      </div>

                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          :
          <p style={{ color: '#000', alignSelf: 'center' }}>No Community found</p>
        }
      </div>
      <FooterContent />

      <Modal title="" className="loginModal" open={isModalLoginOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <LogIn />
        <div className='dontAccountWrap'>
          <span className='dontText'>Don’t have an account?</span>  <div onClick={() => { setIsModalSignupOpen(true); setIsModalLoginOpen(false) }} className="notSignLink">Sign up</div>
        </div>
      </Modal>


      <Modal title="" className="loginModal" open={isModasignuplOpen} onOk={handleOksignup} onCancel={handleCancelsignup} footer={null}>
        <SignUp />
        <div className='dontAccountWrap'>
          <span className='dontText'>Already have an account?</span>  <div onClick={() => { setIsModalSignupOpen(false); setIsModalLoginOpen(true) }} className="notSignLink">Log In</div>
        </div>
        <br />
        <div>
          <p className='dontText' style={{ textAlign: "center" }}>If you have any query or face any issue please connect us on<br />
            1800-2342-2342 Or mail Us support@fanstasy.com</p>
        </div>
      </Modal>
    </>
  );
}

export default CommunityPage;