import * as React from "react"
const SvgComponent = (props) => (
 <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 16 16",
    }}
    viewBox="0 0 16 16"
    {...props}
    color={props.color}
  >
    <style>{".st0{opacity:.2;fill:#fff}"}</style>
    <path d="M4 10.5c-.1 0-.3 0-.4-.1l-2-2c-.2-.2-.2-.5 0-.7l2-2c.2-.2.5-.2.7 0l2 2c.2.2.2.5 0 .7l-2 2c0 .1-.2.1-.3.1zM2.7 8 4 9.3 5.3 8 4 6.7 2.7 8z" />
    <path
      d="M4 10.5c-.1 0-.3 0-.4-.1l-2-2c-.2-.2-.2-.5 0-.7l2-2c.2-.2.5-.2.7 0l2 2c.2.2.2.5 0 .7l-2 2c0 .1-.2.1-.3.1zM2.7 8 4 9.3 5.3 8 4 6.7 2.7 8z"
      className="st0"
    />
    <path d="M12 10.5c-.1 0-.3 0-.4-.1l-2-2c-.2-.2-.2-.5 0-.7l2-2c.2-.2.5-.2.7 0l2 2c.2.2.2.5 0 .7l-2 2c0 .1-.2.1-.3.1zM10.7 8 12 9.3 13.3 8 12 6.7 10.7 8z" />
    <path
      d="M12 10.5c-.1 0-.3 0-.4-.1l-2-2c-.2-.2-.2-.5 0-.7l2-2c.2-.2.5-.2.7 0l2 2c.2.2.2.5 0 .7l-2 2c0 .1-.2.1-.3.1zM10.7 8 12 9.3 13.3 8 12 6.7 10.7 8z"
      className="st0"
    />
    <path d="M8 6.5c-.1 0-.3 0-.4-.1l-2-2c-.2-.2-.2-.5 0-.7l2-2c.2-.2.5-.2.7 0l2 2c.2.2.2.5 0 .7l-2 2c0 .1-.2.1-.3.1zM6.7 4 8 5.3 9.3 4 8 2.7 6.7 4z" />
    <path
      d="M8 6.5c-.1 0-.3 0-.4-.1l-2-2c-.2-.2-.2-.5 0-.7l2-2c.2-.2.5-.2.7 0l2 2c.2.2.2.5 0 .7l-2 2c0 .1-.2.1-.3.1zM6.7 4 8 5.3 9.3 4 8 2.7 6.7 4z"
      className="st0"
    />
    <path d="M8 14.5c-.1 0-.3 0-.4-.1l-2-2c-.2-.2-.2-.5 0-.7l2-2c.2-.2.5-.2.7 0l2 2c.2.2.2.5 0 .7l-2 2c0 .1-.2.1-.3.1zM6.7 12 8 13.3 9.3 12 8 10.7 6.7 12z" />
    <path
      d="M8 14.5c-.1 0-.3 0-.4-.1l-2-2c-.2-.2-.2-.5 0-.7l2-2c.2-.2.5-.2.7 0l2 2c.2.2.2.5 0 .7l-2 2c0 .1-.2.1-.3.1zM6.7 12 8 13.3 9.3 12 8 10.7 6.7 12z"
      className="st0"
    />
  </svg>
)
export default SvgComponent
