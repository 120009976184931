import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 16 16",
    }}
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M8 15.2c-.5 0-.9-.2-1.3-.5l-1.1-1.1c-.3-.3-.7-.4-1.1-.4H4c-1.4 0-2.5-1.1-2.5-2.5V3.3C1.5 2 2.6.8 4 .8h8c1.4 0 2.5 1.1 2.5 2.5v7.3c0 1.4-1.1 2.5-2.5 2.5h-.5c-.4 0-.8.2-1.1.4l-1.1 1.1c-.4.4-.8.6-1.3.6zM4 1.8c-.8 0-1.5.7-1.5 1.5v7.3c0 .8.7 1.5 1.5 1.5h.5c.7 0 1.3.3 1.8.7l1.1 1.1c.3.3.9.3 1.2 0l1.1-1.1c.5-.5 1.1-.7 1.8-.7h.5c.8 0 1.5-.7 1.5-1.5V3.3c0-.8-.7-1.5-1.5-1.5H4z" />
    <path d="M6.9 9.7H5.1c-.3 0-.6-.1-.7-.4-.2-.2-.2-.5-.1-.8.2-.7.8-1.1 1.3-1.4.5-.4.8-.6.8-1 0-.3-.3-.6-.6-.6s-.6.3-.6.6-.2.5-.5.5-.5-.2-.5-.5c0-.9.7-1.6 1.6-1.6s1.6.7 1.6 1.6c0 .9-.7 1.4-1.3 1.8-.3.2-.6.5-.8.8h1.6c.3 0 .5.2.5.5 0 .2-.2.5-.5.5zM10.7 9.7c-.3 0-.5-.2-.5-.5v-.5H8.9c-.3 0-.6-.2-.8-.5-.2-.2-.2-.6 0-.9.5-.8 1-1.7 1.5-2.4.2-.3.6-.5 1-.4.4.1.6.5.6.9v2.3h.1c.3 0 .5.2.5.5s-.2.5-.5.5h-.1v.5c0 .3-.2.5-.5.5zm-.5-3.9c-.4.6-.8 1.3-1.2 1.9h1.2V5.8z" />
  </svg>
)
export default SvgComponent
