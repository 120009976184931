import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 16 16",
    }}
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M11.3 12.5H4.7c-.5 0-.9 0-1.2-.1C1.3 12.2.8 10.8.8 8.6V7.3c0-2.2.4-3.5 2.7-3.8.3 0 .7-.1 1.2-.1h6.7c.5 0 .9 0 1.2.1 2.2.2 2.7 1.6 2.7 3.8v1.3c0 2.2-.4 3.5-2.7 3.8-.4.1-.8.1-1.3.1zm-6.6-8c-.5 0-.8 0-1 .1-1.4.1-1.9.5-1.9 2.7v1.3c0 2.2.4 2.6 1.8 2.8.3 0 .6.1 1.1.1h6.7c.5 0 .8 0 1-.1 1.4-.1 1.8-.6 1.8-2.8V7.3c0-2.2-.4-2.6-1.8-2.8-.3 0-.6-.1-1.1-.1H4.7z" />
    <path d="M10.2 15.2H5.8c-2.1 0-2.7-.7-2.7-2.7V12c0-.1.1-.3.2-.4.1-.1.2-.1.4-.1.3 0 .6.1 1 .1h6.7c.5 0 .8 0 1-.1.1 0 .3 0 .4.1.1.1.2.2.2.4v.5c-.1 2-.7 2.7-2.8 2.7zm-6.1-2.7c0 1.5.2 1.7 1.7 1.7h4.4c1.5 0 1.7-.2 1.7-1.7H4.1zM12.4 4.6c-.3 0-.7-.1-1.1-.1H4.7c-.5 0-.8 0-1 .1-.1 0-.3 0-.4-.1-.2-.1-.2-.3-.2-.4v-.5c0-2.1.7-2.7 2.7-2.7h4.4c2.1 0 2.7.7 2.7 2.7v.5c0 .1-.1.3-.2.4 0 0-.1.1-.3.1zM4.7 3.5H12c0-1.5-.2-1.7-1.7-1.7H5.8c-1.5 0-1.7.2-1.7 1.7h.6z" />
  </svg>
)
export default SvgComponent
