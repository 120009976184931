import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Carousel, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { EndPoints } from '../../src/endpoint';
import { GetData } from '../../src/services';
import bannerimg from '../images/Carousel image.png';
import "../styles/BannerAuth.css";


function BannerAuth(){
  const [bannerdata, setBannerdata]= useState([]);

  useEffect(()=>{
    getBannerPage();
  },[])

  //BANNER SECTION
  const getBannerPage =async()=>{
    try{

    const res = await GetData(EndPoints.BANNERDATA)
    if(res?.status ==200 && res?.success_status ==true){
      setBannerdata(res?.data)
    }
    else if(res?.status ==200 && res?.success_status ==false){
      toast.error(res?.message)
    }
    else{
      toast.error(res?.message)
    }
  }
  catch(err){
    toast.error('Something went wrong');
  }
    }
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      nextArrow: <ArrowRightOutlined />,
      prevArrow: <ArrowLeftOutlined />,
  }
  
    return(
        <div className="container">
       <div className="AuthBannerSection">
              
        <Carousel autoplay {...settings} arrows={true}>
        {bannerdata.map((banner) => (
        <div>
        <Row gutter={16} align="middle">
        <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{padding: "0px 40px"}} className='leftbannerwrap'>
        <h3 className="bannerDes contestsectiontitle">{banner.title}</h3>
        <p className="bannerDes contestsectionsubtitle">{banner.subtitle}</p>
        <p className="bannerDes contestsectionheading">{banner.heading}</p>
        <a href="/Contestpage" className="contestbannerBtn">Join Now</a>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={11} style={{padding: "20px 10px"}}>
        <div className="contestbannerImg"> 
        <img src={bannerimg} height={290} width={650}  alt="Banner" />
        </div>
        </Col>
        </Row>
          </div>
        ))}
         </Carousel>
          </div>
         </div>
        
    );
}

export default BannerAuth;