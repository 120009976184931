import React from "react";
import HeaderTop from "../../components/HeaderTop";
import { Row, Col, Typography, Anchor, Tabs } from "antd";
import "../../pages/ContestPage/ContestPage.css";
import calender from "../../images/calendar.svg";
import contestGraphic from "../../images/contestGraphic.svg";
import watch from "../../images/watch.svg";
import FooterContent from '../../components/FooterContent';
import { PlusOutlined } from '@ant-design/icons'

const { Title } = Typography;
const { Link } = Anchor;
const { TabPane } = Tabs;
const battles = [
  {
    id: "1",
    battleimg: require("../../images/b-1.png"),
    winnerShieldimg: require("../../images/shield.png"),
    winnername: "Arlene",
    winnerNumimg: require("../../images/onebatch.png"),
  },
  {
    id: "2",
    battleimg: require("../../images/b-2.png"),
    winnerShieldimg: require("../../images/shield.png"),
    winnername: "Arlene",
    winnerNumimg: require("../../images/onebatch.png"),
  },
  {
    id: "3",
    battleimg: require("../../images/b-3.png"),
    winnerShieldimg: require("../../images/shield.png"),
    winnername: "Arlene",
    winnerNumimg: require("../../images/onebatch.png"),
  },
  {
    id: "4",
    battleimg: require("../../images/b-4.png"),
    winnerShieldimg: require("../../images/shield.png"),
    winnername: "Arlene",
    winnerNumimg: require("../../images/onebatch.png"),
  },
  {
    id: "5",
    battleimg: require("../../images/b-4.png"),
    winnerShieldimg: require("../../images/shield.png"),
    winnername: "Arlene",
    winnerNumimg: require("../../images/onebatch.png"),
  },
];
function AboutUs() {
  return (
    <>
      <HeaderTop />
      <div className="InnerBannerSection">
      <img src={contestGraphic} className="contestGraphic" alt=""/>
        <div className="container">
          <Row gutter={16} align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="sectionHeading text-center ">
                <Title className="">
                  <span className="text-white"></span>ABOUT US
                </Title>
                <p className="smallHeading">
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  text commonly used to demonstrate the visual form of a
                  document or a typeface without
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div style={{margin: "60px", textAlign:"center"}}>
      <h1 style={{fontSize: "80px"}}>COMING SOON........</h1>
      </div>
      <FooterContent />
    </>
  );
}

export default AboutUs;
