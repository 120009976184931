import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 16 16",
    }}
    viewBox="0 0 16 16"
    {...props}
  >
    <style>{".st0{opacity:.2;fill:#fff}"}</style>
    <path d="M8 14.5c-3.6 0-6.5-2.9-6.5-6.5S4.4 1.5 8 1.5s6.5 2.9 6.5 6.5-2.9 6.5-6.5 6.5zm0-12C5 2.5 2.5 5 2.5 8S5 13.5 8 13.5 13.5 11 13.5 8 11 2.5 8 2.5z" />
    <path
      d="M8 14.5c-3.6 0-6.5-2.9-6.5-6.5S4.4 1.5 8 1.5s6.5 2.9 6.5 6.5-2.9 6.5-6.5 6.5zm0-12C5 2.5 2.5 5 2.5 8S5 13.5 8 13.5 13.5 11 13.5 8 11 2.5 8 2.5z"
      className="st0"
    />
    <path d="M8 9.2C6.6 9.2 5.5 8 5.5 6.7S6.6 4.2 8 4.2s2.5 1.1 2.5 2.5S9.4 9.2 8 9.2zm0-4c-.8 0-1.5.7-1.5 1.5S7.2 8.2 8 8.2s1.5-.7 1.5-1.5S8.8 5.2 8 5.2z" />
    <path
      d="M8 9.2C6.6 9.2 5.5 8 5.5 6.7S6.6 4.2 8 4.2s2.5 1.1 2.5 2.5S9.4 9.2 8 9.2zm0-4c-.8 0-1.5.7-1.5 1.5S7.2 8.2 8 8.2s1.5-.7 1.5-1.5S8.8 5.2 8 5.2z"
      className="st0"
    />
    <path d="M11.9 13.1c-.2 0-.4-.1-.5-.4-.3-.9-1.1-1.5-2.1-1.5H6.7c-1 0-1.8.6-2.1 1.5-.1.3-.4.4-.6.3-.3-.1-.4-.4-.3-.6.4-1.3 1.6-2.3 3-2.3h2.7c1.4 0 2.6.9 3 2.3.1.3-.1.5-.3.6-.1.1-.2.1-.2.1z" />
    <path
      d="M11.9 13.1c-.2 0-.4-.1-.5-.4-.3-.9-1.1-1.5-2.1-1.5H6.7c-1 0-1.8.6-2.1 1.5-.1.3-.4.4-.6.3-.3-.1-.4-.4-.3-.6.4-1.3 1.6-2.3 3-2.3h2.7c1.4 0 2.6.9 3 2.3.1.3-.1.5-.3.6-.1.1-.2.1-.2.1z"
      className="st0"
    />
  </svg>
)
export default SvgComponent
