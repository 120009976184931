import React, { useContext, useState } from 'react';
import { Layout, Menu, Row, Col, Typography, Modal, Form, Input, Anchor, Button, Checkbox } from 'antd';
import logo from '../images/fantasy-logo.svg';
import blacklogo from '../images/blacklogo.svg';
import { PostData } from '../services';
import { EndPoints } from '../endpoint';
import { useNavigate } from 'react-router-dom';
import Validator from '../Validator';
import { toast } from 'react-toastify';
import { AuthContext } from '../App';


const MyFormItemContext = React.createContext([]);
const { Title } = Typography;
const { Link } = Anchor;

const MyFormItemGroup = ({ prefix, children }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(() => [...prefixPath, ...toArr(prefix)], [prefixPath, prefix]);
  return <MyFormItemContext.Provider value={concatPath}>{children}</MyFormItemContext.Provider>;
};

function toArr(str) {
  return Array.isArray(str) ? str : [str];
}
const MyFormItem = ({ name, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName = name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
  return <Form.Item name={concatName} {...props} />;
};

function LogIn() {
  const [isAuth, setIsAuth] = useContext(AuthContext)
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      if (!Validator.isValidEmail(email)) {
        toast.error("Please enter valid email");
      } else if (Validator.isEmpty(password)) {
        toast.error('Please enter your Password');
      } else {
        let data = {
          email: email,
          password: password,
        };

        const res = await PostData(EndPoints.LOGIN, data);
        console.log(res,'88888')
        if (res.status == 200 && res?.success_status == true) {
          toast.success(res?.message);
          localStorage.setItem("user_id", res?.user?.id);
          localStorage.setItem("access_token", res?.access_token);
          localStorage.setItem("proImg", res?.user?.image ? res?.user?.image : '');
          setIsAuth(true)
          // navigate('/');
          // navigate(0)
          // return navigate("/Contestpage");  
        } else if (res.status == 200 && res?.success_status == false) {
         toast.error(res.message);

        } else {
        //  toast.error(res.message);
        }
      }
    } catch (err) {
      console.log(err, 'errrrrrrrr')
    //  toast.error("Something went wrong");
    }
  };

  const onFinish = (value) => {
  };

  return (

    <div className='loginForm'>
      <div className='text-center'>
        <img src={blacklogo} alt="logo" />
        <Title className='modalHeading'>Log in to your account</Title>
        <p className='ModalSmallHeading'>Welcome back! Please enter your details.</p>
      </div>
      <Form name="form_item_path" layout="vertical" onFinish={onFinish}>
        <MyFormItemGroup prefix={['user']}>
          <MyFormItemGroup prefix={['name']}>
            <MyFormItem name="email" label="Email *"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {

                  message: 'Please input your E-mail!',
                },
              ]}>
              <Input
                onChange={(e) => setEmail(e.target.value)} />
            </MyFormItem>
            <MyFormItem name="password" label="Password *"
              rules={[
                {
                  type: 'password',
                  message: 'The input is not valid E-mail!',
                },
                {

                  message: 'Please input your Password',
                },
              ]}>
              <Input.Password
                onChange={(e) => setPassword(e.target.value)} />
            </MyFormItem>
            <MyFormItem className="forgotLinkWrap">
              {/* <Checkbox>Remember</Checkbox> */}
              <div></div>
              {/* <Link href="" className="forgotLink">Forgot password ?</Link>   */}
              <div onClick={() => navigate('/ForgotPage')} className="forgotLink">Forgot password </div>
            </MyFormItem>
          </MyFormItemGroup>
        </MyFormItemGroup>

        <Button type="primary" htmlType="submit" className='loginBtnModal' onClick={() => handleLogin()}> Log In </Button>
      </Form>
      {/* <div className='dontAccountWrap'>
    <span className='dontText'>Don’t have an account?</span>  <a href="/" className="notSignLink">Sign up</a> 
    </div> */}
    </div>
  );
}

export default LogIn;