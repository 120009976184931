// import CONSTANTS from "./Constants";
export default {
    isEmptyObject(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    },
    isEmpty(str) {
      if (str == null || str == "" || str == undefined || str.length == 0) {
        return true;
      } else return false;
    },
    isValidOtp(str) {
      if (str.isEmpty || str == "" || str.length < 6) {
        return false;
      } else return true;
    },
    isValidMobile(str) {
      if (str == null || str == "") return false;
      else {
        const re = /^[56789]\d{9}$/;
        return re.test(String(str));
      }
    },
    isValidPinCode(str) {
      str = str.trim();
      if (str.length != 6 || str.substring(0, 1) == "0") {
        return false;
      } else return true;
    },
    isValidEmail(str) {
      if (str == null || str == undefined || str == "") return false;
      else {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(str).toLowerCase());
      }
    },
    isValidAccountNo(str) {
      if (str == null || str == undefined || str == "") return false;
      else {
        const re = /[0-9]{9,18}/;
        return re.test(String(str).toLowerCase());
      }
    },
    isValidGSTn(str) {
      if (str == null || str == undefined || str == "" || str.isEmpty) return false;
      else {
        const re = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        // return regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(g)
        return re.test(String(str).toUpperCase());
      }
    },
  
    isValidPassword(str) {
      if (str == null || str == "") return false;
      else {
        var passwordRedex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
        if (str.match(passwordRedex)) return true;
        else return false;
      }
    },
  
    isValidName(str){
      if (str == null || str == "" || str == undefined || str.length == 0) return false;
      else {
        var nameRedex = new RegExp('/^[a-zA-Z]*$/');
        if (str.match(nameRedex)) return true;
        else return false;
      }

    },
      isValidConfirmPassword(str1, str2) {
      if (str1 == str2) return true;
      else return false;
    },
    isValidAadhar(str) {
      if (str.trim() == "" || str.trim().length < 12 || str.trim().length > 12)
        return false;
      else return true;
    }
  };
  