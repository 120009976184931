import {
  Anchor,
  Button,
  Col,
  Form,
  Input,
  Row,
  Typography,Select,Space
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FooterContent from "../../components/FooterContent";
import HeaderAuthTop from "../../components/HeaderAuthTop";
import { EndPoints } from "../../endpoint";
import Call from "../../images/Icon_callus.svg";
import Email from "../../images/Icon_Emailus.svg";
import Support from "../../images/Icon_Support.svg";
import { GetData, PostData } from "../../services";
import "../Profile/UserProfile.css";
import Validator from "../../Validator";


const MyFormItemContext = React.createContext([]);
const to = "abs@gmail.com";
const subject ="Lorem Ipsum";

const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(subject)}`;
const { Title } = Typography;
const { Link } = Anchor;
const { TextArea } = Input;

const MyFormItemGroup = ({ prefix, children }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(
    () => [...prefixPath, ...toArr(prefix)],
    [prefixPath, prefix]
  );
  return (
    <MyFormItemContext.Provider value={concatPath}>
      {children}
    </MyFormItemContext.Provider>
  );
};

function toArr(str) {
  return Array.isArray(str) ? str : [str];
}
const MyFormItem = ({ name, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
  return <Form.Item name={concatName} {...props} />;
};

function HelpandCenter() {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [name,setName]=useState('');
  const [email,setEmail]=useState('');
  const [companyName,setCompanyName]=useState('');
  const [msg,setMsg]=useState('')
  const [countryValue,setCountryValue]=('')
  const [countryVal, setCountryVal] = useState("")
  const [country,setCountry]=useState([
    { value: 'option1', label: 'Option 1' },
  { value: 'option2', label: 'Option 2' },
  { value: 'option3', label: 'Option 3' }])
 

  const { Option } = Select;
  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const res = await GetData(EndPoints?.HELPANDCENTER)
      if (res?.status == 200 && res?.success_status == true) {
        let newArr = res.data.map((x, i) => ({
          sr: i + 1,
          ...x
          }));
        setData(newArr)
      }
      else if (res?.status == 200 && res?.success_status == true) {
        toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    }
    catch (err) {
      toast.error('Something went wrong')
    }
  }

const handleSubmit=async()=>{
  try{
if(Validator.isEmpty(name)){
toast.error('Please enter your name')
}
else if(!Validator.isValidEmail(email)){
  toast.error('Please enter your valid email')
}
else if(Validator.isEmpty(companyName)){
  toast.error('Please enter your comapany name')
}
else if(Validator.isEmpty(countryVal)){
  toast.error('Please enter your comapany name')
}
else if(Validator.isEmpty(msg)){
  toast.error('Please enter your message')
}
else{
  let data ={
    "username":name,
    "email":email,
    "mobile_no":companyName,
    "country":countryVal,
    "query":msg,
    "user_id":localStorage.getItem("user_id"),
    "type":'helps'
  }
const res = await PostData(EndPoints.HELP_FORM,data)
if (res?.status == 200 && res?.success_status == true) {
  toast.success(res.message)
  setTimeout(() => {
    navigate('/');
  navigate(0);
  }, "1000");
}
else if (res?.status == 200 && res?.success_status == false) {
  toast.error('Please enter your message')
}
else{
  toast.error('Please enter your message')
}
}
  }
  catch(err){
    toast.error('Something went wrong')
  }

}

  return (
    <>
      {/* <HeaderAuthTop /> */}
      <div className="Profilesection" >
        <div className="container profilewrap" style={{ width: "92%", background: "#fff" }} >
          <Form name="form_item_path" layout="vertical">
            <h2 className="contesthomeform">Help and Center</h2>
            <div className="border"></div>

            {data?.map((el) => {
              return (
                <div className="container couponrowrap" style={{border:"none",margin:'0'}}>
                 <Row>
                      <Col className="tandcwrap" >
                      <p>🌟 Connect with Us, Craft the Future: Contact Us Today! 🌐🚀

Hey Fantasy Legends! 👋 Ready to take your Fantasy Web App experience to the next level? We're just a click away.
</p>
                   {/* <p dangerouslySetInnerHTML={{__html:el?.description}}></p> */}
                   </Col>
                  </Row>
                <div className="helpForm">
                <Row gutter={20}>
                <Col xl={12} lg={12} md={12} xs={12}>
                <Form.Item label="Your Name" 
                 name="name"
                 rules={[
                   {
                     required: true,
                     message: 'Please input your name',
                   },
                 ]}>
                <Input placeholder="" value={name} onChange={(el)=>setName(el.target.value)}/>
                </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} xs={12}>
                <Form.Item label="CONTACT EMAIL"  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'you@company.com',
                    },
                  ]}>
                <Input placeholder="" value={email} onChange={(el)=>setEmail(el.target.value)}/>
              </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} xs={12}>
                <Form.Item label="MOBILE NUMBER"
                name="phonenumber"
                 rules={[
                  {
                    required: true,
                    message: '+1 (555) 000-0000',
                  }]}>
                  
                <Input placeholder="" value={companyName} onChange={(el)=>setCompanyName(el.target.value)}/>
              </Form.Item>
                </Col>
                {/* <Col xl={12} lg={12} md={12} xs={12}>
                <Form.Item label="COUNTRY NAME" >
               
                <Select style={{ width: '100%' }}  onChange={(el) => setCountryVal(el)}>
      {country.map((option) => (
        <Option key={option.value} value={option.value} >
          {option.label}
        </Option>
      ))}
    </Select>
              </Form.Item>
                </Col> */}

<Col xl={12} lg={12} md={12} xs={12}>
                <Form.Item label="COUNTRY NAME" 
                 name="country"
                 rules={[
                   {
                     required: true,
                     message: 'Please input your country name',
                   },
                 ]}>
                <Input placeholder="" value={countryVal} onChange={(el)=>setCountryVal(el.target.value)}/>
              </Form.Item>
                </Col>

                <Col xl={24} lg={24} md={24} xs={24}>
                <Form.Item label="Message"  name="Message"
                  rules={[
                    {
                      required: true,
                      message: 'message',
                    },
                  ]}>
               <TextArea rows={4} onChange={(el) => setMsg(el.target.value)}/>
              </Form.Item>
              </Col>
              <Col xl={24} lg={24} md={24} xs={24}>
              By submitting this form you agree to our terms and conditions and our Privacy Policy which explains how we may collect, use and disclose your personal information including to third parties.
              </Col>
              <Col xl={24} lg={24} md={24} xs={24}>
              <div className="sendNowHelp">
              <Button className="" onClick={()=>handleSubmit()}>Send Now</Button>
              </div>
              </Col>
              </Row>
                </div>
                <div className="helpCOntactDetail">
                <Row gutter={20}>
                <Col xl={8} lg={8} md={8} xs={8}>
                <div className="contactHelpBox">
                <div className="IcoBox">
                <img src={Email} />
                </div>
                <Title level={4}>Email Us</Title>
                <p>Email us for general queries, including marketing and partnership opportunities.</p>
                <p className=""> <Link
                to='#'
                onClick={(e) => {
                    window.location.href = mailtoLink;
                    e.preventDefault();
                }}
            >
              hello@helpcenter.com
            </Link></p>
               
                </div>
                </Col>
                <Col xl={8} lg={8} md={8} xs={8}>
                <div className="contactHelpBox">
                <div className="IcoBox">
                <img src={Call} />
                </div>
                <Title level={4}>Call Us</Title>
                <p>Email us for general queries, including marketing and partnership opportunities.</p>
                <p className=""> <Link
                to='#'
                onClick={(e) => {
                    window.location.href = mailtoLink;
                    e.preventDefault();
                }}
            >
            +1 (646) 786-5060
            </Link></p>
               
                </div>
                </Col>
                <Col xl={8} lg={8} md={8} xs={8}>
                <div className="contactHelpBox">
                <div className="IcoBox">
                <img src={Support} />
                </div>
                <Title level={4}>Support</Title>
                <p>Email us for general queries, including marketing and partnership opportunities.</p>
                <p className=""> <Link
                to='#'
                onClick={(e) => {
                    window.location.href = mailtoLink;
                    e.preventDefault();
                }}
            >
              Support Center
            </Link></p>
               
                </div>
                </Col>
                </Row>
                </div>
                </div>  
              )
            })}

          </Form>
        </div>
      </div>

    </>
  );
}

export default HelpandCenter;
