import React, { useEffect, useState } from "react";

import { Anchor, Col, Collapse, Row, Typography } from "antd";

// import "../../pages/ContestPage/ContestPage.css";
// import "../../pages/CreateContest/CreateContest.css";
import { CheckOutlined, ExportOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import BannerAuth from "../../components/BannerAuth";
import FooterContent from "../../components/FooterContent";
import HeaderAuthTop from "../../components/HeaderAuthTop";
import coursevideo from "../../images/coursevideo.png";
import "./CommunityCourseDetails.css";
import { GetData } from "../../services";
import { EndPoints } from "../../endpoint";
import { toast } from "react-toastify";
import ReactPlayer from "react-player";

import DefaultCommunityBanner from '../../images/defaultCommunityBanner.jpg'

const { Title } = Typography;
const { Link } = Anchor;

const { Panel } = Collapse;

const courselearn = [
  {
    id: 1,
    learnlist:
      "You will learn how to leverage the power of Python to solve tasks.",
  },
  {
    id: 2,
    learnlist:
      "You will be able to use Python for your own work problems or personal projects.",
  },
  {
    id: 3,
    learnlist:
      "Learn to use Python professionally, learning both Python 2 and Python 3!",
  },
  {
    id: 4,
    learnlist:
      "Learn advanced Python features, like the collections module and how to work with timestamps!",
  },
  {
    id: 4,
    learnlist: "You will build games and programs that use Python libraries.",
  },
  {
    id: 5,
    learnlist:
      "You will create a portfolio of Python based projects you can share.",
  },
  {
    id: 6,
    learnlist: "Create games with Python, like Tic Tac Toe and Blackjack!",
  },
  {
    id: 7,
    learnlist: "Learn to use Object Oriented Programming with classes!",
  },
];

const courseinclude = [
  {
    id: 1,
    courseinclude: "22 hours on-demand video",
  },
  {
    id: 2,
    courseinclude: "Access on mobile and TV",
  },
  {
    id: 3,
    courseinclude: "19 coding exercises",
  },
  {
    id: 4,
    courseinclude: "Certificate of completion",
  },
  {
    id: 5,
    courseinclude: "15 articles",
  },
];

function CommunityPage() {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [step, setStep] = useState(0);
  const [videoId, setVideoId] = useState(0);

  const [communityList, setCommunityList] = useState([
    "Community Name 1",
    "Community Name 2",
    "Community Name 3",
  ]);

  useEffect(() => {
    if (location?.state?.step) {
      setStep(location?.state?.step);
      getCommunityList(location?.state?.step);
    } else {
      getCommunityList(0);
    }
  }, []);

  const getCommunityList = async (val) => {
    try {
      const res = await GetData(
        EndPoints.COMMUNITY_LIST + localStorage.getItem("user_id")
      );
      if (res?.status == 200 && res?.success_status == true) {
        const filteredCourselist = await res?.data[val]?.courselist?.filter(
          (course) => course?.featured == 1
        );
        setCommunityList(res?.data);
      } else if (res?.status == 200 && res?.success_status == false) {
        toast.error(res?.message);
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    try {
      let res = await GetData(EndPoints.COURSE_DETAILS + location?.state?.id);
      if (res.status == 200 && res?.success_status == true) {
        setData(res?.data[0]);
        if (res?.data[0]?.course_module.length != 0) {
          setVideoUrl(res?.data[0]?.course_module[0]?.video);
        }
      } else if (res.status == 200 && res?.success_status == false) {
        toast.error(res?.message);
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };


  return (
    <>
      <HeaderAuthTop />
      <div className="Createcontestsection">
        <div className="container">
        <Row className=" contestDetailsBanner">
        <Col xl={24} md={24} lg={24} xs={24}>
        <div className="container">
        <div className="contestbannerImg">

        defaultCommunityBanner
        {data.course_banner ?
          <img
            src={data.course_banner}
            height={290}
            width={650}
            alt="Banner"
          />
        :
        <img
            src={DefaultCommunityBanner}
            height={290}
            width={650}
            alt="Banner"
          />
}
      </div>
      </div>
        </Col>
        </Row>
              <Row>
                <Col xl={24} md={24} lg={24} xs={24}>
                  <div className="container">
                    <Row gutter={20}>
                      <Col xl={16} md={16} lg={16} sm={16} xs={16}>
                        <div className="aboutCommunity">
                          <div className="courseLearn">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data?.description,
                              }}
                              style={{ color: "#000" }}
                            ></div>
                          </div>

                          <div className="courseCOntent">
                            <Title level={4}>Course content</Title>
                            <div className="courseDuration">
                              <span>{data?.totalength} lectures</span>{" "}
                              <span>•</span>{" "}
                              <span>{data?.section} total length</span>
                            </div>
                            <div className="courseWrapContent">
                              {data?.course_module?.map((el) => {
                                return (
                                  <div
                                    className="courseDurationWrap"
                                    onClick={() => {
                                      setVideoUrl(el?.video);
                                    }}
                                  >
                                    <div
                                      className="courseNameText"
                                      style={{ color: "#000" }}
                                    >
                                      {el?.module_name}
                                    </div>
                                    <div style={{ color: "#000" }}>
                                      {el?.totalhour}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xl={8} md={8} lg={8} sm={8} xs={8}>
                        <div className="CourseVideos">
                          <div className="courseBoxRight">
                            <div className="coursevideo">
                              <ReactPlayer
                                url={videoUrl}
                                width="100%"
                                height="100%"
                                controls
                              />
                            </div>
                            <Title level={4} className="">
                              Course Name: How to play the game begginer
                            </Title>
                            <div className="courseDuration">
                              <strong>Total Time Duration: </strong>
                              <span className="courseTimeDuration">
                                {data?.section} <ExportOutlined />
                              </span>
                            </div>
                          </div>
                          <div className="courseVideoList">
                            {data?.course_module?.map((el, index) => {
                              const backgroundColor =
                                index === videoId ? true : false;
                              return (
                                <div
                                  className={
                                    backgroundColor
                                      ? "courseVideoListWrap activeBG"
                                      : "courseVideoListWrap"
                                  }
                                  onClick={() => {
                                    setVideoUrl(el?.video);
                                    setVideoId(index);
                                  }}
                                >
                                  <div class="courseVideoImg">
                                    {/* <img src={el?.thumbnail} alt="" className="" />--------- */}
                                    <ReactPlayer
                                      url={el.video}
                                      width={110}
                                      height={90}
                                      controls
                                    />
                                  </div>
                                  <div class="courseVideoContent">
                                    <Title level={4} className="">
                                      {el?.module_name}
                                    </Title>
                                    <div className="courseDuration">
                                      Duration: {el?.totalhour}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {/* <div className="courseVideoListWrap">
   <div class="courseVideoImg">
   <img src={coursevideo}  alt="" className="" />
   </div>
   <div class="courseVideoContent">
   <Title level={4} className="">Course 2</Title>
   <div className="courseDuration">
   Duration: 2 hours 30 minutes
   </div>
    </div>
    </div> */}
                            {/* <div className="courseVideoListWrap">
   <div class="courseVideoImg">
   <img src={coursevideo}  alt="" className="" />
   </div>
   <div class="courseVideoContent">
   <Title level={4} className="">Course 3</Title>
   <div className="courseDuration">
   Duration: 2 hours 30 minutes
   </div>
    </div>
    </div> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
           
        </div>
      </div>
      <FooterContent />
    </>
  );
}

export default CommunityPage;
