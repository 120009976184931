import {
  Col,
  Divider,
  Row
} from "antd";
import { useState } from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import useClipboard from "react-use-clipboard";

export default function Socialshare(props) {
  const [copyValue, setCopyValue] = useState();
 
  // const shareUrl =props.shareData?props.shareData: `http://localhost:3000/joinbattlecontestentPage?contest_id=${props.data.id}&sender=${localStorage.getItem("user_id")}&senderPerson=${props?.senderPerson}`;
  const shareUrl =props.shareData?props.shareData: `${props.data?.baseurl}/joinbattlecontestentPage?contest_id=${props.data.id}&sender=${localStorage.getItem("user_id")}&senderPerson=${props?.senderPerson}`;
  const [isCopied, setCopied] = useClipboard(shareUrl);
  const title = "Check out this amazing website!";

  return (
    <>
      <div className="fieldWrappeer">
        <br />
        <Row className="boxwrap">
          <p required size="large" className="socialinput" >
            {shareUrl}
          </p>
          <button className="socialcopybtn" onClick={() => setCopied(true)}>
            {isCopied ? "Copied" : "Copy"}
          </button>
        </Row>
      </div>

      <Divider style={{ margin: "40px 0px" }}>or share with</Divider>

      <Row gutter={60} style={{ margin: "0px 15%" }}>
        <Col span={2}>
          <FacebookShareButton url={shareUrl} title={title}>
            <FacebookIcon size={38} round />
          </FacebookShareButton>
        </Col>

        <Col span={2}>
          <TwitterShareButton url={shareUrl} title={title}>
            <TwitterIcon size={36} round />
          </TwitterShareButton>
        </Col>

        <Col span={2}>
          <LinkedinShareButton url={shareUrl} title={title}>
            <LinkedinIcon size={36} round />
          </LinkedinShareButton>
        </Col>

        <Col span={2}>
          <WhatsappShareButton url={shareUrl} title={title}>
            <WhatsappIcon size={36} round />
          </WhatsappShareButton>
        </Col>
        <Col span={2}>
          <TelegramShareButton url={shareUrl} title={title}>
            <TelegramIcon size={36} round />
          </TelegramShareButton>
        </Col>
      </Row>
    </>
  );
}