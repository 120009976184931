import {
  Anchor,
  Col,
  Form,
  Input,
  Row,
  Typography
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FooterContent from "../../components/FooterContent";
import HeaderAuthTop from "../../components/HeaderAuthTop";
import { EndPoints } from "../../endpoint";
import image1 from "../../images/Image-2.png";
import verifyicon from "../../images/verify.png";
import { GetData, PostData } from "../../services";
import "../Profile/UserProfile.css";
import "../NotificationPage/NotificationPage.css";

import moment from 'moment';


const MyFormItemContext = React.createContext([]);

const { Title } = Typography;
const { Link } = Anchor;
const { TextArea } = Input;

const MyFormItemGroup = ({ prefix, children }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(
    () => [...prefixPath, ...toArr(prefix)],
    [prefixPath, prefix]
  );
  return (
    <MyFormItemContext.Provider value={concatPath}>
      {children}
    </MyFormItemContext.Provider>
  );
};

function toArr(str) {
  return Array.isArray(str) ? str : [str];
}
const MyFormItem = ({ name, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
  return <Form.Item name={concatName} {...props} />;
};

function FAQ() {
  const navigate = useNavigate()
  const [data, setData] = useState([])

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const res = await PostData(EndPoints?.NOTIFICATION_LIST)
      if (res?.status == 200 && res?.success_status == true) {
        setData(res?.data)
      }
      else if (res?.status == 200 && res?.success_status == true) {
        toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    }
    catch (err) {
      toast.error('Something went wrong')
    }
  }


  return (
    <>
      <HeaderAuthTop />
      <div className="Profilesection" >
        <div className="container profilewrap" style={{ width: "60%", }} >

          {data.map((el) => {
            return (
              <div className="notificationWrap" onClick={()=>{navigate('/joincontest',{state: { id: el.id,banner:el.banner_image } })}}>
                <div className="notificationList">
                  <div class="notificationMsg">
                    <h3>{el?.contest_name}</h3>
                    <p>A battle between {el?.rapper_name} & {el?.rapper_name2}</p></div>
                  <div className="notificationDate">{moment(el?.created_at).fromNow()}</div>
                </div>
              </div>
            )
          })}

        </div>
      </div>

      <FooterContent />
    </>
  );
}
export default FAQ;