import axios from "axios";
import { EndPoints } from "./endpoint";


export const PostData = (url, request,type) => {
    return new Promise(async (reslove, reject) => {
        try {
         let token =   await localStorage.getItem("access_token")
            let res = await axios.post(EndPoints.BASE_URL + url, request, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': type == '1'? 'multipart/form-data':'application/json',
                     'Authorization':'Bearer' + token
                    
                },
            })
                .then(async response => {
                    return response;
                })
            reslove(res.data)
        }
        catch (err) {
            reject(err)
            return err;
        }       
    })
}

export const GetData = (url, request, token) => {
    return new Promise(async (reslove, reject) => {
        try {
            let token = await localStorage.getItem('access_token');
           
            let res = await axios.get(EndPoints.BASE_URL + url, request, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    // 'Authorization': "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiNWYwYzI0NDQxZWY3YjEwZmZkNmE2MjczYzExM2U4M2U3ZThmNzIzNWVkZmFmMWViMWY3ZmUxM2YxYjg4ZDhlNDBjYzZiNzJlODJmNjAwMjEiLCJpYXQiOjE2OTg4NzM3ODguMjYxMTY4OTU2NzU2NTkxNzk2ODc1LCJuYmYiOjE2OTg4NzM3ODguMjYxMTcxMTAyNTIzODAzNzEwOTM3NSwiZXhwIjoxNzMwNDk2MTg4LjI2MDEyNDkyMTc5ODcwNjA1NDY4NzUsInN1YiI6IjYiLCJzY29wZXMiOltdfQ.PB4Zzeax4c1gN-U3KXsSYYq9buoERx9SUhhNSetuUrNIMhpqLAZv8FRV02y46R51kdemo_70MqE0LYZIj3bU48vXju5CIlkBdDG7f2J5tfXCJzBWpOkzcGj2d0IPV5aD54Ji99dSGbqzvOqv3Y15wcBbk7doiyj6rryohN-gW-1zeQR-BYiNpQnEfpgoP6LnpT2pBhwQBbhrSUYNoNzCLk0K4Z1sqbCFElu39-8ahTyvSebtDoNU1VwFRaIZXemipTxHBiTCLJtW8fTRahK-xdEP9vedG8i_7ehMWhWBf2RN7ZYiUsv3K4PSJ_Naep5A5Hf3Ziy3OyiIkU9CcmtD4K0ycFqJh_8DQZFUaymPvRGDKbZjxsUL36LXsHzVRLQ3aDm4LPfEIv8HPLEepzZPDhPfhf2Br7-IneVb4Fr2OB-tdFJEve8j_yIDAabzH8xRekK-lGod5SLvrKYEKKGXNhB_yu-qP6WMsEd0dYulyO2EpRFapuyfauR8vMa2On7kEoaHGnTpYnNkynWj_26xmwX18DobzGFEbYCh7K6Y7jh4afShtWB375rsTeo0XWJw8Cjg72Yj1oVzPYmcW61lDmhhQjdzXMPKe5mNxkbSOQwBogtS92DHjrOjTUhI88Bxu-bFAeLjcuSnFlfj69Eb5NWas7qz_vBq_19ttLez76E",
                },
            })
                .then(async response => {
                    return response;
                })
            reslove(res.data)
        }
        catch (err) {
            reject(err)
            return err;
        }
    })
}
export const GetUserData = async () => {
    let userData = JSON.parse(await localStorage.getItem('userData'))
    return userData;
}
export const PostUserData = async (data) => {
    let userData = await localStorage.setItem('userData', JSON.stringify(data))
    return userData;
}   
