import {
    Anchor,
    Col,
    Form,
    Input,
    Row,
    Typography
  } from "antd";
  import React, { useEffect, useState } from "react";
  import { useNavigate } from "react-router-dom";
  import { toast } from "react-toastify";
  import FooterContent from "../../components/FooterContent";
  import HeaderAuthTop from "../../components/HeaderAuthTop";
  import { EndPoints } from "../../endpoint";
  import image1 from "../../images/Image-2.png";
  import verifyicon from "../../images/verify.png";
  import { GetData } from "../../services";
  import "../Profile/UserProfile.css";
  import Accordion from "./Accordian";
  import time from "../../images/timedark.png";
  import date from "../../images/datedark.png"
  
  const MyFormItemContext = React.createContext([]);
  
  const { Title } = Typography;
  const { Link } = Anchor;
  const { TextArea } = Input;
  
  const MyFormItemGroup = ({ prefix, children }) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatPath = React.useMemo(
      () => [...prefixPath, ...toArr(prefix)],
      [prefixPath, prefix]
    );
    return (
      <MyFormItemContext.Provider value={concatPath}>
        {children}
      </MyFormItemContext.Provider>
    );
  };
  
  function toArr(str) {
    return Array.isArray(str) ? str : [str];
  }
  const MyFormItem = ({ name, ...props }) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatName =
      name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
    return <Form.Item name={concatName} {...props} />;
  };
  
  function SubscriptionPayment() {
    const navigate = useNavigate()
    const [data, setData] = useState([])
  
    // useEffect(() => {
    //   getData()
    // }, [])
  
    // const getData = async () => {
    //   try {
    //     const res = await GetData(EndPoints?.COUPON_LIST)
    //     if (res?.status == 200 && res?.success_status == true) {
    //       setData(res?.data)
    //     }
    //     else if (res?.status == 200 && res?.success_status == true) {
    //       toast.error(res?.message)
    //     }
    //     else {
    //       toast.error(res?.message)
    //     }
    //   }
    //   catch (err) {
    //     toast.error('Something went wrong')
    //   }
    // }
  
    return (
      <>
        <HeaderAuthTop />
        <div className="Profilesection" >
        <div className="container sub-paymentmainwrap">
        <Row gutter={28} >
      <Col className="gutter-row" span={12} >
        <div className="gutter-row paymentboxwrapper" >
                <div className="sub-paymentdiscriptionwrap">
                  <h2 className="sub-head">Rap Battle - Join the thrill of the battle</h2>  

                  <p className="sub-para">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, to Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu</p>
                  <Row>
              <p className="time" style={{color:"black"}}><img src={time} height={15}/>Time:- 10:23 onwards</p>
              <p className="time"><img  src={date} height={15}/>Date:- 01 Nov 2023</p>
             </Row>
                 </div>
        </div>
      </Col>
      <Col className="gutter-row" span={12}>
        <div className="gutter-row paymentboxwrapper">
                <div className="sub-paymentdiscriptionwrap">
                <p>Payment Support</p>
                <p>Account Support</p>
                </div>
        </div>
      </Col>
      </Row>
        
        </div>
        </div>
        {/* <div className="Profilesection" >
          <div className="container profilewrap" style={{background: "#fff" }} >
            <Form name="form_item_path" layout="vertical">
              <h2 className="contesthomeform" >SubscriptionPayment</h2>
              <div className="border" ></div>
      
            </Form>
          </div>
        </div> */}
  
        <FooterContent />
      </>
    );
  }
  
  export default SubscriptionPayment;