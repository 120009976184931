import React, { useState, useEffect } from "react";
import "../../pages/ContestPage/ContestPage.css";
import { Row, Col, Typography, Anchor, Tabs, Button } from "antd";
import Joincontestimg from "../../images/Google Ads.png";
import time from "../../images/clock.png"
import date from "../../images/date.png"
import { toast } from "react-toastify";
import { GetData, PostData } from "../../services";
import { EndPoints } from "../../endpoint";
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";


export default function JoinBattlemain(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [data, setData] = useState([])
  const [ad,setAd]=useState('')

  useEffect(() => {
    getBattleDetails()
  }, [])

  const getBattleDetails = async () => {
    try {
      const res = await GetData(EndPoints.CONTEXT_DETAILS + `${location?.state?.id}&user_id=${localStorage.getItem("user_id")}`)
      if (res?.status == 200 && res?.success_status == true) {

        setData(res.data[0])
      }
      else if (res?.status == 200 && res?.success_status == true) {
        // toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    }
    catch (err) {
      toast.error('Somrthing went wrong');
    }
  }

useEffect(()=>{
  getAdv()
},[])

const getAdv=async()=>{
  try {
    const res = await PostData(EndPoints.CUSTOM_BANNER + 'Contest')
    if (res?.status == 200 && res?.success_status == true) {
      setAd(res.data[0])
    
    }
    else if (res?.status == 200 && res?.success_status == false) {
      // toast.error(res.message)
    }
    else {
      //  toast.error(res.message)
    }
  }
  catch (err) {
    //  toast.error('Something went wrong')
  }
}



  return (
    <div className="container joinbattlesection">
       <div style={{ paddingBottom: 10 }}>
        <img src={data?.banner_image} alt="" className="" style={{ height: '270px', width: '100%', }} />
      </div>
      <Row xs={4} sm={2} md={2} lg={24} xl={24}>
        <Col span={18} push={0} className="leftwrap">
          <Row>
            <div className="leftwrapinner">
              <h3 className="heading" style={{ flex: 1 }}>{data?.contest_name}</h3>
              <p className="time"><img style={{ marginRight: "10px" }} src={time} />Time:- {data?.contest_time} onwards</p>
              <p className="time"><img style={{ marginRight: "10px" }} src={date} />Date:- {moment(data.contest_date).format('MMM DD, YYYY')}</p>
            </div>

          </Row>
          <div className="contestdescriptionwrap">
            <p className="">Organised by:- {data?.ContestCreatorName}</p>
            <p className="">Entry Fess:- ${data?.entry_fee}</p>
            <p className="">Winning Price:- ${data?.winner_prize}</p>
            <p className="">{data?.description}</p>
            <h4>Note:- You can join the contest only when you have a partner to join the contest</h4>
            {/* <div className="joinbtnwrapper">
              <a href="/joinbattlecontestentPage" className="joincontestbtn" >Join Now</a>
            </div> */}

            <div className="joinbtnwrapper">
              <a onClick={() => navigate('/joinbattlecontestentPage', { state: { data: data,banner:props?.banner } })} className="joincontestbtn" >Join Now</a>
            </div>
          </div>
        </Col>
        <Col xs={4} sm={2} md={2} lg={4} xl={4} className="rightwrap">
          <div><a href={ad.link} target="blank">
            <img src={ad.image} />
          </a>
          </div>
        </Col>
      </Row>
    </div>
  )
}
