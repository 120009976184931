import {
  Anchor,
  Col,
  Form,
  Input,
  Row,
  Typography
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FooterContent from "../../components/FooterContent";
import HeaderAuthTop from "../../components/HeaderAuthTop";
import { EndPoints } from "../../endpoint";
import image1 from "../../images/Image-2.png";
import verifyicon from "../../images/verify.png";
import { GetData } from "../../services";
import "../Profile/UserProfile.css";
import useClipboard from "react-use-clipboard";

const MyFormItemContext = React.createContext([]);

const { Title } = Typography;
const { Link } = Anchor;
const { TextArea } = Input;

const MyFormItemGroup = ({ prefix, children }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(
    () => [...prefixPath, ...toArr(prefix)],
    [prefixPath, prefix]
  );
  return (
    <MyFormItemContext.Provider value={concatPath}>
      {children}
    </MyFormItemContext.Provider>
  );
};

function toArr(str) {
  return Array.isArray(str) ? str : [str];
}
const MyFormItem = ({ name, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
  return <Form.Item name={concatName} {...props} />;
};

function CouponCodes() {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [code,setCode]=useState('')
  const [isCopied, setCopied] = useClipboard(code);

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const res = await GetData(EndPoints?.COUPON_LIST)
      if (res?.status == 200 && res?.success_status == true) {
        setData(res?.data)
      }
      else if (res?.status == 200 && res?.success_status == true) {
        toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    }
    catch (err) {
      toast.error('Something went wrong')
    }
  }


  useEffect(()=>{
if(code){
  setCopied(code)
}
  },[code])

  return (
    <>
      {/* <HeaderAuthTop /> */}
      <div className="Profilesection" >
        <div className="container profilewrap" style={{ width: "92%", background: "#fff" }} >
          <Form name="form_item_path" layout="vertical">
            <h2 className="contesthomeform">Coupon Codes</h2>
            <div className="border"></div>

            {data?.map((el,index) => {
              const status = el.name === code ? "Copied" : el?.name;
              return (
                <div className="container couponrowrap">
                  <Row>
                    <Col span={3} className="couponimage">
                      <img src={el.image} height={85} width={80} />
                    </Col>
                    <Col xs={12} sm={11} md={11} lg={12} xl={16} className="coupondescription">
                      <h2 className="couponhead">UPTO {el?.discount}% OFF</h2>
                      <p className="coupondescription">{el?.description}</p>
                      <div className="verifywraper">
                        <Row><img src={verifyicon} /> <span>Verified</span></Row></div></Col>
                    <Col xs={5} sm={5} md={5} lg={6} xl={4}>
                      <h4 onClick={()=>{setCode(el?.name);setCopied(true)}} className="showcodewrap">{status}</h4>
                    </Col>
                  </Row>
                </div>
              )
            })}


          </Form>
        </div>
      </div>

      {/* <FooterContent /> */}
    </>
  );
}

export default CouponCodes;