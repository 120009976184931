import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 777 620",
    }}
    viewBox="0 0 777 620"
    {...props}
  >
    <path d="M388.2 606.6h-229c-31.5 0-60.6-7.8-86.6-26.1-29.7-20.9-49.1-49-57.1-84.5-2.7-11.9-4.2-24.4-4.2-36.6-.3-101-.2-202-.2-303 .1-69 48-129 115.4-143.9 10.8-2.4 22.1-3.1 33.2-3.2 152.3-.2 304.6-.1 456.9-.2 37.2 0 70.7 10.7 98.9 35.3 33.2 29 49.3 66.2 49.4 109.9.3 102.3.2 204.6 0 307-.1 34.3-10.3 65.4-32.2 92-23.6 28.7-53.9 46.2-90.9 51.2-10.2 1.4-20.6 1.8-30.9 1.9-74.1.2-148.4.2-222.7.2zm-319-342.1v4.4c0 65.7 0 131.3.1 197 0 5.6.5 11.4 1.6 16.9 7.5 39 44.3 71.8 88.4 71.6 152.6-.5 305.3-.2 457.9-.2 4.7 0 9.4.1 14-.6 43.6-6.5 76.6-45.2 76.3-89.1-.5-64.8-.1-129.6-.1-194.5v-5.5H69.2zm638-58.6c0-12.6.2-24.9-.1-37.2-.2-10 .1-20.1-1.6-29.9-7.3-39.8-44.7-71.9-87.7-71.8-153.1.4-306.2.1-459.4.3-8.5 0-17.3 1.3-25.6 3.5-32.5 8.6-59.5 39.5-62.7 73-1.7 17.3-.8 34.9-1.1 52.4v9.6c213 .1 425.4.1 638.2.1z" />
    <path d="M385.5 397.8c13 0 26-.2 39 .1 12.6.3 22.2 7.4 25.8 18.5 4.1 12.7.9 26.3-9.1 33.3-4.4 3.1-10.3 5.5-15.6 5.6-26.3.6-52.6.5-78.9.2-12.3-.1-25.2-11.7-27.4-23.4-2.5-13.7 3.5-26.9 15.2-31.6 4.5-1.8 9.7-2.5 14.6-2.6 12.1-.4 24.3-.1 36.4-.1zM225.5 425.5c.9 17-10.1 29.3-26.9 30.1-17.9.8-29.8-10-30.7-27.9-.8-16.5 10.4-29.1 26.8-29.8 18.2-.9 29.9 9.6 30.8 27.6z" />
  </svg>
)
export default SvgComponent