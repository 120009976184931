import {
  CheckCircleFilled, EllipsisOutlined, LikeOutlined, SendOutlined, LikeFilled,
  ExportOutlined, DoubleRightOutlined
} from "@ant-design/icons";
import {
  Anchor,
  Button,
  Col,
  Dropdown,
  Input,
  List,
  Menu,
  Row, Form,
  Tabs, Modal, message, Upload,
  Typography,Spin
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FooterContent from "../../components/FooterContent";
import HeaderAuthTop from "../../components/HeaderAuthTop";
import { EndPoints } from "../../endpoint";
import bannerad1 from "../../images/adbanner1.png";
import bannerad2 from "../../images/adbanner2.png";
import commentImg from "../../images/comment.png";
import dislike from "../../images/dislike.png";
import likeImg from "../../images/like.png";
import proImg from '../../images/pro.png';
import send from "../../images/send.png";
import shareImg from "../../images/share.png";
import postavtar from '../../images/postavtar.png';
import ListIcon from '../../components/ListIcon';

import { GetData, PostData } from "../../services";
import "./CommunityCourse.css";
import Validator from "../../Validator";
import Socialshare from "../ContestPage/SocialSharePage";
import ReactPlayer from 'react-player'
import { AuthContext } from "../../App";
import SignUp from "../../components/SignUp";
import LogIn from "../../components/LogIn";


const props = {
  name: 'file',
  action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
const handleTabChange = (key) => {
};
const { TabPane } = Tabs;
const { Title } = Typography;
const { Link } = Anchor;

function CommunityPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [communityList, setCommunityList] = useState([]);
  const [communityDesc, setCommunityListDesc] = useState(location?.state?.data);
  const [step, setStep] = useState(0)
  const [featureList, setFeatureList] = useState([])
  const [comment, setComment] = useState('')
  const [subs, setSubs] = useState(false)

  const [postName, setPostName] = useState('');
  const [postDesc, setPostDesc] = useState('')
  const [postUpload, setPostUpload] = useState('')
  const [postLink, setPostLink] = useState('')
  const [postVideo, setPostVideo] = useState('')
  const [editData, setEditData] = useState({})
  const [baseUrl, setBaseUrl] = useState('')
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  const [shareUrl, setShareUrl] = useState('')
  const [videoId, setVideoId] = useState('')
  const [isAuth, setIsAuth] = useContext(AuthContext)

  const [isModalLoginOpen, setIsModalLoginOpen] = useState(false);
  const [isModasignuplOpen, setIsModalSignupOpen] = useState(false);
  const [isModalCommentOpen, setIsModalCommentOpen] = useState(false);
  const [addData, setAddData] = useState([])

  const [pageNumber, setPageNumber] = useState(1)
  const [total, setTotal] = useState('')
  const [pId, setPId] = useState('')
  const [commentList, setCommentList] = useState([])
  const [videoLoader,setVideoLoader]=useState(false)


  const handleloginOk = () => {
    setIsModalLoginOpen(false);
  };
  const handleloginCancel = () => {
    setIsModalLoginOpen(false);
  };

  const handleOksignup = () => {
    setIsModalSignupOpen(false);
  };
  const handleCancelsignup = () => {
    setIsModalSignupOpen(false);
  };

  const handleShareModalOk = () => {
    setIsShareModalOpen(false);
  };
  const handleShareModalCancel = () => {
    setIsShareModalOpen(false);
  };

  const handleCommentOk = () => {
    getCommunityList(step)
    setIsModalCommentOpen(false)
  }

  const handleCommentCancel = () => {
    getCommunityList(step)
    setIsModalCommentOpen(false)
  }

  useEffect(() => {
    getAdvertisement()
  }, [])

  const getAdvertisement = async () => {
    try {
      let res = await PostData(EndPoints.CUSTOM_BANNER + 'Post')
      if (res?.status == 200 && res?.success_status == true) {
        setAddData(res.data)
      }
      else if (res?.status == 200 && res?.success_status == true) {
        toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    }
    catch (err) {

    }
  }

  useEffect(() => {
    if (localStorage.getItem("access_token")) {

      if (location?.state?.data) {
        setStep(location?.state?.step)
        getCommunityList(location?.state?.step)
      }
      else {
        var url = window?.location?.href;
        var regex = /step=([^&]+)&videoId=([^&]+)/
        var match = url?.match(regex);
        if (match) {
          setStep(match[1])
          getCommunityList(match[1])
          setVideoId(match[2])
        }
      }
    }
    else {
      setIsModalLoginOpen(true)
    }

  }, [isAuth])


  const getCommunityList = async (val) => {
    try {
      const res = await GetData(EndPoints.COMMUNITY_LIST + localStorage.getItem("user_id"))
      if (res?.status == 200 && res?.success_status == true) {
        const filteredCourselist = await res?.data[val]?.courselist?.filter(course => course?.featured == 1);
        setBaseUrl(res?.baseurl)
        setFeatureList(filteredCourselist)
        setCommunityList(res?.data)
        setCommunityListDesc(res?.data[val])
        if (res?.subscription?.expire_date && (moment().format('YYYY-MM-DD') <= res?.subscription?.expire_date)) {
          setSubs(true)
        }
        else {
          setSubs(false)
        }
        // if ((res?.subscription?.expire_date == null) || (res?.subscription == null)) {
        //           setSubs(false)
        //         }
        //         else if ((moment().format('MMM DD, YYYY') < moment(res?.subscription?.expire_date).format('MMM DD, YYYY'))) {
        //           setSubs(true)
        //         }
        //         else {
        //           alert('hiiiiiiiiiiiiiii')
        //           setSubs(false)
        //         }
      }
      else if (res?.status == 200 && res?.success_status == false) {
        //  toast.error(res?.message)
      }
      else {
        //  toast.error(res?.message)
      }
    }
    catch (err) {
      //  toast.error('Something went wrong');
    }
  }


  const handleLike = async (id) => {
    try {
      let data = {
        "user_id": localStorage.getItem("user_id"),
        "post_id": id
      }
      const res = await PostData(EndPoints?.POST_LIKE_DISLIKE, data)

      if (res.status == 200 && res?.success_status == true) {
        getCommunityList(step)
        //   toast.success(res.message)
      }
      else if (res.status == 200 && res?.success_status == false) {
        //    toast.error(res.message)
      }
      else {
        //    toast.error(res.message)
      }
    }
    catch (err) {
      //  toast.error('Something went wrong')
    }
  }

  const handleComment = async (id) => {
    try {
      let data = {
        "post_id": id,
        "user_id": localStorage.getItem("user_id"),
        "comments": comment
      }
      console.log(data, 'datatatattaatatattatattata comment')
      const res = await PostData(EndPoints?.POST_COMMENT, data)
      if (res.status == 200 && res?.success_status == true) {
        if (pId) {
          getPostByCommentList(pId, 1)
          setPageNumber(1)
        }
        getCommunityList(step)
        setComment('')
        toast.success(res.message)
      }
      else if (res.status == 200 && res?.success_status == false) {
        toast.error(res.message)
      }
      else {
        toast.error(res.message)
      }
    }
    catch (err) {
      toast.error('Something went wrong')
    }
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    if (subs) {
      setIsModalOpen(true);
    }
    else {
      navigate('/subscription', { state: { data: '0' } })
    }
  };
  const handleOk = async () => {
    try {
      // if (Validator.isEmpty(postName)) {
      //   toast.error('Please enter your post title');
      // }
      // else 
      if (Validator.isEmpty(postDesc)) {
        toast.error('Please enter your post description');
      }
      else {
        if (editData?.id) {
          const formData = new FormData();
          formData.append("user_id", localStorage.getItem("user_id"))
          // formData.append("logo", localStorage.getItem("proImg") ? localStorage.getItem("proImg") : proImg)
          formData.append("description", postDesc)
          formData.append("community_id", communityDesc.id)
          // formData.append("title", postName)
          formData.append("image", postUpload)
          formData.append("video", postVideo)
          formData.append("link", postLink)
          formData.append("post_id", editData?.id)
          setVideoLoader(true)
          let res = await PostData(EndPoints?.EDIT_POST, formData, '1')
          if (res.status == 200 && res.success_status == true) {
            setPostDesc('')
            setPostName('')
            setPostUpload('')
            setPostVideo('')
            setPostLink('')
            getCommunityList(step)
            toast.success(res?.message);
            setIsModalOpen(false)
            setEditData({})
            setVideoLoader(false)
          }
          else if (res.status == 200 && res.success_status == false) {
            setVideoLoader(false)
            toast.error(res?.message);
          }
          else {
            setVideoLoader(false)
            toast.error(res?.message);
          }
        }
        else {
          const formData = new FormData();
          formData.append("user_id", localStorage.getItem("user_id"))
          // formData.append("logo", localStorage.getItem("proImg") ? localStorage.getItem("proImg") : proImg)
          formData.append("description", postDesc)
          formData.append("community_id", communityDesc.id)
          // formData.append("title", postName)
          formData.append("image", postUpload)
          formData.append("video", postVideo)
          formData.append("link", postLink)
          setVideoLoader(true)
          let res = await PostData(EndPoints?.ADD_POST, formData, '1')
          if (res.status == 200 && res.success_status == true) {
            setPostDesc('')
            setPostName('')
            setPostUpload('')
            setPostVideo('')
            setPostLink('')

            getCommunityList(step)
            toast.success(res?.message);
            setIsModalOpen(false)
            setVideoLoader(false)
          }
          else if (res.status == 200 && res.success_status == false) {
            setVideoLoader(false)
            toast.error(res?.message);
          }
          else {
            setVideoLoader(false)
            toast.error(res?.message);
          }
        }
      }
    }
    catch (err) {
      setVideoLoader(false)
      toast.error("Something went wrong");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditData({})
    setPostName('')
    setPostDesc('')
    setPostLink('')
    setPostUpload('')
    setPostVideo('')
  };
  const [form] = Form.useForm();


  const handleDelete = async (id) => {
    try {
      const formData = new FormData();
      formData.append("user_id", localStorage.getItem("user_id"))
      formData.append("post_id", id)

      let res = await PostData(EndPoints?.DELETE_POST, formData, '1')
      if (res.status == 200 && res.success_status == true) {
        getCommunityList(step)
        toast.success(res?.message);
      }
      else if (res.status == 200 && res.success_status == false) {
        toast.error(res?.message);
      }
      else {
        toast.error(res?.message);
      }
    }
    catch (err) {
      toast.error('Something went wrong');
    }
  }

  const handleReport = async (id) => {
    try {
      const formData = new FormData();
      formData.append("user_id", localStorage.getItem("user_id"))
      formData.append("post_id", id)
      let res = await PostData(EndPoints?.REPORT_POST, formData, '1')
      if (res.status == 200 && res.success_status == true) {
        getCommunityList(step)
        toast.success(res?.message);
      }
      else if (res.status == 200 && res.success_status == false) {
        toast.error(res?.message);
      }
      else {
        toast.error(res?.message);
      }
    }
    catch (err) {
      toast.error('Something went wrong');
    }
  }

  const handleEdit = (item) => {
    setEditData(item)
    setPostName(item.title)
    setPostDesc(item.description)
    setPostLink(item.link)
    setPostUpload(item.image)
    setPostVideo(item.video)
    setIsModalOpen(true)
  }

  const handleShare = (item) => {
    setIsShareModalOpen(true)
    setShareUrl(`${baseUrl}/CommunityCourse?step=${step}&videoId=${item?.id}`)
    // setShareUrl(`http://localhost:3000/CommunityCourse?step=${step}&videoId=${item?.id}`)
  }

  const handleCommunityDetails = (item, index) => {
    if (!subs) {
      if (index == 0) {
        navigate('/CommunityCourseDetails', { state: { id: item.id, step: step } })
      }
      else {
        navigate('/subscription', { state: { data: '0' } })
      }
    }
    else {
      navigate('/CommunityCourseDetails', { state: { id: item.id, step: step } })
    }
  }

  const handleFile = (type, file) => {
    if (type.includes("mp4")) {
      setPostVideo(file)
    } else {
      setPostUpload(file)
    }
  }

  const getPostByCommentList = async (postId, page) => {
    try {
      let res = await PostData(EndPoints?.POST_COMMENT_List + `${postId}&page=${page}`)
      if (res.status == 200 && res.success_status == true) {

        let tempArr = []
        if (page == 1) {
          tempArr = res?.data
        } else {
          tempArr = [...commentList, ...res?.data];
        }
        setCommentList(tempArr);

        setTotal(res?.meta?.total_pages)
        setIsModalCommentOpen(true)
      }
      else if (res.status == 200 && res.success_status == false) {
        setPId('')
      }
      else {

      }
    }
    catch (err) {

    }
  }

  const handleCommentPagination = () => {
    if (pageNumber <= total) {
      setPageNumber(pageNumber + 1)
      getPostByCommentList(pId, pageNumber + 1)
    }
  }

  console.log(pageNumber, 'pageNumber', total, 'total9999999999')

  return (
    <>
      <HeaderAuthTop />
      <div className="Createcontestsection">
        <div className="container">
          <Row>
            <Col xl={5} md={5} lg={5} xs={5}>
              <div className="communityLeftLink">
                <List
                  size="small"
                  bordered
                  dataSource={communityList}
                  renderItem={(item, index) => {
                    const backgroundColor = index === step ? true : false
                    return (

                      <List.Item className={backgroundColor ? 'withActiveBG' : 'withoutActiveBG'} onClick={() => { setStep(index); getCommunityList(index); }}>
                        <Link><ListIcon />{item.community_name}</Link>
                      </List.Item>

                    )
                  }}

                />
              </div>
            </Col>
            <Col xl={19} md={19} lg={19} xs={19}>
              <div className="container">
                <div className="courseCommunity">
                  <Row gutter={35}>
                    <Col xl={5} md={5} lg={5} xs={5}>
                      <div className="CourseImg">
                        <img src={communityDesc?.image} alt={Image} />
                      </div>
                    </Col>
                    <Col xl={19} md={19} lg={19} xs={19}>
                      <div className="courseDetails">
                        <Title level={2}>{communityDesc?.community_name}</Title>
                        <p className="courseDescription textClamp" dangerouslySetInnerHTML={{ __html: communityDesc?.about }}></p>
                        <div className="courseRatings">
                          <div></div>
                          {/* <div className="ratings">
                            <span className="ratingStar">
                              <StarOutlined /> 4.7 out of 5 4.7
                            </span>
                            <span className="ratingCount">
                              (188,946 ratings)
                            </span>
                            <span className="rapperCount">834,237 Rapper </span>
                          </div> */}
                          {!subs &&
                            <div className="subscribeBtnWrap">
                              <Button className="subscribeBtn" onClick={() => navigate('/subscription', { state: { data: '0' } })}>Subscribe</Button>
                            </div>}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="courseCommunity courseTrendingList">
                  <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                    <TabPane tab="Course" key="1">
                      <div className="trendingCourseWrap">
                        <Title level={3}>Trending courses</Title>
                        <div className="trendingCourse">
                          <Row gutter={20}>
                            {communityDesc?.courselist?.map((item, index) => {
                              return (
                                <Col
                                  xl={6}
                                  md={6}
                                  lg={6}
                                  xs={24}
                                  key={item.id}
                                  title={item.name}
                                >
                                  <div className="trendingCourseBox" onClick={() => { handleCommunityDetails(item, index) }}>
                                    <div className="courseImg">
                                      <img
                                        src={item.image}
                                        alt=""
                                        className=""
                                      />
                                    </div>
                                    <Title level={4} defaultValue={'aaa'}>{item.name}</Title>
                                    <div className="courseDuration">
                                      <strong>Duration :</strong>{" "}
                                      <span className="courseTimeDuration">
                                        {item?.totalhour} <ExportOutlined />
                                      </span>
                                    </div>
                                  </div>
                                </Col>
                              )
                            })}
                          </Row>
                        </div>
                      </div>

                      {featureList?.length != 0 &&
                        <div className="trendingCourseWrap">

                          <Title level={3}>Featured courses </Title>
                          <div className="trendingCourse">
                            <Row gutter={20}>
                              {featureList?.map((item) => (
                                <Col xl={6} md={6} lg={6} xs={24} key={item.id}>
                                  <div className="trendingCourseBox" onClick={() => navigate('/CommunityCourseDetails', { state: { id: item.id, step: step } })}>
                                    <div className="courseImg">
                                      <img
                                        src={item.image}
                                        alt=""
                                        className=""
                                      />
                                    </div>
                                    <Title level={4}>{item.name}</Title>
                                    <div className="courseDuration">
                                      <strong>Duration :</strong>{" "}
                                      <span className="courseTimeDuration">
                                        {item?.totalhour} <ExportOutlined />
                                      </span>
                                    </div>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </div>
                        </div>}

                    </TabPane>

                    <TabPane tab="Posts" key="2">
                      <Row gutter={25}>
                        <Col xl={16} md={16} lg={16} sm={16} xs={16}>
                          <div className="communityPostWrap">
                            <div className="createPostWrap">
                              <div className="createPostAvtar">
                                {/* <img src={postavtar} alt="" /> */}
                                {localStorage.getItem("proImg") ?
                                  <img src={localStorage.getItem("proImg")} alt="" style={{ height: 50, width: 50, borderRadius: 60 }} /> :
                                  <img src={proImg} alt="" style={{ height: 50, width: 50, borderRadius: 60 }} />
                                }

                              </div>
                              <div className="createPostInput">
                                <Input placeholder="Write something here..." className="searchPost" onClick={showModal} />
                                <Modal title="New Post" open={isModalOpen}
                                  okText={videoLoader?<Spin />:"Save Post"} onOk={handleOk} onCancel={handleCancel} className="modalPost">
                                  <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">

                                    {/* <div style={{ marginBottom: 15 }}>
                                      <label style={{ marginBottom: "30px" }}>Title</label>
                                      <input
                                        placeholder="Olivia Rhye"
                                        size="large"
                                        className="Inputcolor"
                                        value={postName}
                                        onChange={(e) => setPostName(e.target.value)}
                                      />
                                    </div> */}

                                    <div style={{ marginBottom: 15 }}>
                                      <label style={{ marginBottom: "30px" }}>Description</label>
                                      <input
                                        placeholder="Post Description"
                                        size="large"
                                        className="Inputcolor"
                                        value={postDesc}
                                        onChange={(e) => setPostDesc(e.target.value)}
                                      />
                                    </div>

                                    <div style={{ marginBottom: 15 }}>
                                      <label style={{ marginBottom: "30px" }}>Link</label>
                                      <input
                                        placeholder="Post link"
                                        size="large"
                                        className="Inputcolor"
                                        value={postLink}
                                        onChange={(e) => setPostLink(e.target.value)}
                                      />
                                    </div>


                                    {/* <Form.Item name="title" label="Title" rules={[{ required: false, },]}>
                                      <Input placeholder="Olivia Rhye" defaultValue={postName} onChange={(e) => setPostName(e.target.value)} />
                                    </Form.Item>

                                    <Form.Item name="name" label="Description (optional)" rules={[{ required: false, },]}>
                                      <Input.TextArea value={postDesc} defaultValue={postDesc} onChange={(e) => setPostDesc(e.target.value)} placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry." />
                                    </Form.Item>
                                    <Form.Item name="link" label="Link" rules={[{ required: false, },]}>
                                      <Input placeholder="Olivia Rhye" defaultValue={postLink} value={postLink} onChange={(e) => setPostLink(e.target.value)} />
                                    </Form.Item> */}


                                    <Form.Item name="Image" label="File">
                                      <Input className='fieldControl' fullWidth size="large"
                                        required="required"
                                        onChange={(e) => handleFile(e?.target.value, e?.target?.files[0])}
                                        accept="image/*,video/mp4"
                                        value={postUpload?.image}
                                        id="outlined-basic"
                                        variant="outlined"
                                        type="file"
                                        inputProps={{
                                          multiple: false
                                        }}
                                      />
                                    </Form.Item>

                                    {/* <Form.Item name="Image" label="Image">
                                      <Input className='fieldControl' fullWidth size="large"
                                        required="required"
                                        onChange={(e) => setPostUpload(e?.target?.files[0])}
                                        accept="image/*"
                                        value={postUpload?.image}
                                        id="outlined-basic"
                                        variant="outlined"
                                        type="file"
                                        inputProps={{
                                          multiple: false
                                        }}
                                      />
                                    </Form.Item> */}

                                    {/* <Form.Item name="Video" label="Video">
                                      <Input className='fieldControl' fullWidth size="large"
                                        required="required"
                                        onChange={(e) => setPostVideo(e?.target?.files[0])}
                                        accept="video/mp4"
                                        value={postVideo?.image}
                                        id="outlined-basic"
                                        variant="outlined"
                                        type="file"
                                        inputProps={{
                                          multiple: false
                                        }}
                                      />
                                    </Form.Item> */}

                                  </Form>
                                </Modal>
                              </div>
                            </div>

                            {communityDesc?.postlist?.map((item) => {

                              return (
                                <div className="communityPostBox" key={item.id}>
                                  <div className="communityPostHeading">
                                    <div className="communityPostInner">
                                      <div className="communityPostUserImg">
                                        <img src={item?.logo ? item?.logo : proImg} alt="" />
                                      </div>
                                      <div className="communityPostName">
                                        <div className="">
                                          <Title level={5}>
                                            {item.title}{" "}
                                            <span className="CommunityuserVerified">
                                              <CheckCircleFilled />
                                            </span>
                                          </Title>
                                          <Typography className="CommunitySmallText">
                                            {moment(item?.created_at).format('MMM DD, YYYY')}
                                            <span className="spaceBoth">.</span>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="12"
                                              height="13"
                                              viewBox="0 0 12 13"
                                              fill="none"
                                            >
                                              <g clip-path="url(#clip0_522_5991)">
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M11.623 6.5003C11.623 8.9228 10.093 10.9876 7.9458 11.7773L7.9983 11.7503C8.87355 11.0003 9.3588 9.52504 9.4983 8.87479C9.6588 8.12479 9.72555 7.89979 9.1233 7.40479C8.8908 7.21504 8.61555 7.25781 8.3433 7.04781C8.1588 6.90531 8.0763 6.6053 7.8888 6.5003C7.4433 6.24755 7.05855 6.58279 6.4233 6.66754C6.13251 6.70027 5.83808 6.66879 5.5608 6.5753C5.55555 6.5753 5.55555 6.5753 5.55105 6.5723L5.4888 6.5498C5.3658 6.4883 5.43105 6.39529 5.4933 6.27979H5.4888C5.5533 6.15229 5.5533 5.9978 5.4513 5.88305C5.3088 5.72255 5.0463 5.72255 4.8483 5.71505C4.7688 5.7128 4.6908 5.71505 4.61355 5.71805L4.6113 5.71505C4.5813 5.71505 4.5483 5.71805 4.5183 5.71805H4.49055C4.2483 5.7233 3.99105 5.69255 3.8208 5.48255C3.62355 5.2178 3.6033 4.9328 3.89355 4.66505C4.10355 4.47005 4.5408 4.06505 5.2488 4.50005C5.70105 4.77755 6.1233 5.0003 6.4983 4.87505C6.7458 4.79255 6.8583 4.64781 6.5688 4.22256C6.45352 4.06415 6.39679 3.87063 6.4083 3.67505C6.45855 3.09305 6.92355 3.04504 7.2813 2.77504C7.6308 2.50804 7.7913 2.06779 7.6908 1.68229C7.6113 1.37254 7.3083 1.02755 6.7308 0.924805C8.08461 1.10088 9.32797 1.76394 10.2285 2.79005C11.129 3.81617 11.625 5.13508 11.6238 6.5003"
                                                  fill="#65676B"
                                                />
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M7.58026 11.8983C6.74183 12.1441 5.85768 12.1911 4.99793 12.0356C4.13817 11.8801 3.32646 11.5265 2.62718 11.0027C1.9279 10.4789 1.36028 9.79942 0.969347 9.01807C0.578415 8.23671 0.374921 7.375 0.375004 6.5013C0.373858 5.56501 0.607075 4.6433 1.05339 3.82023C1.49971 2.99715 2.14493 2.29889 2.93025 1.78906C2.36918 2.25239 1.94132 2.8565 1.6905 3.53955C1.12575 5.2023 1.52775 6.59581 3.408 6.59581C4.03275 6.59581 4.458 7.01656 4.40775 8.37706C4.398 8.67931 4.79776 9.71205 6.24526 9.79455C6.77026 9.82455 7.13325 10.5843 7.24275 11.0996C7.28775 11.3171 7.33801 11.5871 7.43551 11.8271C7.44803 11.8536 7.46913 11.8751 7.49539 11.8882C7.52165 11.9012 7.55155 11.9051 7.58026 11.899"
                                                  fill="#65676B"
                                                />
                                                <path
                                                  d="M12 6.5C12 8.0913 11.3679 9.61743 10.2426 10.7426C9.11742 11.8679 7.5913 12.5 6 12.5C4.4087 12.5 2.88258 11.8679 1.75736 10.7426C0.632142 9.61743 0 8.0913 0 6.5C0 4.9087 0.632142 3.38257 1.75736 2.25735C2.88258 1.13214 4.4087 0.5 6 0.5C7.5913 0.5 9.11742 1.13214 10.2426 2.25735C11.3679 3.38257 12 4.9087 12 6.5ZM11.25 6.5C11.25 5.10761 10.6969 3.77226 9.71231 2.7877C8.72774 1.80313 7.39239 1.25 6 1.25C4.60761 1.25 3.27226 1.80313 2.28769 2.7877C1.30313 3.77226 0.75 5.10761 0.75 6.5C0.75 7.89239 1.30313 9.22774 2.28769 10.2123C3.27226 11.1969 4.60761 11.75 6 11.75C7.39239 11.75 8.72774 11.1969 9.71231 10.2123C10.6969 9.22774 11.25 7.89239 11.25 6.5Z"
                                                  fill="#65676B"
                                                />
                                              </g>
                                              <defs>
                                                <clipPath id="clip0_522_5991">
                                                  <rect
                                                    width="12"
                                                    height="12"
                                                    fill="white"
                                                    transform="translate(0 0.5)"
                                                  />
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </Typography>
                                        </div>
                                        <div className="postRelatedAction">
                                          {item?.user_id == localStorage.getItem("user_id") ?
                                            <Dropdown overlay={
                                              <Menu>
                                                <Menu.Item key="1" onClick={() => { subs ? handleEdit(item) : navigate('/subscription', { state: { data: '0' } }) }}>Edit</Menu.Item>
                                                <Menu.Item key="2" onClick={() => { subs ? handleDelete(item?.id) : navigate('/subscription', { state: { data: '0' } }) }}>Delete</Menu.Item>
                                                {/* <Menu.Item key="3">Report</Menu.Item> */}
                                              </Menu>}>
                                              <Button >
                                                <EllipsisOutlined className="threeDot" />
                                              </Button>
                                            </Dropdown> :
                                            <Dropdown overlay={<Menu>
                                              <Menu.Item key="3" onClick={() => { subs ? handleReport(item?.id) : navigate('/subscription', { state: { data: '0' } }) }}>Report</Menu.Item>
                                            </Menu>}>
                                              <Button>
                                                <EllipsisOutlined className="threeDot" />
                                              </Button>
                                            </Dropdown>}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="communityPostDescription">
                                      {item.description}
                                    </div>
                                    {item.link &&
                                      <a href={item.link}>{item.link}</a>
                                    }
                                  </div>
                                  <div className="communityPostImg">
                                    {item.image ?
                                      <img src={item.image} alt="" /> :
                                      item.video ?
                                        <>
                                          <video width="100%" controls>
                                            <source //src="https://fantasy.largetech.in/images/post/1702997662.mp4"
                                              src={item.video}
                                              type="video/mp4" style={{ background: 'red', height: 300, width: '100%' }} />
                                          </video>

                                          {/*  <ReactPlayer url={item.video} width='100%'
                                            height='100%' controls />*/}
                                        </> : ''

                                    }
                                  </div>

                                  <div className="likeCommentBox">
                                    <div className="likeCountWrap">
                                      <span className=""><LikeOutlined /></span>
                                      <span className="likeCount">{item?.favcount}</span>
                                    </div>
                                    {item?.total_comments != 0 &&
                                      <div className="commentCountWrap" onClick={() => { setPId(item?.id); getPostByCommentList(item?.id, 1) }}>
                                        <span className="commentCount">{item?.total_comments} Comments</span>
                                      </div>}

                                  </div>

                                  <div className="communityActivity">

                                    <div className="" onClick={() => { handleLike(item?.id) }}>{item?.favstatus ? <LikeFilled style={{ color: '#03a1fc' }} /> : <LikeOutlined />} <span>{item?.favcount}</span></div>

                                    <div className="commentImg"><img src={commentImg} /><span>Comment</span></div>
                                    <div className="shareImg" onClick={() => { subs ? handleShare(item) : navigate('/subscription', { state: { data: '0' } }) }}><img src={shareImg} /><span>Share</span></div>
                                  </div>

                                  <div className="commentAreaWrap">
                                    {item.comments.map((el) => {
                                      return (
                                        <div className="commentBox">
                                          <div className="commentUserImg"> <img src={el?.profile ? el?.profile : localStorage.getItem("proImg")} alt="" /></div>
                                          <div className="commentTextWrap">
                                            <div className="commentTextArea">
                                              <span className="commmentName">{el?.username}</span>
                                              <span className="commmentData">{el?.comments}</span>
                                            </div>
                                            <div className="commentDate">
                                              <span>{moment(el?.created_at).fromNow()}</span>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>

                                  <div className="communityDoComment">
                                    {localStorage.getItem("proImg") ?
                                      <div className="commentUserImg"> <img src={localStorage.getItem("proImg")} alt="" /></div> :
                                      <div className="commentUserImg"> <img src={proImg} alt="" /></div>
                                    }

                                    <div className="commentUserInput"><Input value={comment} onChange={(el) => { setComment(el.target.value) }} placeholder="Write a comment..." /></div>
                                    <div className="commentUserImg" onClick={() => { subs ? handleComment(item?.id) : navigate('/subscription', { state: { data: '0' } }) }}><SendOutlined className="sendIcon" /></div>
                                  </div>
                                </div>
                              )
                            })}

                            {communityDesc?.postlist.length == 0 &&
                              <p style={{ textAlign: "center", fontSize: "15px", color: "red" }}>There is no post avilable.</p>
                            }

                          </div>
                        </Col>
                        <Col xl={8} md={8} lg={8} sm={8} xs={8}>
                          <div className="communityAdBanner">
                            {addData.map((item) => {
                              return (
                                <a href={item?.link}><img src={item?.image} className="mb-30" /></a>
                              )
                            })}
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <FooterContent />
      <Modal title="Share Post" className="inviteusermodal" open={isShareModalOpen} centered onOk={handleShareModalOk} onCancel={handleShareModalCancel} footer={null}>
        <Socialshare shareData={shareUrl} />
        {/* </div> */}
      </Modal>
      <Modal title="" className="loginModal" open={isModalLoginOpen} onOk={handleloginOk} onCancel={handleloginCancel} footer={null}>
        <LogIn />
        <div className='dontAccountWrap'>
          <span className='dontText'>Don’t have an account?</span>  <div onClick={() => { setIsModalSignupOpen(true); setIsModalLoginOpen(false) }} className="notSignLink">Sign up</div>
        </div>
      </Modal>
      <Modal title="" className="loginModal" open={isModasignuplOpen} onOk={handleOksignup} onCancel={handleCancelsignup} footer={null}>
        <SignUp />
        <div className='dontAccountWrap'>
          <span className='dontText'>Already have an account?</span>  <div onClick={() => { setIsModalSignupOpen(false); setIsModalLoginOpen(true) }} className="notSignLink">Log In</div>
        </div>
        <br />
        <div>
          <p className='dontText' style={{ textAlign: "center" }}>If you have any query or face any issue please connect us on<br />
            1800-2342-2342 Or mail Us support@fanstasy.com</p>
        </div>
      </Modal>

      <Modal title="" className="loginModal" open={isModalCommentOpen} onOk={handleCommentOk} onCancel={handleCommentCancel} footer={null}>
        <div className="commentAreaWrap">
         
          {commentList.map((el) => {
            return (
              <div className="commentModalBox" >
                <div className="commentUserImg"> <img src={el?.image ? el?.image : localStorage.getItem("proImg")} alt="" /></div>
                <div className="commentTextWrap">
                  <div className="commentTextArea">
                    <span className="commmentName">{el?.user?.first_name}</span>
                    <span className="commmentData">{el?.comments}</span>
                  </div>
                  <div className="commentDate">
                    <span>{moment(el?.created_at).fromNow()}</span>
                  </div>
                </div>
              </div>
            )
          })}
          {pageNumber != total &&
            <div className="moreComments" onClick={() => handleCommentPagination()}>
              View More Comments <DoubleRightOutlined />
            </div>}
        </div>
        <div className="communityDoComment">
          {localStorage.getItem("proImg") ?
            <div className="commentUserImg"> <img src={localStorage.getItem("proImg")} alt="" /></div> :
            <div className="commentUserImg"> <img src={proImg} alt="" /></div>
          }

          <div className="commentUserModalInput"><Input value={comment} onChange={(el) => { setComment(el.target.value) }} placeholder="Write a comment..." /></div>
          <div className="commentUserImg" onClick={() => { subs ? handleComment(pId) : navigate('/subscription', { state: { data: '0' } }) }}><SendOutlined className="sendIcon" /></div>
        </div>
      </Modal>
    </>
  );
}

export default CommunityPage;

const spinStyle = {
  color: 'red', // Change this to your desired color
}