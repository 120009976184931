import React, { useContext, useState } from "react";
import { Layout, Menu,Row,Col,Typography,Anchor,Modal } from 'antd';
import logo from  '../images/fantasy-logo.svg';
import facebook from '../images/facebook.svg';
import twitter from '../images/twitter.svg';
import instagram from '../images/instagram.svg';
import youtube from '../images/youtube.svg';
import fleft from '../images/fleftlogo.svg';
import fright from '../images/frightlogo.svg';
import { AuthContext } from "../App";
import LogIn from "./LogIn";
import SignUp from "./SignUp";


const { Footer } = Layout;
// const { Text } = Typography;

function FooterContent(){
    const [isAuth, setIsAuth] = useContext(AuthContext);
    const [isModalLoginOpen, setIsModalLoginOpen] = useState(false);
    const [isModasignuplOpen, setIsModalSignupOpen] = useState(false);

    const handleOk = () => {
        setIsModalLoginOpen(false);
      };
      const handleCancel = () => {
        setIsModalLoginOpen(false);
      };
    
      const handleOksignup = () => {
        setIsModalSignupOpen(false);
      };
      const handleCancelsignup = () => {
        setIsModalSignupOpen(false);
      };

    return(
        <Layout>
        <Footer className="footerWrap" style={{paddingTop: "60px"}}>
        <img src={fleft} alt={''} className="fllogo" />
        <img src={fright} alt={''} className="frlogo" />
        <div className="container">
        <div className="footerTop">
        <Row gutter={16}>
        <Col xs={12} sm={12} lg={12} xl={6}>
        <div className="footerAbout">
        <div className="flogo">
        <img src={logo} alt="logo" />
        </div>
        
         <div className="footerSocial">
         <Anchor href="/about"><img src={facebook} alt="Facebook" /> </Anchor>
         <Anchor href="/about"><img src={twitter} alt="Twitter" /></Anchor>
         <Anchor href="/about"><img src={instagram} alt="Instagram" /></Anchor>
         <Anchor href="/about"><img src={youtube} alt="Youtube" /></Anchor>
        </div>
         </div>
        </Col>
        {isAuth &&
        <Col xs={12} sm={12} lg={12} xl={6}>
        <Menu mode="vertical" className="footerMenulist">
        <Menu.Item key="contact"><a href="/">Create Contest</a></Menu.Item>
        <Menu.Item key="contestent List"><a href="/Contestentlist">Contest List</a></Menu.Item>
       </Menu>
        </Col>}
        <Col xs={12} sm={12} lg={12} xl={6}>
        <Menu mode="vertical" className="footerMenulist">
        <Menu.Item key="about"><a href="/joincommunity">Community</a></Menu.Item>
        <Menu.Item key="services"><a href='/Contactuspage'>Contact Us</a></Menu.Item>
        <Menu.Item key="contact"><a href='/Contestpage'>Contest</a></Menu.Item>
       {!isAuth &&
        <Menu.Item key="contact"><a onClick={()=>{setIsModalSignupOpen(true)}}>Create Account</a></Menu.Item>}
       </Menu>
        </Col>
        <Col xs={12} sm={12} lg={12} xl={6}>
        <Menu mode="vertical" className="footerMenulist">
        <Menu.Item><Typography level={3} className="footerListHeading">Contact us</Typography></Menu.Item>
        <Menu.Item>hello@fantasyrapper.com</Menu.Item>
        <Menu.Item>+91 11111 22222</Menu.Item>
        <Menu.Item>772 Lyonwood Ave 
        Walnut, CA 91789</Menu.Item>
       </Menu>
        </Col>
        </Row>
        </div>
        <div className="footerBottom">
        <Row gutter={16} align="middle" justify="flex-end">
        <Col xs={12} sm={12} lg={12} xl={12}>
        <div className="footerCopyright text-white">
        &copy; {new Date().getFullYear()} Fantasy. All Rights Reserved.
        </div>
        </Col>
        <Col xs={12} sm={12} lg={12} xl={12}>
        <Menu mode="horizontal" className="footerCopyrightlist">
        <Menu.Item key="home"><a href="/TermsandConditions">Terms & Conditions</a></Menu.Item>
        <Menu.Item key="about"><a href="/privacypolicy">Privacy Policy</a></Menu.Item>
        {/* <Menu.Item key="services"><a href="/">Sitemap</a></Menu.Item> */}
        {/* <Menu.Item key="contact"><a href="/">| Disclaimer</a></Menu.Item> */}
       </Menu>
        </Col>
        </Row>
        </div>
        </div>
        </Footer>

        <Modal title="" className="loginModal" open={isModalLoginOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <LogIn />
        <div className='dontAccountWrap'>
    <span className='dontText'>Don’t have an account?</span>  <div  onClick={()=>{setIsModalSignupOpen(true);setIsModalLoginOpen(false)}} className="notSignLink">Sign up</div> 
    </div>
      </Modal>

      <Modal title="" className="loginModal" open={isModasignuplOpen} onOk={handleOksignup} onCancel={handleCancelsignup} footer={null}>
        <SignUp />
        <div className='dontAccountWrap'>
        <span className='dontText'>Already have an account?</span>  <div onClick={()=>{setIsModalSignupOpen(false);setIsModalLoginOpen(true)}} className="notSignLink">Log In</div>
      </div>
      <br />
      <div>
        <p className='dontText' style={{ textAlign: "center" }}>If you have any query or face any issue please connect us on<br />
          1800-2342-2342 Or mail Us support@fanstasy.com</p>
      </div>
      </Modal>


        </Layout>
 );}


export default FooterContent;