import {
  Anchor,
  Col,
  Form,
  Input,
  Row,
  Typography
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FooterContent from "../../components/FooterContent";
import HeaderAuthTop from "../../components/HeaderAuthTop";
import { EndPoints } from "../../endpoint";
import image1 from "../../images/Image-2.png";
import verifyicon from "../../images/verify.png";
import { GetData } from "../../services";
import "../Profile/UserProfile.css";
import frame1 from "../../images/Help&center.png"
import frame2 from "../../images/Help&center2.png"
import frame3 from "../../images/Help&center3.png"
import moment from "moment";
import { Space, Tag } from 'antd';
import {
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer, TableHead,
  TableRow,
  Tooltip
} from '@mui/material';

const MyFormItemContext = React.createContext([]);

const { Title } = Typography;
const { Link } = Anchor;
const { TextArea } = Input;

const MyFormItemGroup = ({ prefix, children }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(
    () => [...prefixPath, ...toArr(prefix)],
    [prefixPath, prefix]
  );
  return (
    <MyFormItemContext.Provider value={concatPath}>
      {children}
    </MyFormItemContext.Provider>
  );
};

function toArr(str) {
  return Array.isArray(str) ? str : [str];
}
const MyFormItem = ({ name, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
  return <Form.Item name={concatName} {...props} />;
};

function ContestentHistory() {
  const navigate = useNavigate()
  const [dataList, setDataList] = useState([])

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Contestent Name 1',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Contestent Name 2',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date & Time',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Payment Status',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 5 ? 'geekblue' : 'green';
            if (tag === 'Unpaid') {
              color = 'red';
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Winner Name',
      dataIndex: 'fees',
      key: 'fees',
      render: (text) => <b>{text}</b>,
    },
    {
      title: 'Winner Prize',
      dataIndex: 'fees',
      key: 'fees',
      render: (text) => <b>{text}</b>,
    }
    // {
    //     title: 'Fees($)',
    //     dataIndex: 'fees',
    //     key: 'fees',
    //     render: (text) => <b>{text}</b>,
    //   }
  ];

  const data = [
    {
      key: '1',
      name: 'John',
      //   age: 32,
      address: 'New York No. 1 Lake Park',
      date: '21 Sep 2023',
      tags: ['Paid'],
      fees: '$184',
    },
    {
      key: '2',
      name: 'Jim , Alex',
      //   age: 42,
      address: 'London No. 1 Lake Park',
      date: '21 Sep 2023',
      tags: ['Unpaid'],
      fees: '$98',
    },
    {
      key: '3',
      name: 'Joe smith , Jackson',
      //   age: 32,
      address: 'Sydney No. 1 Lake Park',
      date: '21 Sep 2023',
      tags: ['Paid'],
      fees: '$123',
    },
    {
      key: '4',
      name: 'Kalra , John',
      //   age: 32,
      address: 'Nigeria',
      date: '21 Sep 2023',
      tags: ['Unpaid'],
      fees: '$100',
    },
    {
      key: '5',
      name: 'Jim , Alex',
      //   age: 42,
      address: 'London No. 1 Lake Park',
      date: '21 Sep 2023',
      tags: ['Unpaid'],
      fees: '$98',
    },
  ];


  useEffect(() => {
    getBattleList();
  }, []);

  const getBattleList = async () => {
    try {
      const res = await GetData(EndPoints.BATTLE_HISTORY);
      if (res?.status == 200 && res?.success_status == true) {
        let newArr = res.data.map((x, i) => ({
          sr: i + 1,
          amt: `$${x?.entry_fee}`,
          winnerAmt: `$${x?.winner_prize}`,
          ...x
        }));

        console.log(res.data, '00000red')
        setDataList(newArr);
      } else if (res?.status == 200 && res?.success_status == false) {
      } else {
      }
    } catch (err) {
    }
  };

  return (
    <>
      <div className="Profilesection" >
        <div className="container profilewrap" style={{ width: "92%", background: "#fff" }} >
          <Form name="form_item_path" layout="vertical">
          <h2 className="contesthomeform">Battle History</h2>
            <div className="border" style={{ marginBottom: "20px" }}>
            </div>
            <TableContainer className="tableContainer" style={{ border: "1px solid #E0E7ED", background: "#fff", borderRadius: "3px" }}>
             <Table>
              <TableHead className='tableHeader'>
                  <TableRow>
                    <TableCell>S.No</TableCell>
                    <TableCell>Contest Name</TableCell>
                    <TableCell>Date Of Participation</TableCell>
                    <TableCell>Contest Time</TableCell>
                    <TableCell>Entry Fee</TableCell>
                    <TableCell>Winning Price</TableCell>
                    <TableCell>Rapper Name 1</TableCell>
                    <TableCell>Rapper Name 2</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataList.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.sr}</TableCell>
                      <TableCell>{row?.contest_name}</TableCell>
                      <TableCell>{moment(row.contest_date).format('MMM DD, YYYY')}</TableCell>
                      <TableCell>{row.contest_time}</TableCell>
                      <TableCell className='successText'>{row.amt}</TableCell>
                      <TableCell className='successText'>{row.winnerAmt}</TableCell>
                      <TableCell>{row.rapper_name}</TableCell>
                      <TableCell>{row.rapper_name2}</TableCell>

                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>


          </Form>
        </div>
      </div>

      {/* <FooterContent /> */}
    </>
  );
}

export default ContestentHistory;