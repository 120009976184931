import React, { useState, useEffect } from "react";
import HeaderTop from "../../components/HeaderTop";
import { Row, Col, Typography, Anchor, Tabs, Button } from "antd";
import "../../pages/ContestPage/ContestPage.css";
import calender from "../../images/calendar.svg";
import contestGraphic from "../../images/contestGraphic.svg";
import watch from "../../images/watch.svg";
import FooterContent from "../../components/FooterContent";
import { PlusOutlined } from "@ant-design/icons";
import { GetData } from "../../services";
import { EndPoints } from "../../endpoint";
// import battleimg1 from "../../images/b-1.png";
// import battleimg2 from "../../images/b-2.png";
// import battleimg3 from "../../images/b-3.png";
// import battleimg4 from "../../images/b-4.png";
import BannerAuth from "../../components/BannerAuth";
import JoinBattlemain from "./JoinBattleMainComponent";
import PostSection from "../../components/PostSection";
import JoinBattleContestent from "./JoinBattleContestentComponent";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

const { Title } = Typography;
const { Link } = Anchor;
const { TabPane } = Tabs;
const battles = [
  {
    id: "1",
    battleimg: require("../../images/b-1.png"),
    winnerShieldimg: require("../../images/shield.png"),
    winnername: "Arlene",
    winnerNumimg: require("../../images/onebatch.png"),
  },
  {
    id: "2",
    battleimg: require("../../images/b-2.png"),
    winnerShieldimg: require("../../images/shield.png"),
    winnername: "Arlene",
    winnerNumimg: require("../../images/onebatch.png"),
  },
  {
    id: "3",
    battleimg: require("../../images/b-3.png"),
    winnerShieldimg: require("../../images/shield.png"),
    winnername: "Arlene",
    winnerNumimg: require("../../images/onebatch.png"),
  },
  {
    id: "4",
    battleimg: require("../../images/b-4.png"),
    winnerShieldimg: require("../../images/shield.png"),
    winnername: "Arlene",
    winnerNumimg: require("../../images/onebatch.png"),
  },
  // {
  //   id: "5",
  //   battleimg: require("../../images/b-4.png"),
  //   winnerShieldimg: require("../../images/shield.png"),
  //   winnername: "Arlene",
  //   winnerNumimg: require("../../images/onebatch.png"),
  // },
];
function JoinBattleContestPage() {
  const navigate = useNavigate()
  const [upcomingdata, setUpcomingData] = useState([]);
  const [ongoingdata, setOngoingData] = useState([]);
  const [canceldata, setCancelData] = useState([]);
  const location = useLocation()


  useEffect(() => {
    getUpcomingList();
  }, []);

  //UPCOMING BATTEL SECTION
  const getUpcomingList = async () => {
    try {
      const res = await GetData(EndPoints.UPCOMINGCONTEST);
      if (res?.status == 200 && res?.success_status == true) {
        setUpcomingData(res?.data);
      } else if (res?.status == 200 && res?.success_status == false) {
      } else {
      }
    } catch (err) {
    }
  };

  return (
    <>
      <HeaderTop />

      <div className="JoinNowsection">
        {/* <BannerAuth /> */}
        {/* <div style={{paddingRight:50,paddingLeft:50}}>
        <img src={location.state.banner} alt="" className="" style={{height:'270px',width:'100%',}}/>
        </div> */}
        <JoinBattleContestent/>
        <div className="container">
        <div className="innerContestPadd contestSpace">
                    <div className="sectionHeading text-left ">
                      <Title className="">Upcoming Contest</Title>
                    </div>
                    <Row gutter={16} align="middle" className="battleCardWrap">
                      {upcomingdata.map((battle) => (
                        <Col xs={24} sm={24} md={24} lg={6} xl={6} key={""}>
                        <div className="battleCards">
                          <div className="battleImg">
                          <div className='battleCombImg'>
                  <div className='battleFirstImg'>
                  <img src={battle.rapper_image} alt="" className="" />
                  </div>
                  <div className='battleSecondImg'>
                  <img src={battle.rapper_image2} alt="" className="" />
                  </div>
                  </div>
                            
                            <div className="battleName">
                              <span className="battleFirst">{battle?.rapper_name}</span>
                              <span className="battleSecond">{battle?.rapper_name2}</span>
                            </div>
                          </div>
                          <div className="battleInfo">
                            <Title className="battleDes">
                            {battle?.contest_name}
                            </Title>
                            <div className="battleTimeline">
                              <span className="">
                                <img src={calender} />
                                {moment(battle?.contest_date).format('MMM DD, YYYY')}
                              </span>
                              <span className="">
                                <img src={watch} />
                                {battle?.contest_time}
                              </span>
                            </div>
                            {/* <a href="/joincontest" className="joinNowBtn">
                              Join Now
                            </a> */}
                             <div className="joinNowBtn" onClick={() => navigate('/joincontest', { state: { id: battle.id } })}>
                                Join Now
                              </div>
                          </div>
                        </div>
                      </Col>
                      ))}
                    </Row>
                  </div>
        </div>
        <PostSection/>


       
      </div>

      <FooterContent />
    </>
  );
}

export default JoinBattleContestPage;
