import React, { createContext, useState, useEffect } from 'react'
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import ContestPage from './pages/ContestPage/ContestPage';
import LogIn from "./components/LogIn";
import SignUp from "./components/SignUp";
import AboutUs from "./pages/AboutPage/AboutUs";
import ContactUs from "./pages/ContactPage/ContactUs";
import CreateContest from "./pages/CreateContest/CreateContest";
import './App.css';
import './Responsive.css';
import ContestList from "./pages/CreateContest/ContestList";
import ParticipateList from "./pages/CreateContest/ParticipateList";

import { ToastContainer } from "react-toastify";
import BannerAuth from "./components/BannerAuth";
import JoinContest from "./pages/ContestPage/JoinContestPage";
import JoinBattleContestent from './pages/ContestPage/JoinBattleContestentComponent';
import JoinBattleContestPage from './pages/ContestPage/JoinBattleContestantPage';
import UserProfile from './pages/Profile/Profile';
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { MyProSidebarProvider } from "./../src/components/Sidebar/sidebarContext";
import ForgotPage from './pages/Forgot/ForgotPage';
import ResetPassword from './pages/Forgot/ResetPassword';
import SideBarRoutes from './SideBarRoutes';
import CouponCodes from './pages/Profile/CouponCode';
import PrivacyPolicy from './pages/Profile/PrivacyPolicy';
import TermsCondition from './pages/Profile/Termsandcondition';
import FAQ from './pages/Profile/FAQ';
import NotificationsSetting from './pages/Profile/NotificationsSetting';
import HelpandCenter from './pages/Profile/HelpCenter';
import ContestentHistory from './pages/Profile/ContestentHistory';
import PaymentHistory from './pages/Profile/PaymentHistory';
import Subscription from './pages/Profile/Subscription';
import CommunityPage from './pages/Community/CommunityPage';
import CommunityCourse from './pages/Community/CommunityCourse';
import CommunityCourseDetails from './pages/Community/CommunityCourseDetails';
import SubscriptionPayment from './pages/Profile/SubscriptionPayment';
import NotificationPage from './pages/NotificationPage/NotificationPage';
import SaveCards from './pages/Profile/SaveCards';


export const AuthContext = createContext();

const AuthNavigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/Contestpage" element={<ContestPage />} />
        <Route path="/Login" element={<LogIn />} />
        <Route path="/Signup" element={<SignUp />} />
        <Route path="/Aboutuspage" element={<AboutUs />} />
        <Route path="/Contactuspage" element={<ContactUs />} />
        <Route path="/ForgotPage" element={<ForgotPage />} />
        <Route path="/reset-password/" element={<ResetPassword />} />
        <Route path="/joincommunity" element={<CommunityPage/>} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/TermsandConditions" element={<TermsCondition />} />
        <Route path="/joinbattlecontestentPage" element={<JoinBattleContestPage/>}/>
        <Route path="/communitycourse" element={<CommunityCourse/>} />
      </Routes>
    </BrowserRouter>
  )
}

const HomeNavigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/Contestpage" element={<ContestPage />} />
        <Route path="/Aboutuspage" element={<AboutUs />} />
        <Route path="/Contactuspage" element={<ContactUs />} />
        <Route path="/CreateContest" element={<CreateContest />} />
        <Route path="/ContestentList" element={<ContestList />} />
        <Route path="/ParticipateList" element={<ParticipateList />} />
        <Route path="/BannerAuth" element={<BannerAuth />} />
        <Route path="/joincontest" element={<JoinContest />} />
        <Route path="/joinbattlecontestentPage" element={<JoinBattleContestPage/>}/>
      
        <Route path="/UserProfile" element={<UserProfile />} />
        <Route path="/SaveCards" element={<SaveCards />} />
        
        <Route path="/couponcodes" element={<CouponCodes />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/TermsandConditions" element={<TermsCondition />} />
        <Route path="/Faq" element={<FAQ />} />
        <Route path="/NotificationsSetting" element={<NotificationsSetting/>} />
        <Route path="/HelpandCenter" element={<HelpandCenter/>} />
        <Route path="/Contesthistory" element={<ContestentHistory/>} />
        <Route path="/paymenthistory" element={<PaymentHistory/>} />
        <Route path="/subscription" element={<Subscription/>} />
        <Route path="/subscriptionpayment" element={<SubscriptionPayment/>} />
        <Route path="/joincommunity" element={<CommunityPage/>} />
       <Route path="/communitycourse" element={<CommunityCourse/>} />
       <Route path="/CommunityCourseDetails" element={<CommunityCourseDetails/>} />
       <Route path="/NotificationPage" element={<NotificationPage/>} />
       
       
        {/* </main>
          </div>
        </MyProSidebarProvider> */}

      </Routes>
    </BrowserRouter>
  )
}


export default function App() {
  const [isAuth, setIsAuth] = useState(false)

  useEffect(() => {
    getToken()
  }, [isAuth])

  const getToken = async () => {
    let token = await localStorage.getItem('access_token')
    token ? setIsAuth(true) : setIsAuth(false)
  }

  return (
    <AuthContext.Provider value={[isAuth, setIsAuth]}>
      <ToastContainer/>
      {isAuth ? <HomeNavigation /> : <AuthNavigation />}
    </AuthContext.Provider>
  )
}