import React, { useState } from 'react'
import "../Forgot/ForgotPage.css";
import {
  Row,
  Col,
  Typography,
  Anchor,
  Carousel,
  Tabs,
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  DatePicker,
  Upload,
  normFile,
  message, MyFormItem
} from "antd";
import Bootstrap from 'bootstrap'
import HeaderTop from '../../components/HeaderTop';
import FooterContent from '../../components/FooterContent';
import { useNavigate } from 'react-router-dom';
import Validator from '../../Validator';
import { toast } from 'react-toastify';
import { PostData } from '../../services';
import { EndPoints } from '../../endpoint';

export default function ForgotPage() {
  const MyFormItemContext = React.createContext([]);
  const [email, setEmail] = useState('')
  const { Title } = Typography;
  const { Link } = Anchor;

  const MyFormItemGroup = ({ prefix, children }) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatPath = React.useMemo(() => [...prefixPath, ...toArr(prefix)], [prefixPath, prefix]);
    return <MyFormItemContext.Provider value={concatPath}>{children}</MyFormItemContext.Provider>;
  };

  function toArr(str) {
    return Array.isArray(str) ? str : [str];
  }
  const MyFormItem = ({ name, ...props }) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatName = name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
    return <Form.Item name={concatName} {...props} />;
  }
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!Validator.isValidEmail(email)) {
      toast.error('Please enter valid email')
    }
    else {
      try {
        let data = {
          "email": email
        }
        const res = await PostData(EndPoints.FORGOT_PASSWORD, data)
        if (res?.status == 200 && res?.success_status == true) {
          toast.success(res.message)
        }
        else if (res?.status == 200 && res?.success_status == false) {
          toast.error(res.message)
        }
        else {
          toast.error(res.message)
        }
      }
      catch (err) {
        toast.error('Something went wrong')
      }
    }
  }


  return (
    <>
      <HeaderTop />
      <div classNameName="container" style={{ margin: "70px 0px" }}>
        <div className="form">
          <form action="">
            <div className="card" style={{ border: "2px solid white", borderRadius: "10px", backgroundColor: "white" }}>

              <h2 className="head">Forgot Password?</h2>
              <p className="subhead">You can reset your Password here</p>

              <div className="fieldWrappeer" >
                <Row>
                <label style={{ color:"#070c20b3" }}>Email: <strong style={{color:"red"}}>*</strong></label>

                  <Input
                    style={{ fontSize: "16px" }}
                    required
                    placeholder='Enter your email here'
                    size="large" className="Inputcolor"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Row>
              </div>

              {/* <MyFormItem className="inputemail" name="email" label="Email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}>
            <Input
            defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
              />
          </MyFormItem> */}
              {/* <div onClick={()=>handleSubmit()}>
                           <button className="btn">Forget Password</button>
</div> */}


              <div onClick={() => handleSubmit()} className="btn">Forget Password</div>

            </div>
          </form>
        </div>
      </div>
      <FooterContent />
    </>

  )
}