import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TimePicker
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Validator from "../../Validator";
import BannerAuth from "../../components/BannerAuth";
import FooterContent from "../../components/FooterContent";
import HeaderAuthTop from "../../components/HeaderAuthTop";
import { EndPoints } from "../../endpoint";
import { GetData, PostData } from "../../services";
import "./CreateContest.css";

const MyFormItemContext = React.createContext([]);

const { TextArea } = Input;

const MyFormItemGroup = ({ prefix, children }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(
    () => [...prefixPath, ...toArr(prefix)],
    [prefixPath, prefix]
  );
  return (
    <MyFormItemContext.Provider value={concatPath}>
      {children}
    </MyFormItemContext.Provider>
  );
};

function toArr(str) {
  return Array.isArray(str) ? str : [str];
}
const MyFormItem = ({ name, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
  return <Form.Item name={concatName} {...props} />;
};

function CreateContest() {
  const navigate = useNavigate()
  const location = useLocation()
  const [contextName, setContextName] = useState('')
  const [contextType, setContextType] = useState('')
  const [contextDate, setContextDate] = useState('')
  const [contextTime, setContextTime] = useState('')
  const [contextFee, setContextFee] = useState('')
  const [comingSoon, setComingSoon] = useState(false)
  const [contextWinnerPrice, setContextWinnerPrice] = useState('')
  const [contextRapper_1, setContextRapper_1] = useState('')
  const [contextRapper_2, setContextRapper_2] = useState('')
  const [contextDesc, setContextDesc] = useState('')
  const [uploadBanner, setUploadBanner] = useState('')
  const [uploadRapper_1, setUploadRapper_1] = useState('')
  const [uploadRapper_2, setUploadRapper_2] = useState('')
  const [contestTypeOption, setContestTypeOption] = useState([
    { value: 'one-to-one', label: 'one-to-one' }
  ])
  const [couponOtion, setCouponOption] = useState([])
  const [couponValue, setCouponValue] = useState('')

  useEffect(() => {
    setTimeout(() => {
      getContestData()
    }, "2000");
  }, [])

  const getContestData = async () => {
    let data = await location?.state?.updatedData;
    setContextName(data?.contest_name)
    setContextType(data?.battel_type)
    setContextDate(data?.contest_date)
    setContextTime(data?.contest_time)
    setContextFee(data?.entry_fee)
    setComingSoon(data?.coming_soon == '1' ? true : false)
    setContextWinnerPrice(data?.winner_prize)
    setContextRapper_1(data?.rapper_name)
    setContextRapper_2(data?.rapper_name2)
    setContextDesc(data?.description)
    setUploadBanner(data?.banner_image)
    setUploadRapper_1(data?.rapper_image ? data?.rapper_image : '')
    setUploadRapper_2(data?.rapper_image2 ? data?.rapper_image2 : '')
  }
  let data = location?.state?.updatedData;


  const handleChangeDate = (date, dateString) => {
    setContextDate(dateString)
  };

  const handleChangeTime = (time, timeString) => {
    setContextTime(timeString)
  };

  const handleSubmit = async () => {
    if (Validator.isEmpty(contextName)) {
      toast.error('Please enter contest Name')
    }
    else if (Validator.isEmpty(contextDate)) {
      toast.error('Please enter contest Date')
    }
    else if (Validator.isEmpty(contextTime)) {
      toast.error('Please enter contest Time')
    }
    else if (Validator.isEmpty(contextType)) {
      toast.error('Please enter contest Type')
    }
    else if (Validator.isEmpty(uploadBanner)) {
      toast.error('Please upload Banner image')
    }
    else if (Validator.isEmpty(contextRapper_1)) {
      toast.error('Please enter Rapper Name ')
    }
    else if (Validator.isEmpty(contextRapper_2)) {
      toast.error('Please enter Rapper Name 2')
    }
    else if (Validator.isEmpty(contextFee)) {
      toast.error('Please enter contest Fee')
    }
    else if (Validator.isEmpty(contextWinnerPrice)) {
      toast.error('Please enter Winner price')
    }
    else if (Validator.isEmpty(contextDesc)) {
      toast.error('Please enter contest Description')
    }
    else {
      try {
        const formData = new FormData();
        formData.append("contest_name", contextName)
        formData.append("contest_date", contextDate)
        formData.append("contest_time", contextTime)
        formData.append("battel_type", contextType)
        formData.append("banner_image", uploadBanner)
        formData.append("coming_soon", comingSoon ? '1' : '0')
        formData.append("rapper_name", contextRapper_1)
        formData.append("rapper_name2", contextRapper_2)
        formData.append("rapper_image", uploadRapper_1)
        formData.append("rapper_image2", uploadRapper_2)
        formData.append("entry_fee", contextFee)
        formData.append("winner_prize", contextWinnerPrice)
        formData.append("description", contextDesc)
        formData.append("coupon_id", couponValue)

        formData.append("user_id", localStorage.getItem("user_id"))
        let res = await PostData(EndPoints.CREATECONTEST, formData, '1')
        if (res.status == 200 && res.success_status == true) {
          toast.success(res?.message);
          navigate('/contestPage')
        }
        else if (res.status == 200 && res.success_status == false) {
          toast.error(res?.message);
        }
        else {
          toast.error(res?.message);
        }
      }
      catch (err) {
        toast.error('Something went wrong');
      }
    }
  }

  const handleUpdate = async () => {
    if (Validator.isEmpty(contextName)) {
      toast.error('Please enter context Name')
    }
    else if (Validator.isEmpty(contextDate)) {
      toast.error('Please enter context Date')
    }
    else if (Validator.isEmpty(contextTime)) {
      toast.error('Please enter context Date')
    }
    else if (Validator.isEmpty(contextType)) {
      toast.error('Please enter context Date')
    }
    else if (Validator.isEmpty(uploadBanner)) {
      toast.error('Please enter context Date')
    }
    else if (Validator.isEmpty(contextRapper_1)) {
      toast.error('Please enter context Date')
    }
    else if (Validator.isEmpty(contextRapper_2)) {
      toast.error('Please enter context Date')
    }
    else if (Validator.isEmpty(contextFee)) {
      toast.error('Please enter context Date')
    }
    else if (Validator.isEmpty(contextWinnerPrice)) {
      toast.error('Please enter context Date')
    }
    else if (Validator.isEmpty(contextDesc)) {
      toast.error('Please enter context Date')
    }
    else {
      try {
        const formData = new FormData();
        formData.append("contest_name", contextName)
        formData.append("contest_date", contextDate)
        formData.append("contest_time", contextTime)
        formData.append("coming_soon", comingSoon ? '1' : '0')
        formData.append("battel_type", contextType)
        formData.append("banner_image", uploadBanner)
        formData.append("rapper_name", contextRapper_1)
        formData.append("rapper_name2", contextRapper_2)
        formData.append("rapper_image", uploadRapper_1)
        formData.append("rapper_image2", uploadRapper_2)
        // formData.append("coupon_id", '')
        formData.append("contest_id", location?.state?.updatedData?.id)
        formData.append("winner_prize", contextWinnerPrice)
        formData.append("description", contextDesc)
        formData.append("entry_fee", contextFee)
        let res = await PostData(EndPoints.CONTESTUPDATE, formData, '1')
        if (res.status == 200 && res.success_status == true) {
          toast.success(res?.message);
          navigate('/contestPage')
        }
        else if (res.status == 200 && res.success_status == false) {
          toast.error(res?.message);
        }
        else {
          toast.error(res?.message);
        }
      }
      catch (err) {
        console.log(err, 'update errrrrrrrr')
        toast.error('Something went wrong');
      }
    }
  }

  useEffect(() => {
    getCoupon()
  }, [])

  const getCoupon = async () => {
    try {
      let res = await GetData(EndPoints.couponList)
      console.log(res.data, '887545433e33')
      if (res.status == 200 && res.success_status == true) {
        let newArr = res.data.map((x, i) => ({
          value: x?.id,
          label: x?.name,
          ...x
        }));
        setCouponOption(newArr)

      }
      else if (res.status == 200 && res.success_status == false) {
        toast.error(res?.message);
      }
      else {
        toast.error(res?.message);
      }
    }
    catch (err) {
      toast.error('Something went wrong');
    }
  }

  const disabledDate = (current) => {
    return current && current < moment().endOf('day');
  }

  return (
    <>
      <HeaderAuthTop />
      <div className="Createcontestsection">
        <BannerAuth />
        <div className="container">
          <Form name="form_item_path" layout="vertical">
            <h2 className="contesthomeform">Create your Content Here</h2>
            <MyFormItemGroup prefix={["user"]}>
              <MyFormItemGroup prefix={["name"]}>
                <Row>
                  <Col span={11} style={{ marginRight: "3%" }}>
                    <MyFormItem name="name" label="Enter Contest Name" rules={[
                      {
                        required: true,
                        message: 'Please Fill this field',
                      },
                    ]}>
                      <Input
                        size="large"
                        defaultValue={data?.contest_name}
                        onChange={(e) => setContextName(e.target.value)}
                      />
                    </MyFormItem>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Contest Type" rules={[
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      {
                        required: true,
                        message: 'Please input your E-mail!',
                      },
                    ]}>
                      <Select
                        size="large"
                        defaultValue={data?.battel_type}
                        onChange={(el) => setContextType(el)}
                        options={contestTypeOption}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={11} style={{ marginRight: "3%" }}>
                    <Form.Item label="Date">
                      {data?.contest_date ?
                        <DatePicker
                          defaultValue={data?.contest_date ? dayjs(data?.contest_date, 'YYYY/MM/DD') : dayjs()}
                          disabledDate={disabledDate}
                          style={{ width: "100%" }} size="large" onChange={handleChangeDate} />
                        :
                        <DatePicker
                          disabledDate={disabledDate}
                          style={{ width: "100%" }} size="large" onChange={handleChangeDate} />
                      }
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Contest Time">
                      {data?.contest_time ?
                        <TimePicker
                          defaultValue={data?.contest_time ? dayjs(data?.contest_time, 'HH:mm') : dayjs()}
                          format="HH:mm"
                          onChange={handleChangeTime}
                          size="large"
                          style={{ width: "100%" }}
                        /> :
                        <TimePicker
                          format="HH:mm"
                          onChange={handleChangeTime}
                          size="large"
                          style={{ width: "100%" }}
                        />}
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={11} style={{ marginRight: "3%" }}>
                    <MyFormItem label="Entry Fees">
                      <Input type="number" size="large" defaultValue={data?.entry_fee} onChange={(el) => setContextFee(el.target.value)} />
                    </MyFormItem>
                  </Col>

                  <Col span={12}>
                    <MyFormItem label="Winner Price">
                      <Input type="number" size="large" defaultValue={data?.winner_prize} onChange={(el) => setContextWinnerPrice(el.target.value)} />
                    </MyFormItem>
                  </Col>
                </Row>

                <Row>
                  <Col
                    span={11}
                    style={{ marginRight: "3%" }}
                  >
                    <MyFormItem name="Rapper Name 1" label="Rapper Name 1">
                      <Input
                        size="large"
                        defaultValue={data?.rapper_name}
                        onChange={(e) => setContextRapper_1(e.target.value)}
                      />
                    </MyFormItem>

                  </Col>
                  <Col span={12}>
                    <MyFormItem name="Rapper Image Name 1" label="Rapper Image Name 1">
                      <Input className='fieldControl' fullWidth size="large"
                        required="required"
                        onChange={(e) => setUploadRapper_1(e?.target?.files[0])}
                        accept="image/*"
                        value={uploadRapper_1?.image}
                        id="outlined-basic"
                        variant="outlined"
                        type="file"
                        inputProps={{
                          multiple: false
                        }}
                      />
                     </MyFormItem>
                  </Col>
                </Row>
                <Row>
                  <Col
                    span={11}
                    style={{ marginRight: "3%" }}
                  >
                    <MyFormItem name="Rapper Name 2" label="Rapper Name 2">
                      <Input
                        size="large"
                        defaultValue={data?.rapper_name2}
                        onChange={(e) => setContextRapper_2(e.target.value)}
                      />
                    </MyFormItem>

                  </Col>
                  <Col span={12}>
                    <MyFormItem name="Rapper Image Name 2" label="Rapper Image Name 2">
                      <Input className='fieldControl' fullWidth size="large"
                        required="required"
                        onChange={(e) => setUploadRapper_2(e?.target?.files[0])}
                        accept="image/*"
                        value={uploadRapper_2?.image}
                        id="outlined-basic"
                        variant="outlined"
                        type="file"
                        inputProps={{
                          multiple: false
                        }}
                      />
                    </MyFormItem>
                  </Col>
                </Row>

                <Row>
                  <Col
                    span={11}
                    style={{ marginRight: "3%" }}>
                    <Form.Item label="Description">
                      <TextArea
                        autoSize={{ minRows: 7, maxRows: 5 }}
                        defaultValue={data?.description}
                        onChange={(el) => setContextDesc(el?.target?.value)}
                      />
                    </Form.Item>

                  </Col>

                  <Col span={12}>
                    <MyFormItem name="Banner Images" label="Banner Images"  >
                      <Input className='fieldControl' fullWidth size="large"
                        required="required"
                        onChange={(e) => setUploadBanner(e?.target?.files[0])}
                        accept="image/*"
                        value={uploadBanner?.image}
                        id="outlined-basic"
                        variant="outlined"
                        type="file"
                        inputProps={{
                          multiple: false
                        }}
                      />
                    </MyFormItem>
                    {location?.state?.mode != 'update' ?
                      <Col span={24} style={{ marginTop: "-10px" }}>
                        <Form.Item label="Coupon ">
                          <Select
                            size="large"
                            defaultValue={couponValue}
                            onChange={(el) => setCouponValue(el)}
                            options={couponOtion}
                          />
                        </Form.Item>
                      </Col> : null}

                    <Checkbox style={{ marginTop: "-9%" }} checked={comingSoon}
                      onChange={(el) => { setComingSoon(!comingSoon) }}>
                      <p>Mark As Coming Soon</p>
                    </Checkbox>
                  </Col>
                </Row>

                <Row >
                  <div
                    style={{
                      marginLeft: '40%', marginTop: "50px"
                    }}
                  >
                    <Button onClick={() => { location?.state?.mode == 'update' ? handleUpdate() : handleSubmit() }} className="btnsubmit" style={{ padding: " 0px 66px", fontSize: "18px", fontWeight: "500" }} type="primary" shape="round" size={"large"}>
                      Submit
                    </Button>
                  </div>
                </Row>
              </MyFormItemGroup>
            </MyFormItemGroup>
          </Form>
        </div>
      </div>

      <FooterContent />
    </>
  );
}

export default CreateContest;
