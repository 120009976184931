import {
    Anchor,
    Col,
    Form,
    Input,
    Row,
    Typography
  } from "antd";
  import React, { useEffect, useState } from "react";
  import { useNavigate } from "react-router-dom";
  import { toast } from "react-toastify";
  import FooterContent from "../../components/FooterContent";
  import HeaderAuthTop from "../../components/HeaderAuthTop";
  import { EndPoints } from "../../endpoint";
  import image1 from "../../images/Image-2.png";
  import verifyicon from "../../images/verify.png";
  import { GetData } from "../../services";
  import "../Profile/UserProfile.css";
  
  const MyFormItemContext = React.createContext([]);
  
  const { Title } = Typography;
  const { Link } = Anchor;
  const { TextArea } = Input;
  
  const MyFormItemGroup = ({ prefix, children }) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatPath = React.useMemo(
      () => [...prefixPath, ...toArr(prefix)],
      [prefixPath, prefix]
    );
    return (
      <MyFormItemContext.Provider value={concatPath}>
        {children}
      </MyFormItemContext.Provider>
    );
  };
  
  function toArr(str) {
    return Array.isArray(str) ? str : [str];
  }
  const MyFormItem = ({ name, ...props }) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatName =
      name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
    return <Form.Item name={concatName} {...props} />;
  };
  
  function PrivacyPolicy() {
    const navigate = useNavigate()
    const [data, setData] = useState([])
  
    useEffect(() => {
      getData()
    }, [])
  
    const getData = async () => {
      try {
        const res = await GetData(EndPoints?.POLICY)
        if (res?.status == 200 && res?.success_status == true) {
          let newArr = res.data.map((x, i) => ({
            sr: i + 1,
            ...x
            }));
          setData(newArr)
        }
        else if (res?.status == 200 && res?.success_status == true) {
          toast.error(res?.message)
        }
        else {
          toast.error(res?.message)
        }
      }
      catch (err) {
        toast.error('Something went wrong')
      }
    }
  
    return (
      <>
         <HeaderAuthTop /> 
        <div className="Profilesection" >
          <div className="container profilewrap" style={{ width: "92%", background: "#fff" }} >
            <Form name="form_item_path" layout="vertical">
              <h2 className="contesthomeform">Privacy Policy</h2>
              <div className="border"></div>
  
              {data?.map((el) => {
                return (
                  <div className="container couponrowrap" style={{border:"none"}}>
                   <Row 
                  //  style={{backgroundColor:"#FCF8F8"}}
                   >
                        <Col className="tandcwrap" >
                     {/* <h3>{el?.sr}. {el?.title}</h3> */}
                     {/* <p>{el?.description}</p> */}
                     {/* <p>{{ __html: el?.description }}</p> */}
                     <p dangerouslySetInnerHTML={{__html:el?.description}}></p>
                     </Col>
                    </Row>
                  </div>
                )
              })}
  
  
            </Form>
          </div>
        </div>
  
        <FooterContent /> 
      </>
    );
  }
  
  export default PrivacyPolicy;