import UserCircleIco from './components/UserCircleIco';
import HistoryIco from './components/HistoryIco'; 
import FaqIco from './components/FaqIco';
import SubscriptionIco from './components/SubscriptionIco';
import TermsIco from './components/TermsIco';
import PrivacyIco from './components/PrivacyIco';
import LogoutIco from './components/LogoutIco';
import HelpIco from './components/HelpIco';
import CouponIco from './components/CouponIco';
import NotificationIco from './components/NotificationIco';
import ContestHistoryIco from './components/ContestHistoryIco';
import DebitCardIco from './components/DebitCardIco';

export default {
  SideBarList: [
    {
      id: 1,
      name: 'My Profile',
      icon:<UserCircleIco />,
    },
      {
      id: 2,
      name: 'Saved Cards',
      icon: <DebitCardIco />,
    },
    {
        id: 3,
        name: 'My Coupons',
        icon: <CouponIco />,
      },
      {
        id: 4,
        name: 'Payment History',
        icon: <HistoryIco />,
      },
      {
        id: 5,
        name: 'Contest History',
        icon: <ContestHistoryIco />,
      },
      // {
      //   id: 6,
      //   name: 'Battle History',
      //   icon: <HistoryIco />,
      // },
      {
        id: 7,
        name: 'Notifications Settings',
        icon: <NotificationIco />,
      },
      {
        id: 8,
        name: 'Help & Center',
        icon: <HelpIco />,
      },
      {
        id: 9,
        name: 'FAQ',
        icon: <FaqIco />,
      },
      {
        id: 10,
        name: 'My Subscriptions',
        icon: <SubscriptionIco />,
      },
      // {
      //   id: 11,
      //   name: 'Terms & Conditions',
      //   icon: <TermsIco />,
      // },
      // {
      //   id: 12,
      //   name: 'Privacy Policy',
      //   icon: <PrivacyIco />,
      // },
      {
        id: 13,
        name: 'Logout',
        icon: <LogoutIco />,
      }
  ],

};
