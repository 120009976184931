import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 16 16",
    }}
    viewBox="0 0 16 16"
    {...props}
  >
    <style>{".st0{opacity:.2;fill:#fff}"}</style>
    <path d="M8 14.5c-3.6 0-6.5-2.9-6.5-6.5S4.4 1.5 8 1.5s6.5 2.9 6.5 6.5-2.9 6.5-6.5 6.5zm0-12C5 2.5 2.5 5 2.5 8S5 13.5 8 13.5 13.5 11 13.5 8 11 2.5 8 2.5z" />
    <path
      d="M8 14.5c-3.6 0-6.5-2.9-6.5-6.5S4.4 1.5 8 1.5s6.5 2.9 6.5 6.5-2.9 6.5-6.5 6.5zm0-12C5 2.5 2.5 5 2.5 8S5 13.5 8 13.5 13.5 11 13.5 8 11 2.5 8 2.5z"
      className="st0"
    />
    <path d="M8 11.8c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5z" />
    <path
      d="M8 11.8c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5z"
      className="st0"
    />
    <path d="M8 9.5c-.3 0-.5-.2-.5-.5 0-.7.4-1.3 1-1.5.5-.2.8-.6.8-1.2 0-.5-.4-1-.9-1.1-.5-.2-1 0-1.4.4-.1.3-.4.3-.6.1-.3-.1-.3-.4-.1-.7.5-.7 1.5-1 2.4-.7.9.3 1.5 1.1 1.5 2.1 0 .9-.6 1.8-1.4 2.1-.2 0-.3.3-.3.5s-.2.5-.5.5z" />
    <path
      d="M8 9.5c-.3 0-.5-.2-.5-.5 0-.7.4-1.3 1-1.5.5-.2.8-.6.8-1.2 0-.5-.4-1-.9-1.1-.5-.2-1 0-1.4.4-.1.3-.4.3-.6.1-.3-.1-.3-.4-.1-.7.5-.7 1.5-1 2.4-.7.9.3 1.5 1.1 1.5 2.1 0 .9-.6 1.8-1.4 2.1-.2 0-.3.3-.3.5s-.2.5-.5.5z"
      className="st0"
    />
  </svg>
)
export default SvgComponent
