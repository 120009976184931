import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      d="M8 7.014a.504.504 0 0 1-.5-.5v-2.22c0-.273.227-.5.5-.5s.5.227.5.5v2.22a.5.5 0 0 1-.5.5Z"
    />
    <path
      fillRule="evenodd"
      d="M7.5 6.514a.5.5 0 1 0 1 0v-2.22c0-.273-.227-.5-.5-.5s-.5.227-.5.5v2.22Z"
      clipRule="evenodd"
    />
    <path
      fillOpacity={0.2}
      fillRule="evenodd"
      d="M7.5 6.514a.5.5 0 1 0 1 0v-2.22c0-.273-.227-.5-.5-.5s-.5.227-.5.5v2.22Z"
      clipRule="evenodd"
    />
    <path
      d="M8.013 13.567c-1.72 0-3.433-.273-5.066-.82-.607-.2-1.067-.633-1.267-1.18-.2-.546-.133-1.173.193-1.72l.847-1.413c.187-.314.353-.9.353-1.267v-1.4a4.942 4.942 0 0 1 4.94-4.94 4.942 4.942 0 0 1 4.94 4.94v1.4c0 .36.167.953.354 1.267l.846 1.413c.314.52.367 1.14.16 1.707-.206.566-.66 1-1.233 1.193-1.633.553-3.347.82-5.067.82Zm0-11.733a3.943 3.943 0 0 0-3.94 3.94v1.4c0 .54-.213 1.32-.493 1.78l-.847 1.42c-.173.287-.213.593-.113.86.1.267.327.466.647.573a15.15 15.15 0 0 0 9.506 0 .949.949 0 0 0 .607-.587.974.974 0 0 0-.08-.846l-.847-1.414c-.28-.46-.493-1.24-.493-1.78v-1.4a3.955 3.955 0 0 0-3.947-3.946Z"
    />
    <path
      d="M8 15.267a2.738 2.738 0 0 1-1.92-.8 2.738 2.738 0 0 1-.8-1.92h1A1.727 1.727 0 0 0 8 14.267c.947 0 1.72-.774 1.72-1.72h1c0 1.5-1.22 2.72-2.72 2.72Z"
    />
  </svg>
)
export default SvgComponent
