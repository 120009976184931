import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 16 16",
    }}
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M9.3 9.8c-.1 0-.3 0-.4-.1L7.6 8.4c0-.1-.1-.3-.1-.4V5.3c0-.3.2-.5.5-.5s.5.2.5.5v2.5L9.7 9c.2.2.2.5 0 .7-.1.1-.2.1-.4.1z" />
    <path
      d="M9.3 9.8c-.1 0-.3 0-.4-.1L7.6 8.4c0-.1-.1-.3-.1-.4V5.3c0-.3.2-.5.5-.5s.5.2.5.5v2.5L9.7 9c.2.2.2.5 0 .7-.1.1-.2.1-.4.1z"
      style={{
        opacity: 0.2,
        fill: "#fff",
      }}
    />
    <path d="M1.5 7.3c0 .3.2.5.4.5s.5-.2.5-.4l-.9-.1zm1.3 2.5c-.1-.2-.3-.4-.6-.3-.3.1-.4.4-.3.6l.9-.3zm-1.3 3.5c0 .3.2.5.5.5s.5-.2.5-.5h-1zM2 10v-.5c-.3 0-.5.2-.5.5H2zm3.4.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5v1zM2.5 7.4c.3-2.8 2.6-4.9 5.4-4.9v-1c-3.3 0-6 2.5-6.4 5.8l1 .1zm5.4-5c2.8 0 5.2 2 5.5 4.8l1-.1c-.4-3.2-3.2-5.7-6.5-5.7v1zm5.6 4.9c.3 2.8-1.4 5.4-4.2 6l.2 1c3.2-.8 5.3-3.8 4.9-7.1l-.9.1zm-4.2 6c-2.7.7-5.5-.8-6.5-3.5l-.9.3C3 13.3 6.3 15 9.5 14.3l-.2-1zm-6.8 0V10h-1v3.3h1zM2 10.5h3.3v-1H2v1z" />
    <path
      d="M1.5 7.3c0 .3.2.5.4.5s.5-.2.5-.4l-.9-.1zm1.3 2.5c-.1-.2-.3-.4-.6-.3-.3.1-.4.4-.3.6l.9-.3zm-1.3 3.5c0 .3.2.5.5.5s.5-.2.5-.5h-1zM2 10v-.5c-.3 0-.5.2-.5.5H2zm3.4.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5v1zM2.5 7.4c.3-2.8 2.6-4.9 5.4-4.9v-1c-3.3 0-6 2.5-6.4 5.8l1 .1zm5.4-5c2.8 0 5.2 2 5.5 4.8l1-.1c-.4-3.2-3.2-5.7-6.5-5.7v1zm5.6 4.9c.3 2.8-1.4 5.4-4.2 6l.2 1c3.2-.8 5.3-3.8 4.9-7.1l-.9.1zm-4.2 6c-2.7.7-5.5-.8-6.5-3.5l-.9.3C3 13.3 6.3 15 9.5 14.3l-.2-1zm-6.8 0V10h-1v3.3h1zM2 10.5h3.3v-1H2v1z"
      style={{
        fill: "#fff",
        fillOpacity: 0.2,
      }}
    />
  </svg>
)
export default SvgComponent
