import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Anchor, Tabs, Button,} from "antd";
// import "../../pages/ContestPage/ContestPage.css";
import calender from "../images/calendar.svg";
import watch from "../images/watch.svg";
import { PlusOutlined } from "@ant-design/icons";
import { GetData, PostData } from "../services";
import { EndPoints } from "../endpoint";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function PostSection() {
  const navigate = useNavigate();
  const [postdata, setPostData]=useState([]);
  const [communityDesc, setCommunityListDesc] = useState({});
  const [step,setStep]=useState(0)

    const { Title } = Typography;
   

      useEffect(()=>{
        getUpcomingList();
        // getOngoingList();
        // getCancelList();
      },[])

      const getUpcomingList =async()=>{
        try{
          const res = await GetData(EndPoints.COMMUNITY_LIST + localStorage.getItem("user_id"))
        if(res?.status ==200 && res?.success_status ==true){
          setPostData(res?.data)
          
          // console.log(res,'99999999 couresc rrrrtr')
        }
        else if(res?.status ==200 && res?.success_status ==false){
        //  toast.error(res.message)
        }
        else{
       //   toast.error(res.message)
        }
      }
      catch(err){
     //   toast.error('Something went wrong')
      }
        }

const handleCommunity=(battle)=>{
 const index = postdata.findIndex(el => el.id === battle.id);
  navigate('/CommunityCourse',{state: {data:postdata[index],step:index}})
}

  return (
    <>
          <div className="container">
            {postdata.length != 0 &&
        <div className="innerContestPadd">
                    <div className="sectionHeading text-left ">
                      <Title className="">Communities</Title>
                    </div>
                    <Row gutter={16} align="middle" className="battleCardWrap">
                      {postdata.map((battle) =>{
                        console.log(battle,'batttttttttttle couresee')
                         return(
                          
                        <Col xs={24} sm={24} md={24} lg={6} xl={6} key={""} onClick={()=>{ handleCommunity(battle)}}>
                          <div className="battleCards">
                            <div className="battleImg">
                              <img src={battle?.image} alt="" className="" />
                              <div className="battleName">
                                {/* <span className="battleFirst">{battle?.created_by.first_name} {battle?.created_by.last_name}</span> */}
                              
                              </div>
                            </div>
                            <div className="battleInfo">
                              <Title className="battleDes">
                                {battle?.community_name}
                              </Title>
                              {/* <p className="battle">
                                {battle?.description}
                              </p>
                              <div className="battleTimeline">
                                <span className="">
                                  <img src={calender} />
                                  Dec 2, 2018
                                </span>
                                <span className="">
                                  <img src={watch} />
                                  01:55 pm
                                </span>
                              </div> */}
                             
                            </div>
                          </div>
                        </Col>

                      )})}
                    </Row>
                  </div>
}
        </div>
    </>
  )
}
