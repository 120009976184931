import React, { useState } from "react";
import HeaderTop from "../../components/HeaderTop";
import { Row, Col, Typography, Tabs } from "antd";
import { Layout, Menu, Modal, Form, Input, Anchor, Button, Checkbox, InputNumber, Select, Alert } from 'antd';
import "../../pages/ContestPage/ContestPage.css";
import calender from "../../images/calendar.svg";
import contestGraphic from "../../images/contestGraphic.svg";
import watch from "../../images/watch.svg";
import FooterContent from '../../components/FooterContent';
import { PlusOutlined } from '@ant-design/icons';
import "../ContactPage/ContactUs.css";
import leftwrapimage from "../../images/Image.png";
import { toast } from "react-toastify";
import Validator from "../../Validator";
import { PostData } from "../../services";
import { EndPoints } from "../../endpoint";
import { useNavigate } from "react-router-dom";


const { Title } = Typography;
const { Link } = Anchor;
const { TabPane } = Tabs;

function ContactUs() {
  const MyFormItemContext = React.createContext([]);
  const { Title } = Typography;
  const { Link } = Anchor;
  const navigate = useNavigate();
  const [fName, setFName] = useState('')
  const [lName, setLName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [policy, setPolicy] = useState(false)

  const MyFormItemGroup = ({ prefix, children }) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatPath = React.useMemo(() => [...prefixPath, ...toArr(prefix)], [prefixPath, prefix]);
    return <MyFormItemContext.Provider value={concatPath}>{children}</MyFormItemContext.Provider>;
  };

  function toArr(str) {
    return Array.isArray(str) ? str : [str];
  }
  const MyFormItem = ({ name, ...props }) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatName = name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
    return <Form.Item name={concatName} {...props} />;
  };

  const handleSubmit = async() => {
    if (Validator.isEmpty(fName)) {
      toast.error('Please enter First Name')
    }
    else if (Validator.isEmpty(lName)) {
      toast.error('Please enter Last Name')
    }
    else if (!Validator.isValidEmail(email)) {
      toast.error('Please enter Email')
    }
    else if (Validator.isEmpty(phone)) {
      toast.error('Please enter Phone number')
    }
    else if (phone.length >10) {
      toast.error('Please enter valid Phone number')
    }
    else if (Validator.isEmpty(message)) {
      toast.error('Please enter your message')
    }
    else if (!policy) {
      toast.error('Please checked privacy policy')
    }
    else {
      try {
        let data = {
          "first_name": fName,
          "last_name": lName,
          "email": email,
          "number": phone,
          "query": policy,
          "type":'enquiry'
        }
        console.log(data,'contact us bodyyyyyyyyy')
        const res = await PostData(EndPoints.CONTACT_US, data)
        
        if (res.status == 200 && res?.success_status == true) {
          toast.success(res.message)
          setTimeout(() => {
            navigate('/');
          navigate(0);
          }, "1000");
         
        }
        else if (res.status == 200 && res?.success_status == false) {
          toast.error(res.message)
        }
        else {
          toast.error(res.message)
        }
      }
      catch (err) {
        toast.error('Something went wrong')
      }
    }
  }

    return (
      <>
        <HeaderTop />
        <div className="InnerBannerSection">
          <img src={contestGraphic} className="contestGraphic" alt="" />
          <div className="container">
            <Row gutter={16} align="middle">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="sectionHeading text-center ">
                  <Title className="Contactushead">CONTACT
                    <span className="Contactusheadspan text-white"> US</span>
                  </Title>
                  <p className="smallHeading">
                  🌟 Connect with Us, Craft the Future: Contact Us Today! 🌐🚀
Hey Fantasy Legends! 👋 Ready to take your Fantasy Web App experience to the next level? We're just a click away. </p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="container bgcolor pdb-0 contactPage">
            <Row gutter={25}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="rightWrap rightCheckbox" >
                  <h3 className="head">Get in touch</h3>
                  <p className="para">Our friendly team would love to hear from you.</p>
                  <Form  layout="vertical" className="contactForm" >
                  <Row gutter={25}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                  label="First Name"
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your first name',
                    },
                  ]} >
                  <Input value={fName} onChange={(el) => setFName(el.target.value)} placeholder="Please input your first name" />
                  </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                  label="Last Name"
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your last name',
                    },
                  ]} >
                  <Input value={lName} onChange={(el) => setLName(el.target.value)} placeholder="Please input your last name" />
                  </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'you@company.com',
                    },
                  ]} >
                  <Input value={email} onChange={(el) => setEmail(el.target.value)} placeholder="you@company.com" />
                  </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                  label="Phone Number"
                  name="phonenumber"
                  rules={[
                    {
                      required: true,
                      message: '+1 (555) 000-0000',
                    },
                  ]} >
                  <Input value={phone} onChange={(el) => {setPhone(el.target.value);}} placeholder="+1 (555) 000-0000" />
                  </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                  label="Message"
                  name="Message"
                  rules={[
                    {
                      required: true,
                      message: 'message',
                    },
                  ]} >
                  <Input.TextArea value={message} onChange={(el) => setMessage(el.target.value)} id="" cols="67" rows="6" placeholder="Type message here." />
                  </Form.Item>
                  </Col>
                  </Row>
                  </Form>

                
                  <Row style={{ marginTop: "5px" }}>
                    <Col> <div className="form-check">
                      <Checkbox style={{ marginTop: "-9%" }} checked={policy}
                        onChange={(el) => { setPolicy(!policy) }}
                      >
                        <label className="form-check-label" >
                          You agree to our friendly <a href="#" class="linkprivacy"> privacy policy.</a>
                        </label>
                      </Checkbox>
                    </div></Col>
                  </Row>
                  <br />

                  {/* <div class="d-grid gap-2">
                  <button className="btn btn-primary mybtn" type="button" onChange={() => handleSubmit()}>Send message</button>

                </div> */}

                  <Button className="btn btn-primary contactBtn" onClick={() => handleSubmit()}>Send message</Button>

                </div>

              </Col>

              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div>
                  <img src={leftwrapimage} className="imgFluid" />
                </div>
              </Col>
            </Row>
          </div>


        </div>
        {/* <div style={{margin: "200px", textAlign:"center"}}>
      <h1 style={{fontSize: "80px"}}>COMING SOON........!!</h1>
      </div> */}
        <FooterContent />
      </>
    );
  }

  export default ContactUs;
