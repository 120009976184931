import {
    Anchor,
    Col,
    Form,
    Input,
    Row,
    Typography
  } from "antd";
  import React, { useEffect, useState } from "react";
  import {
    MoreOutlined
  } from '@ant-design/icons';
  import { useLocation, useNavigate } from "react-router-dom";
  import { toast } from "react-toastify";
  import FooterContent from "../../components/FooterContent";
  import HeaderAuthTop from "../../components/HeaderAuthTop";
  import { EndPoints } from "../../endpoint";
  import image1 from "../../images/Image-2.png";
  import verifyicon from "../../images/verify.png";
  import { GetData } from "../../services";
  import "../Profile/UserProfile.css";
  import frame1 from "../../images/Help&center.png"
  import frame2 from "../../images/Help&center2.png"
  import frame3 from "../../images/Help&center3.png"
  import menu from "../../images/menu.png"

  import moment from "moment";
  import { Space, Tag } from 'antd';
  import {
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer, TableHead,
    TableRow,
    Tooltip
  } from '@mui/material';
  
 
  function ParticipateList() {

    console.log()
    const navigate = useNavigate()
    const location =useLocation()
    const [dataList, setDataList] = useState([])
  
  console.log(location.state?.data,'location6666666666')

  useEffect(()=>{
if(location.state?.data){
    let newArr = location.state?.data.map((x, i) => ({
        sr: i + 1,
        contestent_name:`${x?.refferalname?.username} , ${x?.sendername?.username}`,
         amt: `$${x?.entry_fee}`,
        winnerAmt:`$${x?.winner_prize}`,
        ...x
      }));
      setDataList(newArr)
}
  },[])
  
  
    return (
      <>
      <HeaderAuthTop />
        <div className="Profilesection" >
          <div className="container profilewrap" style={{ width: "60%", background: "#fff" }} >
            <Form name="form_item_path" layout="vertical">
              <div className="border" style={{ marginBottom: "20px" }}>
              </div>
              <TableContainer className="tableContainer" style={{ border: "1px solid #E0E7ED", background: "#fff", borderRadius: "3px" }}>
                <h2 className="contesthomeform">Contestent History</h2>
                <Table>
  
                  <TableHead className='tableHeader'>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Contest Name</TableCell>
                      <TableCell>Contestent Name</TableCell>
                      <TableCell>Date Of Participation</TableCell>
                      <TableCell>Contest Time</TableCell>
                      <TableCell>Entry Fee</TableCell>
                      <TableCell>Winning Price</TableCell>
                      <TableCell>Rapper Name 1</TableCell>
                      <TableCell>Rapper Name 2</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataList.map((row) => (
                      <TableRow key={row?.id}>
                        <TableCell>{row?.sr}</TableCell>
                        <TableCell>{row?.contest_name}</TableCell>
                        <TableCell>{row?.contestent_name}</TableCell>
                        <TableCell>{moment(row.date).format('MMM DD, YYYY')}</TableCell>
                        <TableCell>{row.time}</TableCell>
                        <TableCell className='successText'>{row.amt}</TableCell>
                        <TableCell className='successText'>{row.winnerAmt}</TableCell>
                        <TableCell>{row.rapper_name}</TableCell>
                        <TableCell>{row.rapper_name2}</TableCell>
                        <TableCell className='actionWidth' sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                            {/* <div className="icontag" style={{ marginRight: "10%" }} onClick={() => { navigate('/ParticipateList', { state: { data: row.battelsdata} }) }}>
                              <Tooltip title='Edit'>
                                <MoreOutlined style={{ fontSize: "20px" }} />
                              </Tooltip>
                            </div> */}

<div style={{ marginRight: "10%" }} onClick={() => { alert('hii')}}>
                              <Tooltip title='Edit'>
                                <MoreOutlined style={{ fontSize: "20px" }} />
                              </Tooltip>
                            </div>
                            
                          </TableCell>
                      </TableRow>
                    ))}
  
                  </TableBody>
                </Table>
              </TableContainer>

            </Form>
          </div>
        </div>
  
        <FooterContent />
      </>
    );
  }
  
  export default ParticipateList;