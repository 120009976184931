import {
  Col,
  Form,
  Row
} from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FooterContent from "../../components/FooterContent";
import HeaderAuthTop from "../../components/HeaderAuthTop";
import { EndPoints } from "../../endpoint";
import checkimg from "../../images/Check icon.png";
import { GetData } from "../../services";
import "../Profile/UserProfile.css";
import moment from "moment";


function Subscription() {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const location = useLocation();


  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const res = await GetData(EndPoints?.ACTIVE_SUBSCRIPTION + localStorage.getItem("user_id"))
      if (res?.status == 200 && res?.success_status == true) {
        // let newArr = res?.data?.subscriptionList?.map((x, i) => ({
        //   _type: x?.type == 'Monthly' ? 'mth' : x?.type == 'Quarterly' ? 'qtrly' : x?.type == 'Half-yearly' ? 'hf-yr' : 'Anly',
        //   ...x
        // }));
        // setData(newArr)

        if (res?.data?.activeSubscription != null) {
          let newArr = res?.data?.subscriptionList?.map((x, i) => ({
            _type: x?.type == 'Monthly' ? 'mth' : x?.type == 'Quarterly' ? 'qtrly' : x?.type == 'Half-yearly' ? 'hf-yr' : 'Anly',
            subsStatus: x.id == res?.data?.activeSubscription?.sub_id ? 'Active' : '',
            subExpire_date: x.id == res?.data?.activeSubscription?.sub_id ? res?.data?.activeSubscription.expire_date : '',
            ...x
          }));
          setData(newArr)
        }
        else {
          let newArr = res?.data?.subscriptionList?.map((x, i) => ({
            _type: x?.type == 'Monthly' ? 'mth' : x?.type == 'Quarterly' ? 'qtrly' : x?.type == 'Half-yearly' ? 'hf-yr' : 'Anly',
            subsStatus: '',
            subExpire_date: '',
            ...x
          }));
          setData(newArr)
        }

       

      }
      else if (res?.status == 200 && res?.success_status == true) {
        toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    }
    catch (err) {
      console.log(err, 'uhhgjghjbhbvhbvhjbhjbhjb')
      toast.error('Something went wrong')
    }
  }


  const handlePay = (el) => {
    // el.state.type == 'subs'
    navigate('/SaveCards', { state: { step: 2, data: el,type:'subs' } })
  }



  console.log(data, 'data777777777777')

  return (
    <>
      {location?.state?.data == '0' &&
        <HeaderAuthTop />}
      <div className="Profilesection" >
        <div className="container profilewrap" style={{ width: location?.state?.data == '0' ? "60%" : '92%', background: "#fff" }} >
          <Form name="form_item_path" layout="vertical">
            <h2 className="contesthomeform">My Subscription Plan</h2>
            <div className="border"></div>

            <div className="container couponrowrap" style={{ border: "none" }}>
              <Row>
                <Col className="tandcwrap" >
                  <h1 className="subscriptionhead">Pricing Simple, transparent pricing</h1>
                  <p className="subscription-description" >We believe Untitled should be accessible to all companies, no matter the size.</p>

                </Col>
              </Row>
            </div>

            <Row gutter={28}>
              {data.length != 0 ?
                <>
                  {data.map((el) => {
                    return (
                      <Col className="gutter-row" span={8}>
                        <div className={el.subsStatus == 'Active' ? "active-subs-boxwrapper" : "subs-boxwrapper"} >
                          <div class="priceWrap">
                            <h2 className="subs-cardhead">${el?.amount}/{el?.type}</h2>
                            <h3 className="subs-plan">{el?.name}</h3>
                            <h4 className="subs-billed"> Billed {el?.type}</h4>
                            {el?.subExpire_date ?
                              <p>Valid till - {moment(el?.subExpire_date).format('MMM DD, YYYY')}</p> :
                              null}
                          </div>

                          <p dangerouslySetInnerHTML={{ __html: el?.bullets }}></p>
                          {/* <div className="planListData">
                        <div className="battleTimeline sub-list">
                          <span className="">
                            <img src={checkimg} />
                            <p>Access to all basic features</p>
                          </span>
                        </div>


                        <div className="battleTimeline sub-list">
                          <span className="">
                            <img src={checkimg} />
                            <p >Basic reporting and analytics</p>
                          </span>
                        </div>

                        <div className="battleTimeline sub-list">
                          <span className="">
                            <img src={checkimg} />
                            <p >Advanced reporting and analytics</p>
                          </span>
                        </div>
                        <div className="battleTimeline sub-list">
                          <span className="">
                            <img src={checkimg} />
                            <p>Access to all basic features</p>
                          </span>
                        </div>
                        <div className="battleTimeline sub-list">
                          <span className="">
                            <img src={checkimg} />
                            <p>Access to all basic features</p>
                          </span>
                        </div>
                      </div> */}

                          <br />
                          <button disabled={el.subsStatus == 'Active' ? true : false} className={el.subsStatus == 'Active' ? "Activesub-btn" : "sub-btn"} onClick={() => { handlePay(el) }}>{el.subsStatus == 'Active' ? 'Active' : 'Get started'}</button>
                        </div>

                      </Col>
                    )
                  })}
                </> :
                <p style={{ textAlign: "center", fontSize: "15px", color: "red" }}>There is no package avilable.</p>
              }

            </Row>


          </Form>
        </div>

      </div>
      {location?.state?.data == '0' &&
        <FooterContent />}
    </>
  );
}

export default Subscription;