import React, { useContext, useState } from 'react';
import { Layout, Menu, Row, Col, Typography, Modal, Form, Input, Anchor, Button, Checkbox, InputNumber, Select, Alert } from 'antd';
import logo from '../images/fantasy-logo.svg';
import blacklogo from '../images/blacklogo.svg';
import Validator from '../Validator';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PostData } from '../services';
import { useNavigate, useNavigation } from 'react-router-dom';
import { EndPoints } from '../endpoint';
import { AuthContext } from '../App';
import LogIn from './LogIn';

const MyFormItemContext = React.createContext([]);
const { Title } = Typography;
const { Link } = Anchor;

const MyFormItemGroup = ({ prefix, children }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(() => [...prefixPath, ...toArr(prefix)], [prefixPath, prefix]);
  return <MyFormItemContext.Provider value={concatPath}>{children}</MyFormItemContext.Provider>;
};

function toArr(str) {
  return Array.isArray(str) ? str : [str];
}
const MyFormItem = ({ name, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName = name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
  return <Form.Item name={concatName} {...props} />;
};

function SignUp() {
  const navigate = useNavigate();
  const [isModalLoginOpen, setIsModalLoginOpen] = useState(false);
  const [isModasignuplOpen, setIsModalSignupOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("")
  const [password, setPassword] = useState("")
  const [code, setCode] = useState("")
  const [terms, setTerms] = useState(false)
  const [isAuth, setIsAuth] = useContext(AuthContext)

  


  const showModal = () => {
    setIsModalLoginOpen(true);
  };
  const handleOk = () => {
    setIsModalLoginOpen(false);
  };
  const handleCancel = () => {
    setIsModalLoginOpen(false);
  };
  const onFinish = (value) => {
  };
  const showModalsignup = () => {
    setIsModalSignupOpen(true);
  };
  const handleOksignup = () => {
    setIsModalSignupOpen(false);
  };
  const handleCancelsignup = () => {
    setIsModalSignupOpen(false);
  };

  const handleSignup = async () => {
    try {
      if (!Validator.isValidEmail(email)) {
        toast.error('Please enter your valid email');
      }
      else if (Validator.isEmpty(userName)) {
        toast.error('Please enter your User Name');
      }
      else if (Validator.isEmpty(age)) {
        toast.error('Please enter your age');
      }
      else if (Validator.isEmpty(gender)) {
        toast.error('Please enter your Gender');
      }
      else if (Validator.isEmpty(password)) {
        toast.error('Please enter your Password');
      }
      else if (terms == false){
        toast.error('Please mark checkbox ');
      }
      else {
        let data = {
          "email": email.toLocaleLowerCase(),
          "username": userName,
          "age": age,
          "gender": gender,
          "password": password,
          "referral_code": code
        }
        let res = await PostData(EndPoints.SIGNUP,data)
        if (res.status == 200 && res.success_status == true) {
          toast.success(res?.message);
          navigate("/"); 
          // setIsModalSignupOpen(false)
          setIsModalLoginOpen(true)
          //  navigate("/"); 
          // localStorage.setItem("user_id", res?.user?.id);
          // localStorage.setItem("access_token", res?.access_token);
          // localStorage.setItem("proImg",res?.user?.image?res?.user?.image:'');
          // setIsAuth(true)
          // navigate('/');
        }
        else if (res.status == 200 && res.success_status == false) {
          toast.error(res?.message);
        }
        else {
          toast.error(res?.message);
        }
      }
    }
    catch (err) {
      toast.error('Something went wrong');
    }
  }

  const [isChecked, setIsChecked] = useState(false);
   
  
 
  return (

    // <Layout>
    <div className='loginModal'>
      <div className='text-center'>

        <img src={blacklogo} alt="logo" />
        <Title className='modalHeading' style={{textAlign:"left"}}>Create An Account</Title>
        {/* <p className='ModalSmallHeading'>Welcome back! Please enter your details.</p> */}

      </div>
      <Form name="form_item_path" layout="vertical" onFinish={onFinish}>
        <MyFormItemGroup prefix={['user']}>
          <MyFormItemGroup prefix={['name']}>
            <MyFormItem name="email" label="Email"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
            >
              <Input onChange={(e) => setEmail(e.target.value)} size='large'/>
            </MyFormItem>
            <MyFormItem name="name" label="Name"
              rules={[
                {
                  required: true,
                  message: 'Please input your name',
                },
              ]}>
              <Input onChange={(e) => setUserName(e.target.value)} size='large' />
            </MyFormItem>
            <Row>
              <Col span={11} style={{ marginRight: "7%" }}>
                <MyFormItem label="Age"
                  rules={[
                    {
                      type: 'text',
                      message: 'The input is not valid',
                    },
                    {
                      required: true,
                      message: 'Please input your age',
                    },
                  ]}
                >
                  <Input type='text' onChange={(e) => setAge(e.target.value)} size='large' maxLength={2}/>
                </MyFormItem>
              </Col>
              <Col span={11}>
                <Form.Item label="Gender"
                  rules={[
                    {
                      required: true,
                      message: 'Gender',
                    },
                  ]}>
                  <Select onChange={(el) => setGender(el)} size='large'>
                    <Select.Option value="Male">Male</Select.Option>
                    <Select.Option value="Female">Female</Select.Option>
                    {/* <Select.Option value="Others">Others</Select.Option> */}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <MyFormItem name="password" label="Password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}>
              <Input.Password onChange={(e) => setPassword(e.target.value)} size='large' />
            </MyFormItem>
            {/* <MyFormItem name="text" label="Enter Referral Code (Optional)">
              <Input onChange={(e) => setCode(e.target.value)} size='large' />
            </MyFormItem> */}


            <MyFormItem className="forgotLinkWrap">
             <input size={20} type='checkbox' value={terms} onChange={(el)=>setTerms(!terms)}></input>
             <p style={{ marginLeft: "2%" }}>Please mark tick here. Once you tick which means you have accepted the <a href='/TermsandConditions'>Terms of Use </a> and <a href='/privacypolicy'>Privacy & Policy</a></p>
            </MyFormItem>
          </MyFormItemGroup>
        </MyFormItemGroup>

        <Button type="primary" htmlType="submit" className='loginBtnModal' onClick={() => handleSignup()}> Sign Up</Button>
      </Form>
       
      <Modal title="" className="loginModal" open={isModalLoginOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <LogIn />
        <div className='dontAccountWrap'>
          <span className='dontText'>Don’t have an account?</span>  <div onClick={() => { setIsModalSignupOpen(true); setIsModalLoginOpen(false) }} className="notSignLink">Sign up</div>
        </div>
      </Modal>
    </div>
    // </Layout>
  );
}

export default SignUp;  