import { Col, Modal, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import Joincontestimg from "../../images/Google Ads.png";
import time from "../../images/clock.png";
import date from "../../images/date.png";
import LeftRightArrow from "../../images/left-rightarrow.png";
import "../../pages/ContestPage/ContestPage.css";

import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EndPoints } from "../../endpoint";
import proImg from '../../images/pro.png';
import { GetData, PostData } from "../../services";
import Socialshare from "./SocialSharePage";
import { AuthContext } from "../../App";


export default function JoinBattleContestent() {
  const navigate = useNavigate()
  const location = useLocation()
  const [data, setData] = useState('')
  const [isModalLoginOpen, setIsModalLoginOpen] = useState(false);
  const [senderPerson, setSenderPerson] = useState(0);
  const [reciverPerson, setReciverPerson] = useState();
  const [battle, setBattle] = useState(false)
  const [isAuth, setIsAuth] = useContext(AuthContext)
  const [ad,setAd]=useState('')






  const showModal = () => {
    setIsModalLoginOpen(true);
  };
  const handleOk = () => {
    setIsModalLoginOpen(false);
  };
  const handleCancel = () => {
    setIsModalLoginOpen(false);
  };

 

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      const url = window?.location?.href;
      const regex = /senderPerson=(\d+)/;
      const match = url?.match(regex);

      if (match) {
        const senderPerson = match[1];
        setSenderPerson(senderPerson)
        setReciverPerson(senderPerson == 0 ? 1 : 0)
        // Now you have the senderPerson value
      }
    }
  }, []);

  useEffect(() => {

    if (localStorage.getItem("access_token")) {
      if (location?.state?.data?.id) {
        getBattleDetails()
      }
      else if (/contest_id=([^&]+)&sender=([^&]+)/.exec(window?.location?.href)[1]) {
        getUrlDetails()
      }
    }
    else {
      //localStorage.setItem("temp_token", window?.location?.href);
      navigate('/');
    }
  }, [])

  const getBattleDetails = async () => {
    try {
      console.log(EndPoints.CONTEXT_DETAILS + `${location?.state?.data?.id}&user_id=${localStorage.getItem("user_id")}`,'00008776655')
      const res = await GetData(EndPoints.CONTEXT_DETAILS + `${location?.state?.data?.id}&user_id=${localStorage.getItem("user_id")}`)
      if (res?.status == 200 && res?.success_status == true) {
        setData(res.data[0])
      }
      else if (res?.status == 200 && res?.success_status == true) {
        toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    }
    catch (err) {
      toast.error('Somrthing went wrong');
    }
  }

  const getUrlDetails = async () => {
    try {
      const res = await GetData(EndPoints.CONTEXT_DETAILS + `${/contest_id=([^&]+)&sender=([^&]+)/.exec(window.location.href)[1]}&user_id=${/contest_id=([^&]+)&sender=([^&]+)/.exec(window.location.href)[2]}`)
      if (res?.status == 200 && res?.success_status == true) {
        setData(res.data[0])
      }
      else if (res?.status == 200 && res?.success_status == true) {
        toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    }
    catch (err) {
      toast.error('Something went wrong');
    }
  }

  useEffect(()=>{
    console.log(data.user_rapper,'ihhjhkhkhkhhkhhkdataatatatatatatat=============================')
    if(location?.state?.data?.id && data?.invite_status_acceptornot == true && data?.finaljoinstatus == false){
      // setReciverPerson(senderPerson == 0 ? 1 : 0)
      setSenderPerson(data.user_rapper == 'rapper_name'?1:0)
    }
    },[data])

  const handleSendApi = async () => {
    try {
      const res = await PostData(EndPoints.INVITATION_SEND + `${data?.id}&user_id=${localStorage.getItem("user_id")}`)
      if (res.status == 200 && res?.success_status == true) {

      }
      else if (res.status == 200 && res?.success_status == false) {
        // toast.error(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    }
    catch (err) {
      toast.error('Smething went wrong')
    }
  }


  const handleReciverPay = (data) => {
    data.payType = 'reciverPay'
    data.senderId = `${/contest_id=([^&]+)&sender=([^&]+)/.exec(window.location.href)[2]}`
    data.rapperPlace = `${/senderPerson=(\d+)/.exec(window.location.href)[1]}`
    navigate('/SaveCards', { state: { step: 2, data: data } })
  }

  const handleSenderPay = (data) => {
    navigate('/SaveCards', { state: { step: 2, data: data, type: 'senderPay', rapperPlace: senderPerson } })
  }

  const handleReciver=(val)=>{
    if(val == senderPerson){
      alert('This rapper Already choosen')
    }
    else{
      setSenderPerson(val)
      // setReciverPerson(val)
    }
      }

      const handleSender=(val)=>{
        if(data?.invite_status_acceptornot == false){
        setSenderPerson(val)
        }
      }

      useEffect(()=>{
        getAdv()
      },[])
      
      const getAdv=async()=>{
        try {
          const res = await PostData(EndPoints.CUSTOM_BANNER + 'Contest')
          if (res?.status == 200 && res?.success_status == true) {
            setAd(res.data[0])
          
          }
          else if (res?.status == 200 && res?.success_status == false) {
            // toast.error(res.message)
          }
          else {
            //  toast.error(res.message)
          }
        }
        catch (err) {
          //  toast.error('Something went wrong')
        }
      }

  return (
    <div className="container joinbattlesection">
      <div style={{ paddingBottom: 10 }}>
        <img src={data?.banner_image} alt="" className="" style={{ height: '270px', width: '100%', }} />
      </div>

      <Row xs={4} sm={2} md={2} lg={24} xl={24}>
        <Col span={18} push={0} className="leftwrap">
          <Row>
            <div className="leftwrapinner">
              <h3 className="heading" style={{ flex: 1 }}>{data?.contest_name}</h3>
              <p className="time"><img style={{ marginRight: "10px" }} src={time} />Time:- {data?.contest_time} onwards</p>
              <p className="time"><img style={{ marginRight: "10px" }} src={date} />Date:- {moment(data.contest_date).format('MMM DD, YYYY')}</p>
            </div>

          </Row>
          <div className="contestdescriptionwrap">
            <p>Organised by:- {data?.ContestCreatorName}</p>
            <p >Entry Fess:- ${data?.entry_fee}</p>
            <p >Winning Price:- ${data?.winner_prize}</p>
            <h4>Note:- You can join the contest only when you have a partner to join the contest</h4>
            {/* <h5 className="invitenote">Arihant has invited the Friend and both are joining click to join the contest</h5> */}
           
         {!location?.state?.data?.id && data?.invite_status_acceptornot == false ?
            <div className="contestentselector contentName">
              <ul>
                <li>
                  <img onClick={() => handleReciver(0)} src={data?.rapper_image ? data?.rapper_image : proImg} style={{ width: '90px', height: '90px', borderRadius: '70px', padding: '7px', border: reciverPerson == 0 ? '3px solid #428DFF' : '3px solid transparent' }} />
                
                  <p>{data?.rapper_name}</p>
                </li>
                <li style={{ margin: " 0px 50px", marginTop: -100 }}>
                  <img src={LeftRightArrow} style={{ position: 'relative', top: '-26px', width: '70px' }} />
                </li>
                <li>
                  <img onClick={() => handleReciver(1)} src={data?.rapper_image2 ? data?.rapper_image2 : proImg} style={{ width: '90px', height: '90px', borderRadius: '70px', padding: '7px', border: reciverPerson == 1 ? '3px solid #428DFF' : '3px solid transparent' }} />
                  <p>{data?.rapper_name2}</p>
                </li>
              </ul>
            </div>
            :
            <div className="contestentselector contentName">
            <ul>
              <li>
                <img onClick={() => handleSender(0)} src={data?.rapper_image ? data?.rapper_image : proImg} style={{ width: '90px', height: '90px', borderRadius: '70px', padding: '7px', border: senderPerson == 0 ? '3px solid #428DFF' : '3px solid transparent' }} />
              
                <p>{data?.rapper_name}</p>
              </li>
              <li style={{ margin: " 0px 50px", marginTop: -100 }}>
                <img src={LeftRightArrow} style={{ position: 'relative', top: '-26px', width: '70px' }} />
              </li>
              <li>
                <img onClick={() =>handleSender(1)} src={data?.rapper_image2 ? data?.rapper_image2 : proImg} style={{ width: '90px', height: '90px', borderRadius: '70px', padding: '7px', border: senderPerson == 1 ? '3px solid #428DFF' : '3px solid transparent' }} />
                <p>{data?.rapper_name2}</p>
              </li>
            </ul>
          </div>}

            <Modal title="Invite Friend or Partner to Join the contest" className="inviteusermodal" open={isModalLoginOpen} centered onOk={handleOk} onCancel={handleCancel} footer={null}>
              <Socialshare data={data} senderPerson={senderPerson} />
            </Modal>

            {location?.state?.data?.id && data?.invite_status_acceptornot == false && data?.finaljoinstatus == false &&
              <div className="joinbtnwrapper" style={{ margin: "20px 0px" }}>
                <a onClick={() => { showModal(); handleSendApi() }} className="joincontestbtn" >Invite Friend</a>
              </div>
            }

            {location?.state?.data?.id && data?.invite_status_acceptornot == true && data?.finaljoinstatus == false &&
              <div className="joinbtnwrapper" style={{ margin: "20px 0px" }}>
                <a onClick={() => { handleSenderPay(data) }} className="joincontestbtn" >Sender Pay</a>
              </div>
            }

            {!location?.state?.data?.id && data?.invite_status_acceptornot == false &&
              <div className="joinbtnwrapper" style={{ margin: "20px 0px" }}>
                <a onClick={() => { handleReciverPay(data) }} className="joincontestbtn" >Reciver Pay</a>
              </div>
            }
          </div>

        </Col>
        <Col xs={4} sm={2} md={2} lg={4} xl={4} className="rightwrap">

<div><a href={ad.link} target="blank">
            <img src={ad.image} />
          </a>
          </div>
        </Col>
      </Row>
    </div>
  )
}