import { Anchor, Button, Col, Form, Input, List, Row, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Validator from "../../Validator";
import FooterContent from "../../components/FooterContent";
import HeaderAuthTop from "../../components/HeaderAuthTop";
import { EndPoints } from "../../endpoint";
import { GetData, PostData } from "../../services";
import "../Profile/UserProfile.css";
import MasterData from "../../MasterData";

import CouponCode from "../Profile/CouponCode";
import SaveCards from "../Profile/SaveCards";
import PaymentHistory from "./PaymentHistory";
import ContestentHistory from "./ContestentHistory";
import ContestList from "../CreateContest/ContestList";
import NotificationsSetting from "./NotificationsSetting";
import HelpandCenter from "./HelpCenter";
import FAQ from "./FAQ";
import Subscription from "./Subscription";

import TermsCondition from "./Termsandcondition";
import PrivacyPolicy from "./PrivacyPolicy";
import { AuthContext } from "../../App";

import ProfileSideBarLight from "../../images/profileSideBarLight.png";
import { colors } from "@mui/material";

const MyFormItemContext = React.createContext([]);

const { Link } = Anchor;

const MyFormItemGroup = ({ prefix, children }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(
    () => [...prefixPath, ...toArr(prefix)],
    [prefixPath, prefix]
  );
  return (
    <MyFormItemContext.Provider value={concatPath}>
      {children}
    </MyFormItemContext.Provider>
  );
};

function toArr(str) {
  return Array.isArray(str) ? str : [str];
}
const MyFormItem = ({ name, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
  return <Form.Item name={concatName} {...props} />;
};

function UserProfile() {
  const navigate = useNavigate();
  const location = useLocation();
  const [fName, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [img, setImg] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [pin, setPin] = useState("");
  const [fb, setFb] = useState("");
  const [twiter, setTwiter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [youtube, setYoutube] = useState("");
  const [isAuth, setIsAuth] = useContext(AuthContext);
  const [communityList, setCommunityList] = useState(MasterData.SideBarList);
  const [steper, setSteper] = useState(1);

  const [personalInformation, setPersonalInformation] = useState({
    fName: "",
    lname: "",
  });

  // console.log(location?.state?.step, 'steeeeeeeeeeeeee')
  // useEffect(() => {
  //   if (location?.state?.step) {
  //     alert('hllo')

  //     setSteper(3)
  //     // getUser()
  //   }
  //   else {
  //     setSteper(1)
  //     alert('hiiii')
  //     // getUser()
  //   }
  // }, [])

  // console.log(steper, 'steper88888888')

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      // const res = await GetData(EndPoints.GET_USER + 17)
      console.log(EndPoints.GET_USER + localStorage.getItem("user_id"),'000989uuuuuuuuuuuu')
      const res = await GetData(
        EndPoints.GET_USER + localStorage.getItem("user_id")
      );
      if (res.status == 200 && res.success_status == true) {
        const showData = res?.data;
        setPersonalInformation({
          fName: showData.first_name ? showData.first_name : "",
        });

        console.log(res?.data, "datatatattatata profile");
        setFName(
          res?.data?.first_name ? res?.data?.first_name : res?.data?.username
        );
        setLName(res?.data?.last_name ? res?.data?.last_name : "");
        setEmail(res?.data?.email ? res?.data?.email : "");
        setPhone(res?.data?.number ? res?.data?.number : "");
        setImg(res?.data?.image ? res?.data?.image : "");
        setCountry(res?.data?.country ? res?.data?.country : "");
        setCity(res?.data?.city ? res?.data?.city : "");
        setAddress(res?.data?.address ? res?.data?.address : "");
        setPin(res?.data?.ZIP ? res?.data?.ZIP : "");
        setFb(res?.data?.facebook_link ? res?.data?.facebook_link : "");
        setTwiter(res?.data?.twitter_link ? res?.data?.twitter_link : "");
        setLinkedin(res?.data?.linkdin_link ? res?.data?.linkdin_link : "");
        setYoutube(res?.data?.youtube_link ? res?.data?.youtube_link : "");
      } else if (res.status == 200 && res.success_status == false) {
        toast.error(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  const handleSubmit = async () => {
    if (Validator.isEmpty(fName)) {
      toast.error("Please enter your first name");
    } else if (Validator.isEmpty(lname)) {
      toast.error("Please enter your last name");
    } else if (Validator.isEmpty(phone)) {
      toast.error("Please enter your phone number");
    } else if (Validator.isEmpty(img)) {
      toast.error("Please enter your profile image");
    } else if (Validator.isEmpty(country)) {
      toast.error("Please enter your country");
    } else if (Validator.isEmpty(city)) {
      toast.error("Please enter your city");
    } else if (Validator.isEmpty(address)) {
      toast.error("Please enter your address");
    } else if (Validator.isEmpty(pin)) {
      toast.error("Please enter your postal code");
    } else {
      try {
        const formData = new FormData();
        formData.append("first_name", fName ? fName : "");
        formData.append("last_name", lname ? lname : "");
        formData.append("email", email ? email : "");
        formData.append("number", phone ? phone : "");
        formData.append("country", country ? country : "");
        formData.append("city", city ? city : "");
        formData.append("zip", pin ? pin : "");
        formData.append("address", address ? address : "");
        formData.append("image", img ? img : "");
        formData.append("facebook_link", fb ? fb : "");
        formData.append("twitter_link", twiter ? twiter : "");
        formData.append("linkdin_link", linkedin ? linkedin : "");
        formData.append("youtube_link", youtube ? youtube : "");
        formData.append("user_id", localStorage.getItem("user_id"));
        let res = await PostData(EndPoints.UPDATE_USER, formData, "1");
        if (res?.status == 200 && res?.success_status == true) {
          console.log(res, "datajhkhj");
          await localStorage.setItem("proImg", res?.image);
          getUser();
          toast.success(res?.message);

          navigate("/UserProfile");
          navigate(0);
        } else if (res?.status == 200 && res?.success_status == true) {
          toast.error(res?.message);
        } else {
          toast.error(res?.message);
        }
      } catch (err) {
        toast.error("Something went wrong");
      }
    }
  };

  const handleLogOut = () => {
    localStorage.setItem("user_id", "");
    localStorage.setItem("access_token", "");
    localStorage.setItem("proImg", "");
    setIsAuth(false);
    navigate("/");
    navigate(0);
  };

  return (
    <>
      <HeaderAuthTop />
      <div className="Profilesection">
        <div className="container ">
          <Row gutter={20} style={{ paddingTop: "20px" }}>
            <Col xl={5} md={5} lg={5} xs={5}>
              <div className="communityProfileLink">
                <List
                  size="small"
                  bordered
                  dataSource={communityList}
                  renderItem={(item, index) => {
                    const backgroundColor = item.id === steper ? true : false;

                    return (
                      <List.Item
                        className={
                          backgroundColor ? "withActiveBG" : "withoutActiveBG"
                        }
                        onClick={() => setSteper(item.id)}
                      >
                        <Link>
                          {item.icon}
                          <span>{item.name}</span>
                        </Link>
                      </List.Item>
                    );
                  }}
                />
              </div>
            </Col>
            <Col xl={19} md={19} lg={19} xs={19}>
              {steper === 1 ? (
                <div className="profilewrap" style={{ background: "#fff" }}>
                  <h2 className="contesthomeform">Personal Information's</h2>
                  <div className="border"></div>
                  <MyFormItemGroup prefix={["user"]}>
                    <MyFormItemGroup prefix={["name"]}>
                      <Form layout="vertical" className="personalInfoUser">
                        <Row gutter={25}>
                          {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                              label="First name"
                              name="firstname"
                              rules={[
                                {
                                  required: false,
                                  message: 'Please input your first name',
                                },
                              ]} >
                              <Input  value={personalInformation?.fName} />
                            </Form.Item>
                          </Col> */}

                          <Col xl={12} md={12} lg={12} xs={24}>
                          <div className="fieldWrappeer">
                            <label style={{ color: "#070c20b3" }}>
                              First Name{" "}
                              <strong style={{ color: "red" }}>*</strong>
                            </label>
                            <input
                              size="large"
                              className="Inputcolor"
                              value={fName}
                              style={{ marginTop: "7px" }}
                              onChange={(e) => setFName(e.target.value)}
                            />
                            </div>
                          </Col>
                          <Col xl={12} md={12} lg={12} xs={24}>
                            <div className="fieldWrappeer">
                              <label style={{ color: "#070c20b3" }}>
                                Last name{" "}
                                <strong style={{ color: "red" }}>*</strong>
                              </label>
                              <input
                                size="large"
                                className="Inputcolor"
                                value={lname}
                                style={{ marginTop: "7px" }}
                                onChange={(e) => setLName(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row gutter={25}>
                          <Col xl={12} md={12} lg={12} xs={24}>
                            <div className="fieldWrappeer">
                              <label style={{ color: "#070c20b3" }}>
                                E-Mail Address{" "}
                                <strong style={{ color: "red" }}>*</strong>
                              </label>
                              <input
                                disabled="true"
                                size="large"
                                className="Inputcolor"
                                value={email}
                                style={{ marginTop: "7px" }}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col xl={12} md={12} lg={12} xs={24}>
                            <div className="fieldWrappeer">
                              <label style={{ color: "#070c20b3" }}>
                                Mobile Number{" "}
                                <strong style={{ color: "red" }}>*</strong>
                              </label>
                              <input
                                type="number"
                                className="Inputcolor"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                style={{ marginTop: "7px" }}
                                size="large"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row gutter={25}>
                          <Col xl={24} md={24} lg={24} xs={24}>
                            <label style={{ color: "#070c20b3" }}>
                              Profile Image{" "}
                              <strong style={{ color: "red" }}>*</strong>
                            </label>
                            <input
                              className="Inputcolor"
                              fullWidth
                              size="large"
                              required="required"
                              accept="image/*"
                              value={img?.image}
                              onChange={(e) => setImg(e.target.files[0])}
                              id="outlined-basic"
                              variant="outlined"
                              type="file"
                              style={{ marginTop: "7px" }}
                              inputProps={{
                                multiple: false,
                              }}
                            />
                          </Col>
                        </Row>
                        <h2 className="contesthomeform">Personal Address</h2>
                        <div className="border"></div>

                        <Row gutter={25}>
                          <Col xl={12} md={12} lg={12} xs={24}>
                            <div className="fieldWrappeer">
                              <label style={{ color: "#070c20b3" }}>
                                Country or Region{" "}
                                <strong style={{ color: "red" }}>*</strong>
                              </label>
                              <input
                                className="Inputcolor"
                                size="large"
                                // defaultValue={contextName}
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col xl={12} md={12} lg={12} xs={24}>
                            <div className="fieldWrappeer">
                              <label style={{ color: "#070c20b3" }}>
                                City <strong style={{ color: "red" }}>*</strong>
                              </label>
                              <input
                                className="Inputcolor"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                //  onChange={(e) => setAge(e.target.value)}
                                size="large"
                              />
                            </div>

                            {/* <MyFormItem name="City" label="City" >
                
                  <Input
                    className="Inputcolor"
                    size="large"
                    // defaultValue={contextName}
                    value={city}
                    onChange={(e) => setContextName(e.target.value)}
                  />
                </MyFormItem> */}
                          </Col>
                        </Row>
                        <Row gutter={25}>
                          <Col xl={12} md={12} lg={12} xs={24}>
                            <div className="fieldWrappeer">
                              <label>
                                Address{" "}
                                <strong style={{ color: "red" }}>*</strong>
                              </label>
                              <input
                                className="Inputcolor"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                //  onChange={(e) => setAge(e.target.value)}
                                size="large"
                              />
                            </div>
                            {/* <MyFormItem name="address" label="Address"

                >
                  <input
                    //  onChange={(e) => setEmail(e.target.value)}
                    value={address}
                    size='large' className="Inputcolor" />
                </MyFormItem> */}
                          </Col>
                          <Col xl={12} md={12} lg={12} xs={24}>
                            <div className="fieldWrappeer">
                              <label>
                                Postal Code{" "}
                                <strong style={{ color: "red" }}>*</strong>
                              </label>
                              <input
                                type="number"
                                className="Inputcolor"
                                onChange={(e) => setPin(e.target.value)}
                                value={pin}
                                size="large"
                              />
                            </div>
                            {/* <MyFormItem label="Postal Code"
                  rules={[{ type: 'number', warningOnly: true }, { type: 'number', min: 6 }]}
                >
                  <input type='number'
                    className="Inputcolor"
                    value={pin}
                    //  onChange={(e) => setAge(e.target.value)}
                    size='large' />
                </MyFormItem> */}
                          </Col>
                        </Row>
                        <h2 className="contesthomeform">Social Account Link</h2>
                        <div className="border"></div>
                        <Row gutter={25}>
                          <Col xl={12} md={12} lg={12} xs={24}>
                            <div className="fieldWrappeer">
                              <label>Facebook</label>
                              <input
                                placeholder="https://www.facebook.com/"
                                className="Inputcolor"
                                size="large"
                                value={fb}
                                onChange={(e) => setFb(e.target.value)}
                              />
                            </div>
                            {/* <Form.Item
                  name="Facebook"
                  label="Facebook"
                  rules={[{ type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
                >
                  <input placeholder="https://www.facebook.com/"
                    className="Inputcolor" size="large" value={fb} />
                </Form.Item> */}
                          </Col>
                          <Col xl={12} md={12} lg={12} xs={24}>
                            <div className="fieldWrappeer">
                              <label>Twitter</label>
                              <input
                                placeholder="https://www.Twitter.com/  "
                                className="Inputcolor"
                                size="large"
                                value={twiter}
                                onChange={(e) => setTwiter(e.target.value)}
                              />
                            </div>
                            {/* <Form.Item
                  name="Twitter"
                  label="Twitter"
                  rules={[{ type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
                >
                  <input placeholder="https://www.Twitter.com/  "
                    className="Inputcolor" size="large" value={twiter} />
                </Form.Item> */}
                          </Col>
                        </Row>
                        <Row gutter={25}>
                          <Col xl={12} md={12} lg={12} xs={24}>
                            <div className="fieldWrappeer">
                              <label>Linkedin</label>
                              <input
                                placeholder="https://www.Linkedin.com/"
                                className="Inputcolor"
                                size="large"
                                value={linkedin}
                                onChange={(e) => setLinkedin(e.target.value)}
                              />
                            </div>
                            {/* <Form.Item
                  name="Linkedin"
                  label="Linkedin"
                  rules={[{ type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
                >
                  <input placeholder="https://www.Linkedin.com/"
                    className="Inputcolor" size="large" value={linkedin} />
                </Form.Item> */}
                          </Col>
                          <Col xl={12} md={12} lg={12} xs={24}>
                            <div className="fieldWrappeer">
                              <label>Youtube</label>
                              <input
                                placeholder="https://www.Youtube.com/"
                                className="Inputcolor"
                                size="large"
                                value={youtube}
                                onChange={(e) => setYoutube(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl={24} md={24} lg={24} xs={24}>
                            <div
                              style={{
                                marginTop: "10px",
                              }}
                            >
                              <button
                                onClick={() => handleSubmit()}
                                className="btnsubmit"
                                //  style={{ padding: " 0px 30px", fontSize: "18px", fontWeight: "500" }}
                              >
                                Save Profile
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </MyFormItemGroup>
                  </MyFormItemGroup>
                </div>
              ) : steper == 2 ? (
                <SaveCards />
              ) : steper == 3 ? (
                <CouponCode />
              ) : steper == 4 ? (
                <PaymentHistory />
              ) : steper == 5 ? (
                <ContestList />
              ) : steper == 6 ? (
                <ContestentHistory />
              ) : steper == 7 ? (
                <NotificationsSetting />
              ) : steper == 8 ? (
                <HelpandCenter />
              ) : steper == 9 ? (
                <FAQ />
              ) : steper == 10 ? (
                <Subscription />
              ) : steper == 11 ? (
                <TermsCondition />
              ) : steper == 12 ? (
                <PrivacyPolicy />
              ) : (
                handleLogOut()
              )}
            </Col>
          </Row>
        </div>
      </div>

      <FooterContent />
    </>
  );
}

export default UserProfile;
