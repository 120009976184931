import {
    Anchor,
    Col,
    Form,
    Input,
    Row,
    Typography
  } from "antd";
  import React, { useEffect, useState } from "react";
  import { useNavigate } from "react-router-dom";
  import { toast } from "react-toastify";
  import FooterContent from "../../components/FooterContent";
  import HeaderAuthTop from "../../components/HeaderAuthTop";
  import { EndPoints } from "../../endpoint";
  import image1 from "../../images/Image-2.png";
  import verifyicon from "../../images/verify.png";
  import { GetData } from "../../services";
  import "../Profile/UserProfile.css";
  import Accordion from "./Accordian";
  
  const MyFormItemContext = React.createContext([]);
  
  const { Title } = Typography;
  const { Link } = Anchor;
  const { TextArea } = Input;
  
  const MyFormItemGroup = ({ prefix, children }) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatPath = React.useMemo(
      () => [...prefixPath, ...toArr(prefix)],
      [prefixPath, prefix]
    );
    return (
      <MyFormItemContext.Provider value={concatPath}>
        {children}
      </MyFormItemContext.Provider>
    );
  };
  
  function toArr(str) {
    return Array.isArray(str) ? str : [str];
  }
  const MyFormItem = ({ name, ...props }) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatName =
      name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
    return <Form.Item name={concatName} {...props} />;
  };
  
  function FAQ() {
    const navigate = useNavigate()
    const [data, setData] = useState([])
  
    // useEffect(() => {
    //   getData()
    // }, [])
  
    // const getData = async () => {
    //   try {
    //     const res = await GetData(EndPoints?.COUPON_LIST)
    //     if (res?.status == 200 && res?.success_status == true) {
    //       setData(res?.data)
    //     }
    //     else if (res?.status == 200 && res?.success_status == true) {
    //       toast.error(res?.message)
    //     }
    //     else {
    //       toast.error(res?.message)
    //     }
    //   }
    //   catch (err) {
    //     toast.error('Something went wrong')
    //   }
    // }
  
    return (
      <>
        {/* <HeaderAuthTop /> */}
        <div className="Profilesection" >
          <div className="container profilewrap" style={{ width: "92%", background: "#fff" }} >
            <Form name="form_item_path" layout="vertical">
              <h2 className="contesthomeform" >Frequently asked questions</h2>
              <div className="border" ></div>
              <Accordion/>
            </Form>
          </div>
        </div>

      </>
    );
  }
  
  export default FAQ;