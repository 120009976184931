import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 14.9 12.2",
    }}
    viewBox="0 0 14.9 12.2"
    {...props}
  >
    <path d="M10.8 11.9H4.1C1.2 11.9.3 11 .3 8.1v-.3c0-.3.2-.5.5-.5.6 0 1.2-.6 1.2-1.2S1.4 4.9.8 4.9c-.3 0-.5-.2-.5-.5v-.3C.3 1.2 1.2.3 4.1.3h6.7c2.9 0 3.8.9 3.8 3.8v.7c0 .3-.2.5-.5.5-.6 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2c.3 0 .5.2.5.5 0 2.8-.9 3.7-3.8 3.7zM1.3 8.2c0 2.3.5 2.7 2.8 2.7h6.7c2.2 0 2.8-.4 2.8-2.4-1-.2-1.7-1.1-1.7-2.1s.7-1.9 1.7-2.1v-.2c0-2.4-.5-2.8-2.8-2.8H4.1c-2.3 0-2.8.4-2.8 2.7C2.3 4.2 3 5.1 3 6.1S2.3 8 1.3 8.2z" />
    <path d="M9.5 8.7c-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7s.7.3.7.7c-.1.4-.4.7-.7.7zM5.5 5.4c-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7.4 0 .7.3.7.7-.1.4-.4.7-.7.7zM5.2 9.1c-.1 0-.2-.1-.3-.2-.2-.2-.2-.5 0-.7l4.5-4.5c.2-.2.5-.2.7 0 .2.2.2.5 0 .7L5.6 8.9c-.1.1-.3.2-.4.2z" />
  </svg>
)
export default SvgComponent