import { Anchor, Col, Form, Input, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FooterContent from "../../components/FooterContent";
import HeaderAuthTop from "../../components/HeaderAuthTop";
import { EndPoints } from "../../endpoint";
import image1 from "../../images/Image-2.png";
import verifyicon from "../../images/verify.png";
import { GetData, PostData } from "../../services";
import "../Profile/UserProfile.css";
import frame1 from "../../images/Frame.png";
import frame2 from "../../images/Frame-2.png"

const MyFormItemContext = React.createContext([]);

const { Title } = Typography;
const { Link } = Anchor;
const { TextArea } = Input;

const MyFormItemGroup = ({ prefix, children }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(
    () => [...prefixPath, ...toArr(prefix)],
    [prefixPath, prefix]
  );
  return (
    <MyFormItemContext.Provider value={concatPath}>
      {children}
    </MyFormItemContext.Provider>
  );
};

function toArr(str) {
  return Array.isArray(str) ? str : [str];
}
const MyFormItem = ({ name, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
  return <Form.Item name={concatName} {...props} />;
};

function NotificationsSetting() {
  const navigate = useNavigate();

  const [allStatus, setAllStatus] = useState(false)
  const [contextStatus, setContextStatus] = useState(false)
  const [update, setUpdate] = useState(false)

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await GetData(EndPoints?.GET_NOTIFICATION_DATA + localStorage.getItem("user_id"));
      if (res?.status == 200 && res?.success_status == true) {
        setAllStatus(res.data.all_status == 'Yes' ? true : false)
        setContextStatus(res.data.contest_status == 'Yes' ? true : false)
      } else if (res?.status == 200 && res?.success_status == false) {
        
      } else {
        toast.error(res?.message);
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (update) {
      handleAllStatus()
    }
  }, [allStatus, contextStatus])

  const handleAllStatus = async () => {
    try {
      let data = {
        "user_id": localStorage.getItem("user_id"),
        "contest_status": contextStatus ? 'Yes' : 'No',
        "all_status": allStatus ? 'Yes' : 'No',
      }
      const res = await PostData(EndPoints?.NOTIFICATION_ALERT, data);
      if (res?.status == 200 && res?.success_status == true) {
        toast.success(res?.message);
      } else if (res?.status == 200 && res?.success_status == false) {
        toast.error(res?.message);
      } else {
        toast.error(res?.message);
      }
    }
    catch (err) {
      toast.error("Something went wrong");
    }
  }

  return (
    <>
      {/* <HeaderAuthTop /> */}
      <div className="Profilesection">
        <div
          className="container profilewrap"
          style={{ width: "92%", background: "#fff" }}
        >
          <Form name="form_item_path" layout="vertical">
            <h2 className="contesthomeform">Notifications Settings</h2>
            <div className="border"></div>

            {/* {data?.map((el) => {
                return ( */}
            <div className="container notificationwrap">
              <Row>
                <Col lg={2} className="notificationimage">
                  <img src={frame1} width={75} />
                </Col>
                <Col span={19} className="coupondescription">
                  <h2 className="notificationhead">All Notifcation update off</h2>
                  <p className="notificationdescription">
                    You can change the email address associated with your
                    account by going to untitled.com/account from a laptop or
                    desktop.
                  </p>
                </Col>
                <Col span={2} style={{ marginTop: "25px" }}>
                  <label className="switch">
                    {allStatus ?
                      <input type="checkbox" onChange={(e) => { setAllStatus(!allStatus); setUpdate(true) }} checked /> :
                      <input type="checkbox" onChange={(e) => { setAllStatus(!allStatus); setUpdate(true) }} />
                    }
                    <span className="slider round"></span>
                  </label>
                </Col>
              </Row>
            </div>

            <div className="container notificationwrap">
              <Row>
                <Col span={2} className="notificationimage">
                  <img src={frame2} width={75} />
                </Col>
                <Col span={19} className="coupondescription">
                  <h2 className="notificationhead">When you upload products</h2>
                  <p className="notificationdescription">
                    Every new products upload successfully done you can get notification
                  </p>
                </Col>
                <Col span={2} style={{ marginTop: "25px" }}>
                  <label className="switch">
                    {/* <input type="checkbox" /> */}
                    {contextStatus ?
                      <input type="checkbox" onChange={(e) => { setContextStatus(!contextStatus); setUpdate(true) }} checked /> :
                      <input type="checkbox" onChange={(e) => { setContextStatus(!contextStatus); setUpdate(true) }} />
                    }
                    <span className="slider round"></span>
                  </label>
                </Col>
              </Row>
            </div>
            {/* })} */}
          </Form>
        </div>
      </div>

      {/* <FooterContent /> */}
    </>
  );
}

export default NotificationsSetting;
