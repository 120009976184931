import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined
} from '@ant-design/icons';
import {
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer, TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import {
  Form
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EndPoints } from "../../endpoint";
import "../../pages/ContestPage/ContestPage.css";
import { GetData } from "../../services";
import "./CreateContest.css";


const MyFormItemContext = React.createContext([]);


const MyFormItemGroup = ({ prefix, children }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(
    () => [...prefixPath, ...toArr(prefix)],
    [prefixPath, prefix]
  );
  return (
    <MyFormItemContext.Provider value={concatPath}>
      {children}
    </MyFormItemContext.Provider>
  );
};

function toArr(str) {
  return Array.isArray(str) ? str : [str];
}


function ContestList() {
  const navigate = useNavigate()
  const [contestdata, setContestData] = useState([]);

  useEffect(() => {
    getContestentPage();
  }, []);

  const getContestentPage = async () => {
    try {
      console.log(EndPoints.CREATECONTESTLIST + localStorage.getItem("user_id"), '0000000000000000000000000')
      const res = await GetData(EndPoints.CREATECONTESTLIST + localStorage.getItem("user_id"));
      if (res?.status == 200 && res?.success_status == true) {
console.log(res.data,'lisstttttttttttttttt')

        let newArr = res.data.map((x, i) => ({
          sr: i + 1,
          amt: `$${x?.entry_fee}`,
          winnerAmt: `$${x?.winner_prize}`,
          ...x
        }));


        console.log(newArr,'newwwwwwARRRRRRRRRR')

        setContestData(newArr);
      } else if (res?.status == 200 && res?.success_status == false) {
        // toast.error(res?.message)
      } else {
        // toast.error(res?.message)
      }
    } catch (err) {
      toast.error('Something went wrong')
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await GetData(EndPoints.CONTESTDELETE + id)
      if (res.status == 200 && res.success_status == true) {
        getContestentPage()
        alert("Are you sure")
        toast.success(res?.message);

      }
      else if (res.status == 200 && res.success_status == true) {
        toast.error(res?.message);
      }
      else {
        toast.error(res?.message);
      }
    }
    catch (err) {
      toast.error('Something went wrong');
    }
  }

console.log(contestdata,'888888')


  return (
    <>
      <div className="Createcontestsection" style={{ paddingTop: "0" }}>
        <div className="container profilewrap" style={{ width: "92%", background: "#fff" }}>
          <Form name="form_item_path" layout="vertical">
            <h2 className="contesthomeform">Contest History</h2>
            <div className="border" style={{ marginBottom: "20px" }}></div>
            <div className="">
              <TableContainer className="tableContainer" style={{ border: "1px solid #E0E7ED", background: "#fff", borderRadius: "3px" }}>
                <Table>
                  <TableHead className='tableHeader'>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Contest Name</TableCell>
                      <TableCell>Date Of Participation</TableCell>
                      <TableCell>Contest Time</TableCell>
                      <TableCell>Entry Fee</TableCell>
                      <TableCell>Winning Price</TableCell>
                      <TableCell>Rapper Name 1</TableCell>
                      <TableCell>Rapper Name 2</TableCell>
                      <TableCell>Winner Name</TableCell>
                      {/* <TableCell>Action</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {contestdata.length != 0 ?
                      <> */}
                        {contestdata.map((row) =>{ 
                          return(
                          <TableRow key={row.id}>
                            <TableCell>{row.sr}</TableCell>
                            <TableCell>{row.contest_name}</TableCell>
                            <TableCell>{moment(row.contest_date).format('MMM DD, YYYY')}</TableCell>
                            <TableCell>{row.time}</TableCell>
                            <TableCell className='successText'>{row.amt}</TableCell>
                            <TableCell className='successText'>{row.winnerAmt}</TableCell>
                            <TableCell>{row.rapper_name}</TableCell>
                            <TableCell>{row.rapper_name2}</TableCell>
                            <TableCell>{row.winner_name?row.winner_name:'-'}</TableCell>
                            {/* <TableCell className='actionWidth' sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                              <div className="icontag" style={{ marginRight: "5%" }} onClick={() => { navigate('/ParticipateList', { state: { data: row.battelsdata } }) }}>
                                <Tooltip title='Edit'>
                                  <EyeOutlined style={{ fontSize: "20px" }} />
                                </Tooltip>
                              </div>
                              <div className="icontag" style={{ marginRight: "5%" }} onClick={() => { navigate('/CreateContest', { state: { updatedData: row, mode: 'update' } }) }}>
                                <Tooltip title='Edit'>
                                  <EditOutlined style={{ fontSize: "20px" }} />
                                </Tooltip>
                              </div>

                              <div className="icontag" onClick={() => handleDelete(row.id)}>
                                <Tooltip title='Delete'>
                                  <DeleteOutlined
                                    style={{ fontSize: "20px" }}
                                  />
                                </Tooltip>
                              </div>
                            </TableCell> */}
                          </TableRow>
                        )})}
                      {/* </>
                      :
                      <TableRow >
                        <TableCell colSpan={9}><p style={{ textAlign: "center", fontSize: "15px", color: "red" }}>There is no record avilable.</p></TableCell>
                      </TableRow>
                    } */}



                  </TableBody>
                </Table>
              </TableContainer>
            </div>

          </Form>
        </div>
      </div>
    </>
  );
}

export default ContestList;
